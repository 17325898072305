import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, updateRequest } from "../api";
import { MESSAGE_NOTIFICATIONS, ORDER_NOTIFICATIONS } from "../constants";

export const fetchServiceTypes = createAsyncThunk(
  "store/service_types",
  async (store_id, { rejectWithValue }) => {
    try {
      let service_types = await getRequest(`stores/${store_id}/service_types`);

      return service_types;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMainCategories = createAsyncThunk(
  "store/categories",
  async (store_id, { rejectWithValue }) => {
    try {
      let categories = await getRequest(`stores/${store_id}/categories`);

      return categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubStatuses = createAsyncThunk(
  'fetchSubStatuses',
  async (storeId) => {
    try {
      const data = await getRequest(`/stores/${storeId}/sub_statuses`);
      return data;
    } catch (error) {
      console.error('Failed to fetch sub statuses', error);
    }
  }
);

export const updateBuildPosOptionStatus = createAsyncThunk(
  "store/BuildPosOption",
  async ({ store_id, option }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(
        `/stores/${store_id}`,
        {
          store: { build_pos_option: option },
        },
        "addresses"
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUnreadNotifications = createAsyncThunk(
  'fetchUnreadNotifications',
  async ({ storeId, userId }) => {
    try {
      const data = await getRequest(`/stores/${storeId}/users/${userId}/notifications`, {
        filters: { read: false },
      });
      return data;
    } catch (error) {
      console.error('Failed to fetch sub statuses', error);
    }
  }
);

export const fetchReadNotifications = createAsyncThunk(
  'fetchReadNotifications',
  async ({ storeId, userId, currentPage = 1, perPage = 10, activeTab = "all" }) => {
    try {
      let filters = {
        read: true,
      };

      if ( activeTab === "orders") {
        filters.notification_type = [...ORDER_NOTIFICATIONS];
      } else if (activeTab === "messages") {
        filters.notification_type = [...MESSAGE_NOTIFICATIONS];
      }
      const data = await getRequest(`/stores/${storeId}/users/${userId}/notifications`, {
        page: currentPage,
        per_page: perPage,
        filters,
      });

      return data;
    } catch (error) {
      throw error;
    }
  }
);
