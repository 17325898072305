import React, { useEffect, useRef, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../Button/Menu";
import NumberInput from "../../../Form/NumberInput";
import ServiceItem from "./ServiceItem";
import ProductItem from "./ProductItem";
import CartBadge from "../CartBadge";
import { calculateMatchScore, generateRandomString, tokenizeString } from "../../../../utils";
import { POS_NEW_ORDER_STEPS, SEARCH_TYPES_ENUM } from "../../../../constants";
import { useDispatch } from "react-redux";
import { updateCurrentStep } from "../../../../redux/currentStepSlice";
import { useMediaQuery } from "react-responsive";
import SearchInput from "../../../Form/Field/SearchInput";
import { isEmpty } from "lodash";
import SearchResults from "./SearchResults";
import { useDebounce } from "../../../../customHooks/useDebounce";
import { postRequest, updateRequest } from "../../../../api";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../redux/authSlice";
import SuggestionBox from "../../../SuggestionBox";

const ITEM_OPTIONS = {
  service_list: "Service List",
  product_list: "Product List",
};

const AddItem = ({
  replica,
  setReplica,
  editMode,
  setEditMode,
  duplicateMode,
  setDuplicateMode,
  products,
  subCategories,
  popularSearches,
  setPopularSearches,
  addServiceLineItem,
  addProductLineItem,
}) => {
  const [itemType, setItemType] = useState(ITEM_OPTIONS.service_list);

  const [quantity, setQuantity] = useState(1);
  const [currentServices, setCurrentServices] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentCategoryType, setCurrentCategoryType] = useState();

  const [searchInput, setSearchInput] = useState("");
  const [serviceTypeKeys, setServiceTypeKeys] = useState([]);
  const [serviceKeys, setServiceKeys] = useState([]);
  const [productKeys, setProductKeys] = useState([]);
  const [serviceTypeResults, setServiceTypeResults] = useState([]);
  const [serviceResults, setServiceResults] = useState([]);
  const [productResults, setProductResults] = useState([]);
  const [selectedSearchedItem, setSelectedSearchedItem] = useState({});
  const [focus, setFocus] = useState(false);

  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const debouncedSearchInput = useDebounce(searchInput, 200);
  const searchDropdownRef = useRef(null);

  const resetSelections = () => {
    setCurrentServices([]);
    setCurrentOptions([]);
    setCurrentProduct();
    setCurrentCategoryType();
    setDescription("");
    setShowDescription(false);
  };

  const handleCurrentCategoryType = (cT) => {
    resetSelections();
    setCurrentCategoryType(cT);
  };

  const isButtonDisabled = () => {
    if (itemType === ITEM_OPTIONS.service_list) {
      return currentOptions.length < 1 && currentServices.length < 1;
    } else {
      return !currentProduct;
    }
  };

  const handleLineItems = () => {
    if (isButtonDisabled()) return;

    if (itemType === ITEM_OPTIONS.service_list && currentCategoryType) {
      addServiceLineItem({
        category: currentCategoryType.category,
        categoryType: currentCategoryType.name,
        services: currentServices,
        options: currentOptions,
        uniqStr: generateRandomString(),
        quantity,
        description,
      });
    } else if (itemType === ITEM_OPTIONS.product_list) {
      addProductLineItem({
        ...currentProduct,
        uniqStr: generateRandomString(),
        quantity,
        description,
      });
    }

    if (duplicateMode) setDuplicateMode(false);
    if (editMode) setEditMode(false);

    setReplica(null);
    setQuantity(1);
    resetSelections();
    if (!isDesktopScreen)
      dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART));
  };

  const handleCancel = () => {
    if (replica) {
      if (itemType === ITEM_OPTIONS.service_list && !duplicateMode) {
        addServiceLineItem({
          category: replica.category,
          categoryType: replica.categoryType,
          services: replica.services,
          options: replica.options,
          uniqStr: replica?.uniqStr,
          quantity: replica?.quantity,
          description: replica?.description,
        });
      } else if (itemType === ITEM_OPTIONS.product_list) {
        addProductLineItem({
          id: replica?.id,
          name: replica?.name,
          price: replica?.price,
          category: replica?.category,
          uniqStr: replica?.uniqStr,
          quantity: replica?.quantity,
          description: replica?.description,
        });
      }
    } else {
      navigate(-1);
    }

    if (duplicateMode) setDuplicateMode(false);
    if (editMode) setEditMode(false);

    setQuantity(1);
    setReplica(null);
    resetSelections();
    if (!isDesktopScreen)
      dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART));
  };

  const generateServiceKeys = (subcategories) => {
    const serviceTypeKeysSet = new Set();
    const serviceKeys =
      subcategories?.flatMap(({ category, name, services = [] }) =>
        services.map(({ service_type, name: serviceName }) => {
          const baseKey = `${category} | ${name} | ${service_type}`;
          serviceTypeKeysSet.add(baseKey);
          return `${baseKey} | ${serviceName}`;
        })
      ) || [];

    return [serviceKeys, Array.from(serviceTypeKeysSet)];
  };

  const generateProductKeys = (prod) => {
    return prod?.map((p) => `${p?.category} | ${p?.name}`);
  };

  const upsertRecentSearch = async (query, requestToUpdate = false) => {
    try {
      const endpoint = requestToUpdate
        ? `users/${currentUser?.id}/recent_searches/${query?.id}`
        : `users/${currentUser?.id}/recent_searches`;

      const method = requestToUpdate ? updateRequest : postRequest;
      const payload = requestToUpdate
        ? { recent_search: { increase_frequency: true } }
        : { recent_search: { query, search_type: SEARCH_TYPES_ENUM.POS } };

      const response = await method(endpoint, payload);
      if (response?.data) {
        setPopularSearches((prev) => {
          const fs = prev.filter((s) => s?.id !== response?.data?.id);
          return [response?.data, ...fs]
            .sort((a, b) => b?.frequency - a?.frequency)
            .slice(0, 10);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptionClick = (selectedOption) => {
    const [category, categoryType, serviceType, serviceName] =
      selectedOption.split(" | ");
    setItemType(
      serviceType ? ITEM_OPTIONS.service_list : ITEM_OPTIONS.product_list
    );
    setSelectedSearchedItem({
      category,
      categoryType,
      serviceType,
      serviceName,
    });

    const existingSearch = popularSearches.find(
      (search) => search?.query?.toLowerCase() === searchInput?.toLowerCase()
    );

    existingSearch
      ? upsertRecentSearch(existingSearch, true)
      : upsertRecentSearch(searchInput);

    setFocus(false);
  };

  const handleBlur = (e) => {
    if (
      searchDropdownRef.current &&
      searchDropdownRef.current.contains(e.relatedTarget)
    )
      return;
    setFocus(false);
  };

  useEffect(() => {
    if (isEmpty(debouncedSearchInput)) {
      setProductResults([]);
      setServiceResults([]);
      setServiceTypeResults([]);
    } else {
      const filterAndSort = (keys) => {
        return keys
          .map((key) => ({
            key,
            score: calculateMatchScore(key, debouncedSearchInput),
          }))
          .filter((item) => item.score > 0)
          .sort((a, b) => b.score - a.score)
          .map((item) => item.key);
      };

      const filterProd = filterAndSort(productKeys).slice(0, 10);
      const filterService = filterAndSort(serviceKeys).slice(0, 10);
      const filterServiceType = filterAndSort(serviceTypeKeys).slice(0, 10);

      setProductResults(filterProd);
      setServiceResults(filterService);
      setServiceTypeResults(filterServiceType);
    }
  }, [debouncedSearchInput, serviceKeys, productKeys]);

  useEffect(() => {
    if (replica) {
      setItemType(
        replica?.services?.length > 0 || replica?.options?.length > 0
          ? ITEM_OPTIONS.service_list
          : ITEM_OPTIONS.product_list
      );
      setQuantity(replica?.quantity || 1);
      if (replica?.description) {
        setShowDescription(true);
        setDescription(replica?.description);
      }
    }
  }, [replica]);

  useEffect(() => {
    if (!isEmpty(subCategories)) {
      const [serviceKeys, serviceTypeKeys] = generateServiceKeys(subCategories);
      setServiceKeys(serviceKeys);
      setServiceTypeKeys(serviceTypeKeys);
    }

    if (!isEmpty(products)) {
      const productKeys = generateProductKeys(products);
      setProductKeys(productKeys);
    }
  }, [subCategories, products]);

  return (
    <div className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <div className="overflow-y-auto p-0 lg:px-5 lg:pt-5 newOrderStep1">
        <CartBadge replica={replica} duplicateMode={duplicateMode} />
        <div className="flex flex-col gap-2 lg:gap-4 items-start">
          {replica ? (
            isDesktopScreen && (
              <p className="text-xl lg:text-2xl font-[Montserrat] font-semibold lg:font-bold leading-[25.20px]">
                {duplicateMode ? "Duplicate Item" : "Edit Item"}
              </p>
            )
          ) : (
            <div className="flex flex-col sm:flex-row w-full gap-2 sm:gap-0 sm:justify-between newOrderStep2">
              <MenuButton
                options={Object.values(ITEM_OPTIONS)}
                variant="outlinedTertiary"
                defaultOption={itemType}
                onChange={(e) => {
                  setItemType(e);
                  setQuantity(1);
                  setSelectedSearchedItem(null);
                }}
              />
              <div
                onFocus={() => setFocus(true)}
                ref={searchDropdownRef}
                tabIndex={-1}
                onBlur={handleBlur}
              >
                <SearchInput
                  placeholder="Search item"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClear={() => setSearchInput("")}
                  width="350px"
                />
                {focus && (
                  <>
                    <div className="absolute z-10 w-[90%] sm:w-[350px] max-h-[calc(80vh-82px)] overflow-auto shadow-md">
                      {isEmpty(debouncedSearchInput) &&
                        !isEmpty(popularSearches) && (
                          <SuggestionBox
                            title="Popular Searches"
                            options={popularSearches.map((i) => i?.query)}
                            onClick={setSearchInput}
                          />
                        )}
                      {(!isEmpty(productResults) ||
                        !isEmpty(serviceResults)) && (
                        <SearchResults
                          products={productResults}
                          services={serviceResults}
                          serviceTypes={serviceTypeResults}
                          query={debouncedSearchInput}
                          onOptionClick={handleOptionClick}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {itemType === ITEM_OPTIONS.service_list ? (
            <ServiceItem
              replica={replica}
              subCategories={subCategories}
              currentServices={currentServices}
              setCurrentServices={setCurrentServices}
              currentOptions={currentOptions}
              setCurrentOptions={setCurrentOptions}
              currentCategoryType={currentCategoryType}
              setCurrentCategoryType={handleCurrentCategoryType}
              resetSelections={resetSelections}
              searchedItem={selectedSearchedItem}
              setSearchedItem={setSelectedSearchedItem}
            />
          ) : (
            <ProductItem
              replica={replica}
              products={products}
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
              searchedProduct={selectedSearchedItem}
              setSearchedProduct={setSelectedSearchedItem}
            />
          )}
        </div>

        {showDescription && (
          <div className="flex flex-col mb-4">
            <p className="text-lg font-bold my-4">Description</p>
            <TextField
              id="outlined-multiline"
              placeholder="Description"
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
            />
          </div>
        )}
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: showDescription ? "flex-end" : "space-between",
          alignItems: "center",
          gap: 2,
          borderBottomLeftRadius: "lg",
          borderBottomRightRadius: "lg",
        }}
        className="pt-4 lg:px-5 lg:pb-2"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 4,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          {!showDescription && (
            <Button
              variant="textSecondary"
              startIcon={<Add />}
              sx={{
                margin: { xs: "0px", sm: "16px" },
              }}
              disabled={isButtonDisabled()}
              onClick={() => setShowDescription(true)}
            >
              Additional order details
            </Button>
          )}

          <Box
            sx={{
              display: { xs: "inline-flex", sm: "none" },
            }}
          >
            {(currentServices.length > 0 ||
              currentOptions.length > 0 ||
              currentProduct) && (
              <NumberInput quantity={quantity} setQuantity={setQuantity} />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: { xs: "100%", sm: "auto" },
            justifyContent: "flex-end",
            alignItems: { lg: "center" },
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "inline-flex" },
            }}
          >
            {(currentServices.length > 0 ||
              currentOptions.length > 0 ||
              currentProduct) && (
              <NumberInput quantity={quantity} setQuantity={setQuantity} />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "row" },
              gap: 1,
            }}
          >
            {replica && (
              <Button
                variant="outlinedSecondary"
                onClick={handleCancel}
                sx={{
                  width: { xs: "100%", sm: "fit-content" },
                }}
              >
                Cancel
              </Button>
            )}

            <Button
              variant="containedPrimary"
              disabled={isButtonDisabled()}
              onClick={handleLineItems}
              sx={{
                width: { xs: "100%", sm: "fit-content" },
              }}
            >
              {replica && !duplicateMode ? "Update" : "Add Item"}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AddItem;
