import { getRequest, postRequest, updateRequest } from "../api";

export const fetchStoreUsers = async (storeId, roles) => {
  try {
    const { data } = await getRequest(
      `stores/${storeId}/users`,
      { filters: { role: roles } },
      "&fields=user.name,user.id"
    );
    return data;
  } catch (error) {
    console.error(`Error fetching users: ${error.message}`);
  }
};

export const handleAssignees = async (storeId, orderId, lineItemId, itemId, selectedUsers, type) => {
  const updatedType = (type === "options" || type === "services") ? "services" : "products";
  const response = await postRequest(
    `stores/${storeId}/orders/${orderId}/order_assignees`,
    {
      order_assignees: {
        order_line_item_id: lineItemId,
        type: updatedType,
        [type]: [
          {
            id: itemId,
            user_ids_to_add: selectedUsers,
          },
        ],
      },
    }
  );
  return response;
};

export const getOrdersInfo = async (includes, fields, storeId, filters = {}) => {
  const data = await getRequest(`stores/${storeId}/orders`, { filters: filters }, `${includes}&fields=${fields}`);
  return data;
};

export const markTaskDone = async (assignees, storeId, orderId) => {
  const response = await updateRequest(
    `stores/${storeId}/orders/${orderId}/order_assignees/bulk_update`,
    { assignees }
  );
  return response;
};
