import { createAsyncThunk } from "@reduxjs/toolkit";
import { destroyDataFromLocalStorage } from "../utils/localStorage";
import { getRequest, postRequest, deleteRequest } from "../api";
import { LOGIN_TYPE, ROLE_PRIORITY } from "../constants";
import {
  USERS_SIGN_IN,
  USERS,
  USERS_SIGN_OUT,
} from "../constants/BackendRoutes";

export const login = createAsyncThunk(
  "auth/login",
  async (params, { rejectWithValue }) => {
    try {
      let res = await postRequest(USERS_SIGN_IN, params);
      const sortedStores = [...res.data.stores_as_staff].sort((a, b) => {
        return ROLE_PRIORITY.indexOf(a.role) - ROLE_PRIORITY.indexOf(b.role);
      });

      res.data.stores_as_staff = sortedStores;

      return {
        ...res.data,
        token: res.headers.authorization,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      await deleteRequest(USERS_SIGN_OUT);
      destroyDataFromLocalStorage();
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async ({ id, login_type = LOGIN_TYPE.STAFF }, { rejectWithValue }) => {
    try {
      const associationToInclude =
        login_type === LOGIN_TYPE.STAFF
          ? "tour_options,stores_as_staff.store,stores_as_staff.store.addresses"
          : "stores_as_customer.store,addresses,contacts,payment_methods";

      const fields =
        "&fields=user.id,user.name,user.email,user.cust_id,user.status,user.last_sign_in_at,user.last_sign_up,user.phone_number,user.user_name,user.avatar_url,user.pswd_mask,user.unread_notifications_count";

      let userData = await getRequest(
        `${USERS}/${id}`,
        {},
        associationToInclude.concat(fields)
      );

      if (login_type === LOGIN_TYPE.STAFF) {
        const sortedStores = [...userData.stores_as_staff].sort((a, b) => {
          return ROLE_PRIORITY.indexOf(a.role) - ROLE_PRIORITY.indexOf(b.role);
        });
        userData.stores_as_staff = sortedStores;
      }

      return userData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
