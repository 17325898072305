import LeftArrowIcon from "@mui/icons-material/ArrowBackIos";
import RightArrowIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrdersInfo } from "../../../api/assignees";
import { ORDER_STATUS } from "../../../constants";
import { setLoading } from "../../../redux/loadingSlice";
import { selectCurrentStore } from "../../../redux/storeSlice";
import { addToast } from "../../../redux/toastSlice";
import AvatarTooltip from "../../Tooltip";

const NavigationButton = ({ to, icon, children, disabled, status }) => {
  const tooltipContent = (
    <div className="flex items-center">
      <span>
        <p className="inline mr-1">This is the {children === "Previous Order" ? "first" : "last"}</p>
        <p className="text-[#4C8C4A] inline mr-1">{ORDER_STATUS[status]}</p>
        <p className="inline">order.</p>
      </span>
    </div>
  );
  return (
    <AvatarTooltip title={disabled ? tooltipContent : ""} disableHoverListener={!disabled}>
      <span className="flex">
        <Button
          component={to ? Link : "button"}
          to={to || ""}
          sx={{
            borderColor: "lightgray",
            color: "#272523",
            "&:hover": {
              borderColor: "#256E22",
              color: "#256E22",
              backgroundColor: "#edf3ed",
            },
            marginLeft: children === "Next Order" ? "auto" : 0,
          }}
          variant="outlined"
          startIcon={children === "Previous Order" ? icon : null}
          endIcon={children === "Next Order" ? icon : null}
          disabled={disabled}
        >
          {children}
        </Button>
      </span>
    </AvatarTooltip>
  );
};

const Navigation = ({ status, currentOrderId }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const [allOrders, setAllOrders] = useState([]);

  const fetchRepairOrders = async () => {
    try {
      dispatch(setLoading(true));
      if (currentStore && status) {
        const fields = "order.id";
        const data = await getOrdersInfo("", fields, currentStore?.store_id, { status });
        setAllOrders(data);
      }
    } catch (error) {
      dispatch(addToast(error?.message || "Something went wrong!"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchRepairOrders();
  }, [currentStore, status]);

  const currentIndex = allOrders.findIndex(order => order.id === currentOrderId);

  const prevOrderId = currentIndex > 0 ? allOrders[currentIndex - 1]?.id : null;
  const nextOrderId = currentIndex < allOrders.length - 1 ? allOrders[currentIndex + 1]?.id : null;

  return (
    <div className="flex justify-between w-full">
      <NavigationButton to={prevOrderId ? `/orders/details/${prevOrderId}` : null} icon={<LeftArrowIcon />} disabled={!prevOrderId} status={status}>
        Previous Order
      </NavigationButton>

      <NavigationButton to={nextOrderId ? `/orders/details/${nextOrderId}` : null} icon={<RightArrowIcon />} disabled={!nextOrderId} status={status}>
        Next Order
      </NavigationButton>
    </div>
  );
};

export default Navigation;
