import React from "react";
import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";

const SearchResults = ({
  services = [],
  serviceTypes = [],
  products = [],
  query = "",
  onOptionClick,
}) => {
  const highlightMatches = (text, query) => {
    if (!query) return text;

    const queryWords = query
      .toLowerCase()
      .split(" ")
      .filter((word) => word.length > 0);

    const sortedQueryWords = queryWords.sort((a, b) => b.length - a.length);

    const matches = [];
    sortedQueryWords.forEach((queryWord) => {
      let textLower = text.toLowerCase();
      let currentPosition = 0;

      while (true) {
        const matchStart = textLower.indexOf(queryWord, currentPosition);
        if (matchStart === -1) break;

        const isOverlapping = matches.some(
          (match) =>
            match.start < matchStart + queryWord.length &&
            match.end > matchStart
        );

        if (!isOverlapping) {
          matches.push({
            start: matchStart,
            end: matchStart + queryWord.length,
            word: queryWord,
          });
        }

        currentPosition = matchStart + 1;
      }
    });

    matches.sort((a, b) => a.start - b.start);

    const elements = [];
    let lastEnd = 0;

    matches.forEach((match, index) => {
      if (match.start > lastEnd) {
        elements.push(text.slice(lastEnd, match.start));
      }

      elements.push(
        <span key={index} className="font-bold text-[#4C8C4A]">
          {text.slice(match.start, match.end)}
        </span>
      );

      lastEnd = match.end;
    });

    if (lastEnd < text.length) {
      elements.push(text.slice(lastEnd));
    }

    return <>{elements}</>;
  };

  const renderServiceTypeResults = () => {
    return serviceTypes.map((service, index) => {
      const parts = service.split("|").map((part) => part.trim());
      const title = parts.pop();
      const breadcrumb = parts.join(" > ");

      return (
        <Box
          key={index}
          className="hover:bg-[rgba(243,240,232,0.50)] cursor-pointer"
          onClick={() => onOptionClick(service)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            p: 1,
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: "bold" }}
          >
            {highlightMatches(title, query)}
          </Typography>
          <p className="text-[#939291] text-xs font-normal font-['Montserrat'] leading-none">
            {highlightMatches(breadcrumb, query)}
          </p>
        </Box>
      );
    });
  };

  const renderServiceResults = () => {
    return services.map((service, index) => {
      const parts = service.split("|").map((part) => part.trim());
      const title = parts.pop();
      const breadcrumb = parts.join(" > ");

      return (
        <Box
          key={index}
          className="hover:bg-[rgba(243,240,232,0.50)] cursor-pointer"
          onClick={() => onOptionClick(service)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            p: 1,
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: "bold" }}
          >
            {highlightMatches(title, query)}
          </Typography>
          <p className="text-[#939291] text-xs font-normal font-['Montserrat'] leading-none">
            {highlightMatches(breadcrumb, query)}
          </p>
        </Box>
      );
    });
  };

  const renderProductResults = () => {
    return products.map((product, index) => {
      const [breadcrumb, title] = product.split("|").map((part) => part.trim());

      return (
        <Box
          key={index}
          className="hover:bg-[rgba(243,240,232,0.50)] cursor-pointer"
          onClick={() => onOptionClick(product)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            p: 1,
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: "bold" }}
          >
            {highlightMatches(title, query)}
          </Typography>
          <p className="text-[#939291] text-xs font-normal font-['Montserrat'] leading-none">
            {highlightMatches(breadcrumb, query)}
          </p>
        </Box>
      );
    });
  };

  return (
    <Box
      className="w-full h-full"
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        gap: 2,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      {!isEmpty(serviceTypes) > 0 && (
        <>
          <p className="text-[#939291] text-sm font-normal font-['Questrial'] leading-none">
            Service types
          </p>
          {renderServiceTypeResults()}
        </>
      )}

      {!isEmpty(services) && (
        <>
          <p className="text-[#939291] text-sm font-normal font-['Questrial'] leading-none">
            Services
          </p>
          {renderServiceResults()}
        </>
      )}

      {!isEmpty(products) && (
        <>
          <p className="text-[#939291] text-sm font-normal font-['Questrial'] leading-none">
            Products
          </p>
          {renderProductResults()}
        </>
      )}
    </Box>
  );
};

export default SearchResults;
