import React from "react";
import { TextField } from "@mui/material";

const TextInput = ({ ...props }) => {
  return (
    <TextField
      size="small"
      placeholder="Please enter"
      style={{ width: "100%" }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderColor: "#D0D5DD",
          borderRadius: "8px",
          "& fieldset": {
            borderColor: "#D0D5DD",
          },
        },
      }}
      {...props}
    />
  );
};

export function BasicTextInput({
  name,
  inputHandler,
  value,
  placeholder = "Please enter",
  classes = "",
  size = "small"
}) {
  return (
    <TextField
      size={size}
      name={name}
      value={value}
      onChange={inputHandler}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      className={classes}
    />
  );
}

export default TextInput;
