import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CrownIcon from "../../images/CrownIcon.svg";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { updateRequest } from "../../api";
import { useSelector } from "react-redux";
import { selectCurrentOrganization, selectSubscription, updateSubscription } from "../../redux/organizationSlice";
import { addToast } from "../../redux/toastSlice";
import { formatDate } from "../../utils";

export const CancelConfirmation = ({ close, setCurrentStep }) => {
  const dispatch = useDispatch()
  const currentOrganization = useSelector(selectCurrentOrganization)
  const organizationSubscription = useSelector(selectSubscription)

  const handleCancel = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(
        `organizations/${currentOrganization?.id}/subscriptions/${organizationSubscription?.id}`,
        { subscription: { cancel_at_period_end: true } }
      );

      if (response.status === 200) {
        dispatch(addToast(response?.data?.message));
        dispatch(
          updateSubscription({
            ...organizationSubscription,
            cancel_at_period_end: true,
          })
        );
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
      close();
    }
  }

  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-[#272523] text-2xl font-bold font-['Montserrat'] leading-[33.60px] w-[80%]">
          Are you sure you want to cancel your plan?
        </p>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          {!currentOrganization?.free_month_offer_accepted_at &&
            !organizationSubscription?.free_month_coupon_availed && (
              <span className="items-center flex gap-2">
                <img alt="logo" src={CrownIcon} className="w-6 h-6" />
                <p
                  className="text-[#F97316] text-sm underline cursor-pointer"
                  onClick={() => setCurrentStep(0)}
                >
                  Special offer for you: Stay with us and get 1 month Free!
                </p>
              </span>
            )}
          <Typography variant="body1" className="mt-2">
            Your {organizationSubscription?.plan?.name} will be canceled
            starting next billing cycle, on{" "}
            {formatDate(organizationSubscription?.current_period_end)}.
          </Typography>
        </div>
        <Typography variant="body1" className="mt-2">
          Everyone on the team will not be able to access the Coblr account
          after that, all the data related to the account will be removed within
          90 days.
        </Typography>
      </div>

      <Box className="flex justify-end gap-2">
        <Button
          onClick={() => close()}
          variant="outlined"
          sx={{ minWidth: "120px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleCancel()}
          variant="contained"
          sx={{ minWidth: "120px" }}
        >
          Cancel subscription
        </Button>
      </Box>
    </div>
  );
};
