import React from "react";
import { Button } from "@mui/material";
import { IncludedIcon } from "../../common/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentOrganization, selectSubscription } from "../../redux/organizationSlice";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";
import { updateRequest } from "../../api";

export const FreeCoupon = ({ close, setCurrentStep }) => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription);

  const handleCancel = () => {
    organizationSubscription?.cancel_at_period_end
      ? close()
      : setCurrentStep(1);
  }

  const handleGetOffer = async () => {
    try {
      dispatch(setLoading(true));
      const params = {
        subscription: {
          cancel_at_period_end: organizationSubscription?.cancel_at_period_end === true ? false : undefined,
          discounts: process.env?.REACT_APP_STRIPE_FREE_COUPON_FOR_SUBSCRIPTION
            ? [{ coupon: process.env.REACT_APP_STRIPE_FREE_COUPON_FOR_SUBSCRIPTION }]
            : undefined,
        },
      };

      Object.keys(params.subscription).forEach(
        (key) => params.subscription[key] === undefined && delete params.subscription[key]
      );

      const response = await updateRequest(
        `organizations/${currentOrganization?.id}/subscriptions/${organizationSubscription?.id}`,
        params
      );

      if (response?.status === 200) {
        dispatch(addToast(response?.data?.message));
        close();
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-[#272523] text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
          Get 1 month of Coblr, on us!
        </p>
        <p className="font-[Questrial] font-normal">
          We don’t like goodbyes, so here’s an idea: Would you like{" "}
          <span className="font-extrabold text-lg">1 month</span> of Coblr for{" "}
          <span className="font-extrabold text-lg">free?</span>
        </p>
        <p className="font-[Questrial] font-normal">
          You will still have access to the following features:
        </p>
      </div>

      <div className="bg-[linear-gradient(0deg,_rgba(76,_140,_74,_0.05)_0%,_rgba(76,_140,_74,_0.05)_100%)] rounded">
        <div className="flex flex-col gap-1 p-6">
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            Starter
          </p>
          <p className="text-[#F97316] font-[Questrial] font-bold text-sm">
            Free for the month
          </p>
          <p className="font-[Questrial] text-[#939291] text-sm">
            and then $75/Month
          </p>
        </div>
        <hr />
        <div className="grid grid-cols-2 gap-2 px-6 pb-2">
          <div className="px-6 pt-6 border-stone-300 flex-col gap-5 flex">
            <p className="text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
              Items included:
            </p>
            <div className="flex-col justify-center gap-3 flex">
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                1 Location
              </p>
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                2 Employees
              </p>
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                1 Integration
              </p>
              <p className="text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                Email Support Only
              </p>
            </div>
          </div>

          <div className="px-6 pt-6 border-l border-neutral-200 flex-col flex gap-5">
            <p className="text-stone-400 text-xs font-normal font-['Questrial'] leading-none">
              Features included:
            </p>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Order Management
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Full POS
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Customer Management
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IncludedIcon />
                <p className="grow shrink basis-0 text-stone-800 text-xs font-normal font-['Questrial'] leading-none">
                  Messages
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex py-3 justify-center items-center">
          <a
            href="https://getcoblr.com/pricing/"
            target="_blank"
            className="text-[#4C8C4A] font-[Questrial] font-normal"
          >
            View More {`>`}{" "}
          </a>
        </div>
      </div>

      <div className="flex justify-end gap-4">
        <Button
          variant="outlined"
          onClick={handleCancel}
          sx={{
            borderColor: "gray",
            color: "gray",
            minWidth: "120px",
            textTransform: "none",
          }}
        >
          {organizationSubscription?.cancel_at_period_end
            ? "Cancel"
            : "Cancel subscription"}
        </Button>
        <Button
          variant="contained"
          onClick={handleGetOffer}
          sx={{ minWidth: "120px" }}
        >
          Accept the Offer
        </Button>
      </div>
    </div>
  );
};
