import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";

export const skipTour = ({ isDesktopScreen = true }) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      showCancelLink: true,
      modalOverlayOpeningRadius: 20,
      scrollTo: true,
      scrollToHandler: (element) => {
        const offsetTop = element.getBoundingClientRect().top + window.pageYOffset;
        const scrollToPosition = offsetTop - 100;
        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
  });

  const steps = [
    {
      id: "Step 1",
      text: ` <div>
                    <p class="font-bold text-xl">✨ Continue your onboarding Tour</p>
                    <p class="text-sm text-gray-500 pt-2">
                      You’ve skipped the tour for now, but no worries! Pick up where you left off here anytime and explore all the key features of Coblr at your own pace!
                    </p>
                  </div>`,
      attachTo: {
        element: ".skipTourStep1",
        on: isDesktopScreen ? "left" : "bottom",
        classes: ".rounded-container",
      },
      beforeShowPromise: () =>
        new Promise((resolve) => {
          setTimeout(resolve, 200);
        }),
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: index === steps.length - 1 ? "Got it" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
