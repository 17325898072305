import React from "react";
import { Box, Button, LinearProgress, Stack } from "@mui/material";

const Disclaimer = ({
  title,
  description,
  handleClose,
  primaryButtonText = "Try Now",
  handleSubmit,
  handleCancel,
  progressBar,
}) => {
  const shouldRenderActions =
    progressBar || handleSubmit || handleClose || handleCancel;

  return (
    <Box className="flex flex-col bg-[#F8F8F8] border border-stone-100 rounded-lg p-5 relative gap-4">
      <div className="flex flex-col gap-2">
        {typeof title === "string" ? (
          <p className="text-lg font-semibold font-[Montserrat]">{title}</p>
        ) : (
          title
        )}

        {description && (
          <p className="font-[Questrial] text-sm">{description}</p>
        )}
      </div>

      {shouldRenderActions && (
        <div className="flex flex-col-reverse sm:flex-row justify-between">
          {!progressBar ? (
            <>
              <div className="flex flex-col sm:flex-row gap-4">
                {handleSubmit && (
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    size="sm"
                    sx={{ minWidth: "120px" }}
                  >
                    {primaryButtonText}
                  </Button>
                )}

                {handleClose && (
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{ minWidth: "120px" }}
                  >
                    Hide for now
                  </Button>
                )}
              </div>

              {handleCancel && (
                <Button
                  className="secondary_btn mr-auto"
                  onClick={handleCancel}
                  sx={{ marginLeft: "auto" }}
                >
                  Don't show it again
                </Button>
              )}
            </>
          ) : (
            <Stack sx={{ width: "100%", color: "green" }} spacing={1}>
              <LinearProgress color="inherit" />
            </Stack>
          )}
        </div>
      )}
    </Box>
  );
};

export default Disclaimer;
