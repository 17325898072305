import CircleIcon from "@mui/icons-material/Circle";
import React from "react";

const MessageNotificationItem = ({ notification, handleNotificationClick }) => {
  const { orderId, read, customerName, messageContent, createdAt } =
    notification;

  return (
    <div
      className={`flex flex-col sm:flex-row gap-2 mb-4 w-full`}
      onClick={() => handleNotificationClick(notification)}
    >
      <div className="flex flex-col gap-1 flex-1 font-['Questrial'] font-normal text-base">
        <div className="flex flex-wrap justify-between gap-1">
          <div className="flex gap-1">
            {!read && (
              <CircleIcon
                sx={{
                  marginTop: "7px",
                  width: "10px",
                  height: "10px",
                  color: "#D84141",
                }}
              />
            )}
            <span>You have an unread message</span>
          </div>
          <div className="font-['Questrial'] font-normal text-sm text-[#939291]">
          {createdAt}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 text-sm">
          <div
            className={`flex pr-2 ${
              orderId && "border-r border-gray-300"
            }`}
          >
            <span className="text-[#939291]">
              From&nbsp;<span className="text-[#272523]">{customerName}</span>
            </span>
          </div>
          {orderId && (
            <div className="flex">
              <span className="text-[#939291]">Related to:&nbsp;</span>
              <span className="text-[#272523] mr-2">#{orderId}</span>
            </div>
          )}
        </div>
        <div className="max-w-64 truncate text-sm">{messageContent}</div>
      </div>
    </div>
  );
};

export default MessageNotificationItem;
