import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";
import { setisNavbarOpen, setOpenDropdown } from "../redux/navbarSlice";
import { TOUR_OPTION_STATUS, TOUR_OPTION_STATUS_ENUM } from "../constants/index.js";
import { tours } from ".";

export const dashboardTour = ({
  currentTour,
  updateTourStatus,
  dispatch,
  isDesktopScreen = true,
}) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      showCancelLink: true,
      modalOverlayOpeningRadius: 10,
      scrollTo: true,
      scrollToHandler: (element) => {
        const offsetTop =
          element.getBoundingClientRect().top + window.pageYOffset;
        const scrollToPosition = offsetTop - 125;
        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
  });

  const onComplete = () => {
    dispatch(setisNavbarOpen(false));
    dispatch(setOpenDropdown(null));
    if(currentTour?.status !== TOUR_OPTION_STATUS_ENUM.complete)
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.COMPLETE,
        })
      );
  };

  const onSkip = () => {
    dispatch(setisNavbarOpen(false));
    dispatch(setOpenDropdown(null));

    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.skip)
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.SKIP,
        })
      );

    const skip = tours.skip({ isDesktopScreen });
    skip.start();
  };

  tour.on("complete", onComplete);
  tour.on("cancel", onSkip);

  const steps = [
    {
      id: "Step 6",
      text: ` <div>
                <p class="font-bold text-xl">Store Overview</p>
                <p class="text-sm text-gray-500 pt-2">
                  View your total orders and total sales, along with a comparison to last month’s performance. Quickly track your store’s growth!
                </p>
              </div>`,
      attachTo: {
        element: ".dashboardStep6",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 7",
      text: ` <div>
                <p class="font-bold text-xl">Sales Trends</p>
                <p class="text-sm text-gray-500 pt-2">
                  See your daily sales performance for the current month. Monitor your progress and identify patterns easily.
                </p>
              </div>`,
      attachTo: {
        element: ".dashboardStep7",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 8",
      text: ` <div>
                <p class="font-bold text-xl">Active Orders Status</p>
                <p class="text-sm text-gray-500 pt-2">
                 Get a view of your store’s active orders, broken down by their current status. Stay on top of what’s in progress!
                </p>
              </div>`,
      attachTo: {
        element: ".dashboardStep8",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 9",
      text: ` <div>
                <p class="font-bold text-xl">What’s Due Next</p>
                <p class="text-sm text-gray-500 pt-2">
                  Quickly check orders with deadlines in the next 7 days or those that are overdue. Plan your work efficiently and stay ahead of schedule.
                </p>
              </div>`,
      attachTo: {
        element: ".dashboardStep9",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 1",
      text: ` <div>
                <p class="font-bold text-xl">Orders Page</p>
                <p class="text-sm text-gray-500 pt-2">
                  This is where all of your orders from online and in-store will be compiled and managed.
                </p>
              </div>`,
      attachTo: {
        element: ".dashboardStep1",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
      when: {
        show: () => {
          dispatch(setisNavbarOpen(true));
          dispatch(setOpenDropdown("orders"));
        },
        hide: () => dispatch(setOpenDropdown(null)),
      },
    },
    {
      id: "Step 2",
      text: ` <div>
                  <p class="font-bold text-xl">Messages Page</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Stay connected with your customers through SMS and email to ensure timely communication and updates.
                  </p>
                </div>`,
      attachTo: {
        element: ".dashboardStep2",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
      beforeShowPromise: () =>
        new Promise((resolve) => {
          setTimeout(resolve, 100);
        }),
    },
    {
      id: "Step 3",
      text: ` <div>
                  <p class="font-bold text-xl">Point of Sale</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Customize your POS system by setting up categories and services tailored to your business needs.
                  </p>
                </div>`,
      attachTo: {
        element: ".dashboardStep3",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
      when: {
        show: () => dispatch(setOpenDropdown("pos")),
      },
    },
    {
      id: "Step 4",
      text: ` <div>
                  <p class="font-bold text-xl">Create new order from POS</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Easily create new orders with an easy customer checkout flow and the ability to sign up  customers for email / SMS updates.
                  </p>
                </div>`,
      attachTo: {
        element: ".dashboardStep4",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
      when: {
        hide: () => dispatch(setOpenDropdown(null)),
      },
    },
    {
      id: "Step 5",
      text: ` <div>
                  <p class="font-bold text-xl">Customers Page</p>
                  <p class="text-sm text-gray-500 pt-2">
                    View and manage your customer list and history to stay up to date on your customers.
                  </p>
                </div>`,
      attachTo: {
        element: ".dashboardStep5",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
      beforeShowPromise: () =>
        new Promise((resolve) => {
          setTimeout(resolve, 100);
        }),
      when: {
        hide: () => {
          dispatch(setisNavbarOpen(false));
        },
      },
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: `${index + 1}/${steps.length}`,
          action: null,
          classes: "step_counter",
          disabled: true,
        },
        {
          text: "Skip for now",
          action: tour.cancel,
          classes: "secondary_btn",
        },
        {
          text: index === steps.length - 1 ? "Finish" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
