import { IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationBellIcon } from "../common/icons";
import { NOTIFICATIONS } from "../constants/FrontendRoutes";
import { selectUnReadNotifications } from "../redux/storeSlice";
import NotificationBar from "./NotificationBar";

const NotificationIcon = () => {
  const [isVisible, setIsVisible] = useState(false);
  const notifications = useSelector(selectUnReadNotifications);
  const location = useLocation();
  const navigate = useNavigate();

  const notificationRef = useRef(null);
  const iconRef = useRef(null);

  const toggleNotification = (e) => {
    e.stopPropagation();
    setIsVisible((prev) => !prev);
  };

  const handleBackdropClick = () => {
    setIsVisible(false);
  };

  return (
    <div className="relative">
      {!location.pathname.includes(NOTIFICATIONS) && (
        <>
          <div className="hidden sm:block">
            <IconButton ref={iconRef} onClick={toggleNotification}>
              <NotificationBellIcon unread={notifications?.data?.length || 0} />
            </IconButton>
          </div>
          <div className="block sm:hidden">
            <IconButton onClick={() => navigate(NOTIFICATIONS)}>
              <NotificationBellIcon unread={notifications?.data?.length || 0} />
            </IconButton>
          </div>
        </>
      )}

      {isVisible && (
        <>
          <div
            className="fixed inset-0 z-40 bg-transparent"
            onClick={handleBackdropClick}
          />

          <div
            className="hidden sm:flex z-50 absolute right-0 top-12"
            ref={notificationRef}
          >
            <NotificationBar setIsVisible={setIsVisible} />
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationIcon;
