import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

export const CustomTooltip = ({
  title,
  arrow = true,
  placement = "right",
  hoveredColor,
  defaultColor,
  customStyles = {},
  icon: IconComponent = InfoIcon,
  iconProps = {},
  onMouseEnter,
  onMouseLeave,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Tooltip
      title={title}
      arrow={arrow}
      placement={placement}
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              backgroundColor: "white",
              color: "black",
              border: "1px solid rgba(128, 128, 128, 0.3)",
              padding: "16px",
              borderRadius: "8px",
              maxWidth: {
                xs: "250px",
                sm: "350px",
              },
            },
            "& .MuiTooltip-arrow": {
              color: "white",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: "1px solid rgba(128, 128, 128, 0.5)",
                zIndex: -1,
              },
            },
          },
        },
      }}
    >
      <IconButton
        onMouseEnter={() => {
          if (hoveredColor && defaultColor) setHovered(true);
          if (onMouseEnter) onMouseEnter();
        }}
        onMouseLeave={() => {
          if (hoveredColor && defaultColor) setHovered(false);
          if (onMouseLeave) onMouseLeave();
        }}
        sx={{
          color:
            hovered && hoveredColor ? hoveredColor : defaultColor || "inherit",
        }}
      >
        <IconComponent {...iconProps} />
      </IconButton>
    </Tooltip>
  );
};
