import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Dialog, IconButton } from "@mui/material";
import SetupPosFrame from "../../images/Setup-POS-Frame.svg";
import { postRequest } from "../../api";
import { useDispatch } from "react-redux";
import { BUILD_POS_OPTION } from "../../constants";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";
import { useSelector } from "react-redux";
import { selectCurrentStore, setCurrentStore } from "../../redux/storeSlice";

export const SetupPosModal = ({
  open,
  close,
  handleSkip,
  setIsBuildPosModalOpen,
  isDisclaimerEnabled = true,
}) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);

  const handleClose = () => {
    close(false);
  };

  const handleSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const response = await postRequest(
        `/stores/${currentStore?.store_id}/build_pos`
      );

      if (response.status === 200) {
        dispatch(
          setCurrentStore({
            ...currentStore,
            store: { build_pos_option: BUILD_POS_OPTION.IN_PROGRESS },
          })
        );

        close(false);
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
      setIsBuildPosModalOpen(true);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="flex flex-col px-6 pt-8 gap-4 pb-4">
        <p className="text-xl font-semibold font-[Montserrat]">
          🚀 Get started with your POS in one click!
        </p>
        <p className="font-[Questrial] text-base">
          Not sure where to start?
          <br />
          Try our standard structure of categories and services that other
          repair shops rely on. You can also customize it to fit your unique
          needs.
        </p>
        <img src={SetupPosFrame} />
        <div className="flex justify-between">
          <Button
            className="secondary_btn"
            onClick={() => {
              isDisclaimerEnabled && handleSkip(true);
              handleClose();
            }}
          >
            Skip for now
          </Button>
          {currentStore?.store?.build_pos_option ===
          BUILD_POS_OPTION.IN_PROGRESS ? (
            <CircularProgress color="success" size={30}/>
          ) : (
            <Button variant="containedPrimary" onClick={handleSubmit}>
              Build my POS
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
