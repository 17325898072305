import React, { useEffect, useState } from "react";
import { PAYMENT_STATUS } from "../../../constants";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { postRequest } from "../../../api";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toastSlice";

const CorporateOverview = ({ corporationDetail = {}, currentStore = {}, corporateUserId }) => {
  const dispatch = useDispatch();
  const { name, addresses, contacts, orders } = corporationDetail;
  const address = addresses?.find((item) => item?.address_type === "shipping");

  const filteredOrders = orders?.filter(
    (o) =>
      o.store_id == currentStore.id &&
      o.under_warranty
  );
  const billLaterOrder = filteredOrders?.filter(
    (fo) => fo?.payment?.status == PAYMENT_STATUS.BILL_LATER
  );

  const handleGenerateInvoice = async () => {
    try {
      const response = await postRequest(
        `stores/${currentStore?.id}/users/${corporateUserId}/invoices`,
      );
      if (response) {
        dispatch(addToast("Refresh!"))
      }
    } catch (error) {
      dispatch(addToast(error))
    }
  }

  return (
    <section className="bg-white px-[24px] py-[32px] flex flex-col gap-[16px] rounded-[8px]">
      <div className="text-[#272523] font-bold font-['Montserrat'] text-[24px] flex justify-between items-center">
        {name}
        {/* <span onClick={handleGenerateInvoice}>
          <PlaylistAddIcon />
        </span> */}
      </div>
      <div className="h-[0.5px] bg-[#C9C8C8]" />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Contact person:</span>
          <span className="text-[#272523]">{contacts?.[0]?.name || "N/A"}</span>
        </div>
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Email address:</span>
          <span className="text-[#272523]">
            {contacts?.[0]?.email || "N/A"}
          </span>
        </div>
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Phone number:</span>
          <span className="text-[#272523]">
            {contacts?.[0]?.phone_number || "N/A"}
          </span>
        </div>
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Shipping address:</span>
          <span className="text-[#272523]">
            {address?.human_readable || "N/A"}
          </span>
        </div>
      </div>
      <div className="h-[0.5px] bg-[#C9C8C8]" />
      <p className="text-[#272523] font-['Montserrat'] font-[600] text-[18px] mb-[16px]">
        Corporate Summary
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <div className="flex flex-col gap-2 ">
          <span className="text-2xl font-semibold">
            {filteredOrders?.length || 0}
          </span>
          <span className="text-gray-500">Total orders</span>
        </div>
        <div className="sm:border-l-[0.5px] sm:border-l-[#c9c8c8]">
          <div className="w-fit sm:mx-auto flex flex-col gap-2 ">
            <span className="text-2xl font-semibold">
              {billLaterOrder?.length || 0}
            </span>
            <span className="text-gray-500">Unpaid orders</span>
          </div>
        </div>
        <div className="sm:border-r-[0.5px] sm:border-r-[#c9c8c8] sm:border-l-[0.5px] sm:border-l-[#c9c8c8]">
          <div className="w-fit sm:mx-auto flex flex-col gap-2 ">
            <span className="text-2xl font-semibold">
              $
              {billLaterOrder
                ?.reduce((sum, o) => sum + o?.net_total, 0)
                ?.toFixed(2)}
            </span>
            <span className="text-gray-500">Total unpaid</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 sm:mx-auto">
          <span className="text-2xl font-semibold">
            $
            {filteredOrders
              ?.reduce((sum, o) => sum + o?.net_total, 0)
              ?.toFixed(2)}
          </span>
          <span className="text-gray-500">Total spend</span>
        </div>
      </div>
    </section>
  );
};

export default CorporateOverview;
