import React, { useState } from "react";
import InputMask from "react-input-mask";
import Selector from "../Form/Field/AutoComplete";
import LabelText from "../Form/FormLabel";
import { BasicTextInput } from "../Form/Field/TextInput";
import { COUNTRIES, STATES_PER_COUNTRY } from "../../constants";
import { TextField } from "@mui/material";

export default function ShippingForm({ shippingType, data, inputHandler }) {

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    inputHandler({ target: { name, value } });
  };

  return (
    <div className="flex flex-col gap-3 mb-2">
      <p className="font-questrial text-base font-normal">
        Please specify the following shipping information:
      </p>
      <LabelText
        text={shippingType}
        classes="text-[#272523] font-semibold text-lg"
      />
      <div className="flex flex-row gap-2 md:flex-nowrap flex-wrap">
        <div className="flex flex-col gap-2 w-full">
          <LabelText text="Name" classes="text-[#272523]" />
          <BasicTextInput
            name="name"
            value={data?.name}
            inputHandler={handleInputChange}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <LabelText text="Company Name" classes="text-[#272523]" />
          <BasicTextInput
            name="companyName"
            value={data?.companyName}
            inputHandler={handleInputChange}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
      <LabelText text="Phone number" classes="text-[#272523]" />
      <InputMask
        mask="(999) 999-9999"
        value={data?.phone}
        onChange={handleInputChange}
      >
        {() => (
          <TextField
            type="tel"
            size="small"
            variant="outlined"
            name="phone"
            placeholder="(123) 456-7890"
            className="px-4 py-2 border rounded-lg"
          />
        )}
      </InputMask>
      </div>
      <div className="flex flex-col gap-2 flex-wrap">
        <LabelText text="Address" classes="text-[#272523]" />
        <BasicTextInput
          name="addressLine1"
          value={data?.addressLine1}
          inputHandler={handleInputChange}
          placeholder="Address line 1"
        />
        <BasicTextInput
          name="addressLine2"
          value={data?.addressLine2}
          inputHandler={handleInputChange}
          placeholder="Address line 2"
        />
        <BasicTextInput
          name="addressLine3"
          value={data?.addressLine3}
          inputHandler={handleInputChange}
          placeholder="Address line 3"
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-2">
        <div className="flex flex-col gap-2 w-full">
          <LabelText text="Country" classes="text-[#272523]" />
          <Selector
            name="country"
            label="Select Country"
            options={COUNTRIES}
            onChange={(_, value) =>
              handleInputChange({
                target: { name: "country", value: value?.value },
              })
            }
            value={COUNTRIES.find(
              (country) => country?.value === data?.country
            )}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <LabelText text="State" classes="text-[#272523]" />
          <Selector
            name="state"
            label="Please select"
            options={STATES_PER_COUNTRY?.[data?.country] || []}
            onChange={(_, value) =>
              handleInputChange({
                target: { name: "state", value: value?.value },
              })
            }
            value={STATES_PER_COUNTRY?.[data?.country]?.find(
              (state) => state?.value === data?.state
            )}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-2">
        <div className="flex w-full flex-col gap-2">
          <LabelText text="City" classes="text-[#272523]" />
          <BasicTextInput
            name="city"
            value={data?.city}
            inputHandler={handleInputChange}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <LabelText text="Zip code" classes="text-[#272523]" />
          <InputMask
            mask={data?.country === "CA" ? "a9a 9a9" : "99999"}
            value={data?.zipCode}
            onChange={handleInputChange}
          >
            {() => (
              <TextField
                type="text"
                size="small"
                name="zipCode"
                placeholder={data?.country === "CA" ? "A1A 1A1" : "12345"}
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>
    </div>
  );
}
