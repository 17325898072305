import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import LetterSize from "../components/Order/PrintLayouts/Large";
import { isObjectEmpty } from "../utils";
import { useDispatch } from "react-redux";
import { updateCart } from "../redux/cartSlice";

const PrintComponent = ({ cart }) => {
  const dispatch = useDispatch();
  const updatedCurrentOrder = {
    ...cart?.data,
    order_line_items: cart?.data?.order_line_items.filter(
      (item) => item?.line_item_type === "service",
    ),
  };

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
          @page {
              size: 10in 15in;
            }
          }`,
    content: () => printRef.current,
  });

  useEffect(() => {
    const printLabels = async () => {
      if (
        updatedCurrentOrder &&
        updatedCurrentOrder?.order_line_items?.length > 0 &&
        updatedCurrentOrder?.status !== "quote"
      ) {
        await handlePrint();
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    };

    if (!isObjectEmpty(cart?.data)) {
      printLabels();
      dispatch(updateCart({ ...cart, qrGenerated: true }));
    }
  }, [cart?.data]);

  return (
    <div style={{ display: "none" }}>
      <div ref={printRef}>
        {/* {currentOrder &&
                currentOrder.data &&
                currentOrder.data.order_line_items.map((_, index) => (
                <div key={index} style={{ pageBreakAfter: "always" }}>
                    <Small currentItem={index + 1} data={currentOrder?.data} />
                </div>
                ))} */}
        {/* {currentOrder &&
                currentOrder.data &&
                currentOrder.data.order_line_items.map((_, index) => (
                <div key={index} style={{ pageBreakAfter: "always" }}>
                    <Medium currentItem={index + 1} data={currentOrder?.data} />
                </div>
                ))} */}
        {updatedCurrentOrder &&
          updatedCurrentOrder?.order_line_items?.map((_, index) => (
            <div key={index} style={{ pageBreakAfter: "always" }}>
              <LetterSize currentItem={index + 1} data={updatedCurrentOrder} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default PrintComponent;
