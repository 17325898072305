import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";

import { logout } from "../../redux/authActions";
import { fetchUnreadCount } from "../../redux/messageAction";
import { CUSTOMER_ROUTES } from "../../constants/FrontendRoutes";
import { CUSTOMER_DASHBOARD_TAB_LIST } from "../../constants";
import MenuOptionButton from "../Button/MenuOptionButton";
import { ArrowDownIcon } from "../../common/icons";
import Logo from "../../images/Logo.svg";
import Sidebar from "../NavBar/Customer/Sidebar";
import { Logout, Settings } from "@mui/icons-material";
import NotificationCount from "../NotificationCount";

const CustomerLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const unreadCount = useSelector((state) => state.message.unreadMessages);
  const buttonConfig = [
    {
      name: "Setting",
      iconStart: <Settings />,
      function: () => navigate(CUSTOMER_ROUTES.MY_ACCOUNT),
    },
    {
      name: "Logout",
      iconStart: <Logout />,
      function: () => dispatch(logout()),
    },
  ];

  useEffect(() => {
    dispatch(fetchUnreadCount());
  }, [dispatch]);

  return (
    <div className="bg-[#F8F8F8] h-screen">
      <div className="mx-auto max-w-[1280px]">
        <div className="p-5 flex justify-between items-center w-full border-b">
          <div className="flex items-center gap-3">
            <Link to={CUSTOMER_ROUTES.ORDERS_OVERVIEW}>
              <img alt="logo" src={Logo} className="w-7 lg:w-8 h-auto" />
            </Link>
            <div className="hidden md:flex">
              {CUSTOMER_DASHBOARD_TAB_LIST.map((tab, index) => {
                const isActive = location.pathname === tab.route;
                return (
                  <Link
                    key={index}
                    to={tab.route}
                    className="flex items-center px-4 py-2 rounded-lg"
                  >
                    <p
                      className={`text-base font-normal font-['Questrial'] leading-snug ${
                        isActive ? "text-[#4C8C4A]" : "text-[#272523]"
                      }`}
                    >
                      {tab.label}
                    </p>
                    {tab.value === CUSTOMER_DASHBOARD_TAB_LIST[2].value &&
                      unreadCount > 0 && (
                        <NotificationCount count={unreadCount} />
                      )}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <MediaQuery minWidth={767}>
              <MenuOptionButton
                title="My Account"
                data={buttonConfig}
                icon={<ArrowDownIcon fill="black" />}
                isMenuHorizontal={false}
              />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <Sidebar />
            </MediaQuery>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default CustomerLayout;
