import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Selector from "../Form/Field/AutoComplete";
import { formatDate } from "../../utils";
import CrownIcon from "../../images/CrownIcon.svg";

const MultipleConfirmationModal = ({
  open,
  onClose = () => {},
  onConfirm = () => {},
  title,
  message,
  date,
  count,
  description,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor,
  cancelButtonColor,
}) => {
  const [removeCount, setRemoveCount] = useState(1);
  const [seat, setSeat] = useState();

  const generateSeatOptions = (seatCount) => {
    return Array.from({ length: seatCount }, (_, i) => ({
      title: `${i + 1} ${i + 1 === 1 ? "seat" : "seats"}`,
      value: i + 1,
    }));
  };

  useEffect(() => {
    const seats = generateSeatOptions(count);
    setSeat(seats);
  }, []);

  useEffect(() => {
    if (count) {
      const seats = generateSeatOptions(count);
      setSeat(seats);
    }
  }, [count]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <Box
        className="bg-white rounded-lg shadow-md p-10 mx-auto"
        style={{
          width: "90%",
          maxWidth: "600px",
          outline: "none",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <Box className="flex justify-between items-start pb-2">
          <Typography
            id="confirmation-dialog-title"
            variant="h5"
            component="span"
            fontWeight="bold"
            style={{ maxWidth: "80%" }}
          >
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ marginTop: "-8px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="mt-2">
          {description && (
            <Typography variant="body1" className="mt-2">
              {description}
              <span className="font-bold text-xl">{formatDate(date)}</span>
            </Typography>
          )}
          {count > 1 && (
            <Box className="mt-4">
              <Typography>
                Please select how many seat you would like to remove.
              </Typography>
              <Selector
                name="count"
                label="Please select"
                size="large"
                options={seat || null}
                // value={seat.find((seats) => seats?.value === removeCount) || null}
                value={
                  seat && seat.length > 0
                    ? seat.find((seats) => seats?.value === removeCount) || null
                    : null
                }
                onChange={(_, value) => setRemoveCount(value?.value)}
              />
            </Box>
          )}
          <Typography color="error" variant="body1" className="mt-4">
            {message}
          </Typography>
        </Box>
        <Box className="flex justify-end mt-10 space-x-2">
          <Button
            onClick={onClose}
            variant="outlined"
            style={{
              borderColor: cancelButtonColor,
              color: cancelButtonColor,
              minWidth: "120px",
            }}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={() => onConfirm(removeCount)}
            variant="containedPrimary"
            style={{
              backgroundColor: confirmButtonColor,
              color: "white",
              minWidth: "120px",
            }}
          >
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

MultipleConfirmationModal.defaultProps = {
  title: "Are you sure you want to delete this category type?",
  message: "This action is permanent and cannot be undone.",
  confirmButtonText: "Confirm",
  cancelButtonText: "Cancel",
};

export default MultipleConfirmationModal;
