import { Button, Dialog } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { tours } from "../../tour";
import {
  TOUR_OPTION_STATUS,
  TOUR_OPTION_STATUS_ENUM,
  TOUR_OPTION_TYPE,
} from "../../constants";
import { useSelector } from "react-redux";
import { selectTour } from "../../redux/tourSlice";
import { updateTourStatus } from "../../redux/tourAction";

export const TourModal = ({ open, close }) => {
  const dispatch = useDispatch();
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const currentTour = useSelector(
    selectTour(TOUR_OPTION_TYPE.DASHBOARD_PAGE)
  );

  const tour = tours.dashboard({
    currentTour,
    updateTourStatus,
    dispatch,
    isDesktopScreen,
  });
  const skipTour = tours.skip({ isDesktopScreen });

  const handleStartTour = () => {
    close(false);
    tour.start();
  };

  const handleClose = () => {
    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.skip) {
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.SKIP,
        })
      );
    }

    close(false);
    skipTour.start();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <div className="flex flex-col p-4 gap-4">
        <h3 className="text-lg font-bold font-[Montserrat]">
          Welcome to Coblr
        </h3>
        <p className="text-sm font-base font-[Questrial]">
          Your account has been created! Would you like to take a tour of the
          product?
        </p>
        <p className="text-sm font-base font-[Questrial]">
          You can click [Skip for now] and come back any time you want by
          clicking the lightbulb icon in the upper right hand corner.
        </p>
        <div className="flex justify-end gap-4">
          <Button className="secondary_btn" onClick={handleClose}>
            Skip for now
          </Button>
          <Button variant="containedPrimary" onClick={handleStartTour}>
            Take a tour
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
