import React, { useState } from "react";
import {
  Button,
  Checkbox,
  MenuItem,
  ListItemText,
  Select,
} from "@mui/material";
import { CrossIcon } from "../../common/icons";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";
import { isEmpty, isEqual } from "lodash";

const DownloadShippingLabel = ({
  shippingLabels,
  onClose,
}) => {
  const [selectedLabels, setSelectedLabels] = useState([]);
  const dispatch = useDispatch();

  const dropdownOptions = shippingLabels
    .filter((label) => label?.label_pdf?.url)
    .map((label) => ({
      id: label.id,
      trackingNumber: label?.tracking_number,
      legInfo: `Leg #${label?.shipping_leg.split("_")[1]}`,
      shippingLeg: label?.shipping_leg,
      labelData: label?.label_pdf?.url,
    }));

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      if (isEqual(selectedLabels?.length, dropdownOptions?.length)) {
        setSelectedLabels([]);
      } else {
        setSelectedLabels(dropdownOptions.map((option) => option?.id));
      }
    } else {
      setSelectedLabels(value);
    }
  };

  const isAllSelected = isEqual(selectedLabels?.length, dropdownOptions?.length);

  const handleDownload = async () => {
    dispatch(setLoading(true));
    const selected = dropdownOptions.filter((option) =>
      selectedLabels.includes(option?.id)
    );

    try {
      selected.forEach((label, index) => {
        if (!label?.labelData) {
          dispatch(addToast("No shipping label found"));
          return;
        }

        setTimeout(() => {
          const link = document.createElement("a");
          link.href = label?.labelData;
          link.target = "_self";
          link.click();
          link.remove();
        }, index * 3000);
      });
      dispatch(addToast("Preparing your PDF for download..."));
    } catch (error) {
      dispatch(
        addToast(error?.message || "An error occurred while processing labels")
      );
    } finally {
      dispatch(setLoading(false));
      onClose();
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2 md:w-[535px] w-[calc(100vw-100px)] rounded-lg transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg px-4 py-4 text-[16px]">
      <div className="flex justify-between items-center">
        <p className="font-bold font-['Montserrat'] text-[18px] md:text-2xl">
          Download Shipping Label
        </p>
        <CrossIcon
          className="md:w-auto md:h-auto h-[28px] w-[28px] cursor-pointer"
          onClick={onClose}
        />
      </div>

      <div className="mt-2">
        <p className="text-base font-['Questrial'] text-[#272523]">
          You have created multiple shipping labels for this order. Please
          select which one you’d like to download below:
        </p>
      </div>

      <div className="flex flex-row justify-between mt-6 gap-4">
        <div className="flex justify-center items-center">
          <p className="font-medium font-['Questrial'] text-black mb-2">
            Shipment
          </p>
        </div>
        <div className="flex justify-center items-center">
          <Select
            multiple
            size="small"
            value={selectedLabels}
            onChange={handleSelectionChange}
            renderValue={(selected) =>
              isEmpty(selected)
                ? "Please select"
                : `${selected.length} selected`
            }
            displayEmpty
            fullWidth
            sx={{
              width: { xs: "100%", sm: "250px" },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  maxHeight: "320px",
                },
              },
            }}
          >
            <MenuItem
              value="all"
              sx={{
                "&:hover": {
                  backgroundColor: "#F3F0E8",
                  color: "green",
                },
              }}
            >
              <Checkbox checked={isAllSelected} />
              <ListItemText
                primary={
                  <p className="text-[#939291] font-['Questrial']">
                    Select all
                  </p>
                }
              />
            </MenuItem>
            {dropdownOptions.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  "&:hover": {
                    backgroundColor: "#F3F0E8",
                    color: "green",
                  },
                }}
              >
                <Checkbox checked={selectedLabels.includes(option?.id)} />
                <ListItemText
                  primary={
                    <>
                      <span className="text-xs text-[#939291] font-['Questrial']">
                        Tracking: {option?.trackingNumber}
                      </span>
                      <br />
                      <span className="font-medium text-black font-['Questrial']">
                        {option?.legInfo}
                      </span>
                    </>
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="flex justify-end gap-3 mt-6">
        <Button
          variant="outlined"
          sx={{ color: "black", borderColor: "gray", minWidth: "120px" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={isEmpty(selectedLabels)}
          variant="contained"
          color="success"
          sx={{ minWidth: "120px" }}
          onClick={handleDownload}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default DownloadShippingLabel;
