import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";
import { BUILD_POS_OPTION, TOUR_OPTION_STATUS, TOUR_OPTION_STATUS_ENUM } from "../constants";
import { tours } from ".";
import { updateTourStatus } from "../redux/tourAction";

export const posTour = ({ currentStore ,currentTour, dispatch, isDesktopScreen = true, setIsSetupPosModalOpen }) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      scrollTo: false,
      showCancelLink: true,
      modalOverlayOpeningRadius: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
  });

  const onComplete = () => {
    if(currentTour?.status !== TOUR_OPTION_STATUS_ENUM.complete)
      dispatch(updateTourStatus({ id: currentTour?.id, status: TOUR_OPTION_STATUS.COMPLETE }));

    if(![BUILD_POS_OPTION.DONE,BUILD_POS_OPTION.NEVER].includes(currentStore?.store?.build_pos_option))
      setIsSetupPosModalOpen(true);
  };

  const onSkip = () => {
    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.skip)
      dispatch(updateTourStatus({ id: currentTour?.id, status: TOUR_OPTION_STATUS.SKIP }));

    const skip = tours.skip({ isDesktopScreen });
    skip.start();
  };

  tour.on("complete", onComplete);
  tour.on("cancel", onSkip);

  const steps = [
    {
      id: "Step 1",
      text: ` <div>
                  <p class="font-bold text-xl">Manage your services and products list</p>
                  <p class="text-sm text-gray-500 pt-2">
                    You can manage your services, products, options, and pricing here. We provide a pre-made template or you can customize your own POS.
                  </p>
                </div>`,
      attachTo: {
        element: ".pos-Step1",
        on: "bottom",
      },
      classes: "mr-96",
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: `${index + 1}/${steps.length}`,
          action: null,
          classes: "step_counter",
          disabled: true,
        },
        {
          text: "Skip for now",
          action: tour.cancel,
          classes: "secondary_btn",
        },
        {
          text: index === steps.length - 1 ? "Next" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
