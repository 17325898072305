import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTabs, { CustomTabPanel } from "../common/components/Tabs";
import MappingField from "../components/POS/MappingField";
import {
  BUILD_POS_OPTION,
  COBLRSHOP_PRODUCT_FIELDS,
  POS_TAB_LIST,
  TOUR_OPTION_STATUS_ENUM,
  TOUR_OPTION_TYPE,
} from "../constants";
import EntityPage from "../components/EntityPage";
import Service from "../components/POS/EmbededTab/Service";
import Product from "../components/POS/EmbededTab/Product";
import Option from "../components/POS/EmbededTab/Option";
import CategoryType from "../components/POS/EmbededTab/CategoryType";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { selectTour } from "../redux/tourSlice";
import { tours } from "../tour";
import { SetupPosModal } from "../components/Modal/SetupPosModal";
import Disclaimer from "../components/Disclaimer/Disclaimer";
import { selectCurrentStore } from "../redux/storeSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { selectCurrentUser } from "../redux/authSlice";
import { BuildPosModal } from "../components/Modal/BuildPosModal";
import { updateBuildPosOptionStatus } from "../redux/storeAction";

const POS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("tab") || POS_TAB_LIST[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [csvDataMapping, setCsvDataMapping] = useState([]);
  const [isSetupPosModalOpen, setIsSetupPosModalOpen] = useState(false);
  const [isBuildPosModalOpen, setIsBuildPosModalOpen] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const currentTour = useSelector(selectTour(TOUR_OPTION_TYPE.POS_PAGE));
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const tour = tours.pos({
    currentStore,
    dispatch,
    navigate,
    currentTour,
    isDesktopScreen,
    setIsSetupPosModalOpen,
  });
  const disclaimerTour = tours.disclaimer();

  useEffect(() => {
    if (currentTour?.status === TOUR_OPTION_STATUS_ENUM.incomplete) {
      tour.start();
    }
  }, []);

  useEffect(() => {
    if (
      [BUILD_POS_OPTION.SKIP, BUILD_POS_OPTION.IN_PROGRESS].includes(
        currentStore?.store?.build_pos_option
      )
    ) {
      setIsDisclaimerOpen(true);
      if (
        currentStore?.store?.build_pos_option === BUILD_POS_OPTION.IN_PROGRESS
      )
        setInProgress(true);
    } else {
      setIsDisclaimerOpen(false);
      setInProgress(false);
    }
  }, [currentStore?.store]);

  useEffect(() => {
    const tabValue = searchParams.get("tab") || POS_TAB_LIST[0].value;
    setCurrentTab(tabValue);

    if (!searchParams.has("tab")) {
      searchParams.set("tab", POS_TAB_LIST[0].value);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [location.search, searchParams, navigate]);

  const handleTabChange = (newValue) => {
    const newTab = POS_TAB_LIST[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;
      const csvFields = csvData?.split("\n")[0]?.split(",");
      setCsvDataMapping(
        csvFields.map((item) => ({
          csvField: item,
          coblrshopField: COBLRSHOP_PRODUCT_FIELDS.includes(item) ? item : "",
          isMapField: COBLRSHOP_PRODUCT_FIELDS.includes(item),
        }))
      );
    };

    reader.readAsText(file);
  };

  const handleCheckedBox = (value, index) => {
    setCsvDataMapping((prevMapping) => {
      return prevMapping.map((item, i) => {
        if (i === index) {
          return { ...item, isMapField: value };
        }
        return item;
      });
    });
  };

  const handleDropdownChange = (value, index) => {
    setCsvDataMapping((prevMapping) => {
      return prevMapping.map((item, i) => {
        if (i === index) return { ...item, coblrshopField: value };

        if (item.coblrshopField === value)
          return { ...item, coblrshopField: "" };
        return item;
      });
    });
  };

  const handleDisclaimerClose = () => {
    setIsDisclaimerOpen(false);
    isDesktopScreen && disclaimerTour.start();
  };

  const handleCancelPosBuild = () => {
    dispatch(updateBuildPosOptionStatus({ store_id: currentStore?.store_id, option: BUILD_POS_OPTION.NEVER }));
  };

  const renderTabPanel = (Component, index) => (
    <CustomTabPanel
      value={POS_TAB_LIST.findIndex((tab) => tab.value === currentTab)}
      index={index}
    >
      {csvDataMapping.length === 0 ? (
        <Component onCSVUpload={uploadFile} />
      ) : (
        <MappingField
          csvFields={csvDataMapping}
          handleCheckedBox={handleCheckedBox}
          handleDropdownChange={handleDropdownChange}
          handleBack={() => setCsvDataMapping([])}
        />
      )}
    </CustomTabPanel>
  );

  return (
    <EntityPage
      title="Services & Products"
      isInfoButtonEnabled={currentTour?.status !== TOUR_OPTION_STATUS_ENUM.incomplete}
      infoButtonClass="skipTourStep1"
      infoButtonFunction={() => tour.start()}
    >
      {isDisclaimerOpen && (
        <Disclaimer
          title="🚀 Get started with your POS in one click!"
          description="Not sure where to start? Use a standard structure of categories and services that other repair shops rely on!"
          handleSubmit={() => setIsSetupPosModalOpen(true)}
          handleClose={handleDisclaimerClose}
          handleCancel={handleCancelPosBuild}
          progressBar={inProgress}
        />
      )}

      <Box class="pos-Step1 relative">
        {!isDisclaimerOpen &&
          currentStore?.store?.build_pos_option === BUILD_POS_OPTION.SKIP &&
          isDesktopScreen && (
            <IconButton
              onClick={() => setIsDisclaimerOpen(true)}
              size="small"
              className="disclaimerStep1"
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "10px",
                right: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        <BasicTabs
          list={POS_TAB_LIST.map((tab) => tab.label)}
          setValue={handleTabChange}
          value={POS_TAB_LIST.findIndex((tab) => tab.value === currentTab)}
        />
        {renderTabPanel(Service, 0)}
        {renderTabPanel(Product, 1)}
        <CustomTabPanel
          value={POS_TAB_LIST.findIndex((tab) => tab.value === currentTab)}
          index={2}
        >
          <Option />
        </CustomTabPanel>
        <CustomTabPanel
          value={POS_TAB_LIST.findIndex((tab) => tab.value === currentTab)}
          index={3}
        >
          <CategoryType />
        </CustomTabPanel>
      </Box>
      <SetupPosModal
        open={isSetupPosModalOpen}
        close={setIsSetupPosModalOpen}
        handleSkip={setIsDisclaimerOpen}
        setIsBuildPosModalOpen={setIsBuildPosModalOpen}
      />
      <BuildPosModal
        open={isBuildPosModalOpen}
        close={setIsBuildPosModalOpen}
      />
    </EntityPage>
  );
};

export default POS;
