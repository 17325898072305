import numeral from "numeral";
import moment from "moment";
import { CART_TYPES, DISCOUNT_TYPES, ORDER_STATUS_ENUM } from "../constants";
import { RAILS_APP_API_URL } from "../constants/BackendRoutes";

export const sumBy = (arr, attr) => {
  const sum = arr.reduce(
    (sum, product) =>
      sum +
      (Number.isNaN(parseFloat(product[attr])) ? 0 : parseFloat(product[attr])),
    0
  );
  return parseFloat(sum.toFixed(2));
};

export function categorizeMessages(messages) {
  const categorized = {
    Today: [],
    Yesterday: [],
  };

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  messages.forEach((message) => {
    const messageDate = new Date(message.created_at);
    const messageDateStart = new Date(
      messageDate.getFullYear(),
      messageDate.getMonth(),
      messageDate.getDate()
    );

    if (messageDateStart.getTime() === today.getTime()) {
      categorized.Today.push(message);
    } else if (messageDateStart.getTime() === yesterday.getTime()) {
      categorized.Yesterday.push(message);
    } else {
      const dateString = messageDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      if (!categorized[dateString]) {
        categorized[dateString] = [];
      }
      categorized[dateString].push(message);
    }
  });

  return categorized;
}

export function convertTo24HourFormat(utcTimeString) {
  const date = new Date(utcTimeString);

  if (isNaN(date.getTime())) {
    return "";
  }

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export function formatTimeDifference(utcTimeString) {
  const currentDate = new Date();
  const date = new Date(utcTimeString);

  if (isNaN(date.getTime())) {
    return "";
  }

  const timeDifference = currentDate - date;
  const oneHour = 60 * 60 * 1000;
  const oneDay = 24 * oneHour;

  if (timeDifference < oneHour) {
    return "Now";
  } else if (timeDifference < 2 * oneHour) {
    return "1hr ago";
  } else if (timeDifference < oneDay) {
    const hoursAgo = Math.floor(timeDifference / oneHour);
    return `${hoursAgo}hrs ago`;
  } else if (timeDifference < 2 * oneDay) {
    return "Yesterday";
  } else {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
}

export function convertDateToISOFormat(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function generateRandomString(length = 10) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function parsePriceIntoFloat(priceString) {
  return parseFloat(priceString.replace(/[^0-9.-]+/g, ""));
}

export function maskUSD(price) {
  return `$${parseFloat(price).toFixed(2)}`;
}

export function sortRows(rows, sortBy, sortOrder, columns) {
  if (!sortBy) return rows;
  const column = columns.find((col) => col.label === sortBy);
  if (!column) return rows;

  return [...rows].sort((a, b) => {
    let aValue = a[column.field];
    let bValue = b[column.field];

    if (column.field === "price") {
      aValue = parsePriceIntoFloat(aValue);
      bValue = parsePriceIntoFloat(bValue);
    }

    if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });
}

export function calculateServiceSubtotal(serviceLineItems, rushOrder) {
  const rushOrderPrice = rushOrder?.isRush ? rushOrder?.price : 0;
  const serviceTotal = serviceLineItems?.reduce((acc, item) => {
    const servicesTotal =
      item.services?.reduce(
        (subAcc, service) => subAcc + (service.price || 0),
        0
      ) || 0;
    const optionsTotal =
      item.options?.reduce(
        (subAcc, option) => subAcc + (option.price || 0),
        0
      ) || 0;

    return acc + (servicesTotal + optionsTotal) * item.quantity;
  }, 0) || 0;

  return serviceTotal + rushOrderPrice;
}

export function calculateProductSubtotal(productLineItems) {
  return (
    productLineItems?.reduce(
      (acc, { price, quantity }) => acc + (price || 0) * quantity,
      0
    ) || 0
  );
}

export function calculateSubTotal(cart) {
  const { serviceSubTotal, productSubTotal } = cart;
  return serviceSubTotal + productSubTotal;
}

export function calculateDiscountFor(total, discount, overallTotal) {
  if (discount?.method_value === DISCOUNT_TYPES.percentage) {
    return (total / 100) * discount.value;
  } else if (discount?.method_value === DISCOUNT_TYPES.amount) {
    const amountIsWhatPercentOfTotal = (discount.value / overallTotal) * 100;
    return (total / 100) * amountIsWhatPercentOfTotal;
  }
  return 0;
}

export function calculateDiscount(serviceTotal, productTotal, discount) {
  if (!discount) return 0;

  const overallTotal = serviceTotal + productTotal;
  const serviceDiscount = calculateDiscountFor(serviceTotal, discount, overallTotal);
  const productDiscount = calculateDiscountFor(productTotal, discount, overallTotal);

  return serviceDiscount + productDiscount;
}

export function calculateServicesSalesTax(serviceTotal, productTotal, discount, serviceTaxRate) {
  const serviceDiscount = calculateDiscountFor(
    serviceTotal,
    discount,
    serviceTotal + productTotal
  );

  return (serviceTotal - serviceDiscount) * serviceTaxRate;
}

export function calculateProductsSalesTax(serviceTotal, productTotal, discount, productTaxRate) {
  const productDiscount = calculateDiscountFor(
    productTotal,
    discount,
    serviceTotal + productTotal
  );

  return (productTotal - productDiscount) * productTaxRate;
}

export function calculateTotal(cart) {
  const {
    serviceSubTotal,
    productSubTotal,
    totalDiscount,
    serviceSalesTax,
    productSalesTax,
  } = cart;

  return serviceSubTotal + productSubTotal - totalDiscount + serviceSalesTax + productSalesTax;
}

export function calculateChangeDue(cart, amountTendered) {
  return amountTendered - calculateTotal(cart);
}

export function groupedBy(array, key) {
  return array?.reduce((acc, obj) => {
    const keyValue = obj[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(obj);
    return acc;
  }, {});
}

export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validatePhoneNumber(phoneNumber) {
  const emailRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  return emailRegex.test(phoneNumber);
}

export function validateZipcode(zipcode) {
  const zipcodeRegex = /^\d{5}(-\d{4})?$/;
  return zipcodeRegex.test(zipcode);
}

export function transformCreateOrderPayload(orderType, cart, billLater) {
  const {
    estimateCompleteDate,
    paymentMethod,
    rushOrder,
    discount,
    serviceLineItems,
    productLineItems,
  } = cart;

  const transformLineItems = (items) => {
    return items.flatMap((item) => {
      const { quantity, ...rest } = item;
      return Array.from({ length: quantity }, (_, index) => ({
        details: { ...rest },
        line_item_type: item.type,
      }));
    });
  };

  const transformedLineItems = [
    ...transformLineItems(
      (serviceLineItems || []).map((item) => ({ ...item, type: "service" }))
    ),
    ...transformLineItems(
      (productLineItems || []).map((item) => ({ ...item, type: "product" }))
    ),
  ];

  const status =
    cart.type == CART_TYPES.checkout
      ? ORDER_STATUS_ENUM.new_order
      : ORDER_STATUS_ENUM.quote;
  return {
    status,
    estimated_completion: estimateCompleteDate,
    payment_method: paymentMethod,
    rush_fee: rushOrder.isRush ? rushOrder.price : 0,
    sub_total:
      cart.serviceSubTotal +
      cart.productSubTotal -
      (rushOrder.isRush ? rushOrder.price : 0),
    discount_id: discount?.id,
    service_sales_tax: cart.serviceSalesTax,
    product_sales_tax: cart.productSalesTax,
    net_total: calculateTotal(cart),
    placement_type: orderType,
    line_items: transformedLineItems,
    under_warranty: billLater,
  };
}

export const capitalize = (str) => {
  return str
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const hasNonEmptyValues = (obj) => {
  return Object.keys(obj).some((key) => obj[key] !== "" && obj[key] !== null);
};

export const diffImageSrc = (imgSrc) => {
  if (imgSrc) {
    if (typeof imgSrc === "string") {
      return `${RAILS_APP_API_URL}${imgSrc}`;
    } else if (imgSrc instanceof File) {
      return URL.createObjectURL(imgSrc);
    }
  }
  return null;
};

export function transformArrayToTitleObjects(array) {
  return array
    .filter((item) => item !== null && item !== undefined)
    .map((item, index) => ({
      title: item,
      value: index,
    }));
}

export function convertBase64ToPdfAndDownload(
  encodedData,
  fileName = "downloaded_file.pdf"
) {
  const byteCharacters = atob(encodedData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const convertDateFormat = (inputDate) => {
  if (!inputDate) return "-";
  const date = new Date(inputDate);
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
};

export const formatTo24Hour = (inputDate = "00:00") => {
  const date = new Date(inputDate);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const caseConversion = (type) => {
  return type
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};

export const groupServicesByType = (services) => {
  return (services || []).reduce((acc, service) => {
    const formattedType = service.type
      ? caseConversion(service.type)
      : "Services";
    if (!acc[formattedType]) {
      acc[formattedType] = [];
    }
    acc[formattedType].push({ id: service.id, name: service.name });
    return acc;
  }, {});
};

export const groupByEnums = (items, statusEnum, key) => {
  const groupedItems = Object.keys(statusEnum).reduce((acc, statusKey) => {
    acc[statusKey] = [];
    return acc;
  }, {});

  items.forEach((item) => {
    if (statusEnum.hasOwnProperty(item[key])) {
      groupedItems[item[key]].push(item);
    }
  });

  return groupedItems;
};

export const humanizeNumber = (number) => {
  const formattedNumber = numeral(number).format("0.00a");
  return formattedNumber
    .replace(/k/i, "K")
    .replace(/m/i, "M")
    .replace(/b/i, "B");
};

export const formatDateToLongNumeric = (dateString) => {
  const options = { month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export function filterLineItems(orderLineItems, filterByType) {
  return orderLineItems.filter((item) => item.line_item_type === filterByType);
}

/** Converts 2024-07-29 into July 29th, 2024 */
export function formatDate(dateString) {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.format("MMMM Do, YYYY");
}

export function addDaysToSpecificDate(dateString, daysToAdd) {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.add(daysToAdd, "days");
}

export function daysDifferenceFromToday(targetDateStr) {
  const targetDate = moment(targetDateStr, "YYYY-MM-DD");
  const today = moment();
  const diffInDays = targetDate.diff(today, "days");
  return diffInDays;
}
export  const calculateDaysRemaining = (dueDate) => {
  const currentDate = new Date();
  const dueDateObj = new Date(dueDate);
  const timeDiff = dueDateObj - currentDate;
  const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysRemaining;
};

// Function to check if the object has no properties
export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Get latest shipping address
export function getLatestAddress(addresses) {
  if (!addresses || addresses.length === 0) return {};
  // Filter for shipping addresses
  const shippingAddresses = addresses?.filter(
    (address) => address?.address_type === "shipping"
  );

  // If there are shipping addresses, return the latest one
  if (shippingAddresses?.length > 0) {
    return shippingAddresses.reduce((latest, current) => {
      return new Date(current.updated_at) > new Date(latest.updated_at)
        ? current
        : latest;
    });
  }

  // If no shipping address, fallback to the latest of any address type
  return addresses?.reduce((latest, current) => {
    return new Date(current.updated_at) > new Date(latest.updated_at)
      ? current
      : latest;
  });
}

// Function to check if all fields of an object are filled (not empty, null, or undefined)
// It also checks for nested objects, returning false for empty objects.
export const isObjectFieldsFilled = (obj) => {
  if (Object.keys(obj).length === 0) return false;

  return Object.values(obj).every((value) => {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return isObjectFieldsFilled(value);
    }
    return value !== null && value !== undefined && value !== "";
  });
};

// Function to check if an object is partially filled
export const isObjectPartiallyFilled = (obj) => {
  if (Object.keys(obj).length === 0) return false;

  const isFilled = (value) => value !== null && value !== undefined && value !== "";

  let hasFilled = false;
  let hasEmpty = false;

  for (const value of Object.values(obj)) {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      if (isObjectPartiallyFilled(value)) return true;
    } else {
      isFilled(value) ? hasFilled = true : hasEmpty = true;
    }

    if (hasFilled && hasEmpty) return true;
  }

  return false;
};

/**
 * Tokenizes a given string into an array of lowercase, trimmed words (tokens).

 * @example
 * tokenizeString(" Hello   World! This | is a Test. ");
 * // Output: ["hello", "world!", "this", "is", "a", "test."]
 *
 * tokenizeString("  Multiple\nLines   and \tSpaces ");
 * // Output: ["multiple", "lines", "and", "spaces"]
 */
export const tokenizeString = (str) =>
  str
    .toLowerCase()
    .split(/[\s|]+/)
    .filter((token) => token.trim() !== "");


/**
 * Calculates a relevance score for a given key based on its similarity to the user's search input.
 *
 * Scoring Rules:
 * - Exact match: +20 points
 * - Substring match: +10 points + the length of the search input
 * - Partial token match (substring match of a token): +2 points per token
 * - Exact token match: +5 points per token
 *
 * @param {string} key - The searchable item to be scored.
 * @param {string} debouncedSearchInput - The user's search query.
 * @returns {number} - The relevance score of the key.
 *
 * Example:
 * const debouncedSearchInput = "hello world";
 * calculateSearchScore("hello world", debouncedSearchInput); // Output: 51
 * calculateSearchScore("hello universe", debouncedSearchInput); // Output: 17
 */
export const calculateMatchScore = (key, debouncedSearchInput) => {
  const normalizedKey = key.toLowerCase();
  const normalizedSearchInput = debouncedSearchInput.toLowerCase();
  const searchInputTokens = tokenizeString(normalizedSearchInput);
  const keyTokens = tokenizeString(normalizedKey);

  let score = 0;

  if (normalizedKey === normalizedSearchInput) {
    score += 20;
  }

  if (normalizedKey.includes(normalizedSearchInput)) {
    score += 10 + normalizedSearchInput.length;
  }

  for (const token of searchInputTokens) {
    if (keyTokens.some((keyToken) => keyToken.includes(token))) {
      score += 2;
    }
    if (keyTokens.includes(token)) {
      score += 5;
    }
  }

  return score;
};

export const isHtmlText = (text) => /<\/?[a-z][\s\S]*>/i.test(text);