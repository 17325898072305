


import CircleIcon from "@mui/icons-material/Circle";
import React from "react";

const OrderAssignmentItem = ({
  notification,
  handleNotificationClick,
  isFullPage,
}) => {
  const { orderId, read, customerName, estimatedCompletionDate, createdAt } = notification;

  return (
    <div
      className="flex flex-col sm:flex-row gap-2 mb-4 w-full"
      onClick={() => handleNotificationClick(notification)}
    >
      <div className="flex flex-col gap-1 flex-1 font-['Questrial'] font-normal text-base">
        <div className="flex justify-between flex-wrap gap-1">
          <div className="flex gap-1">
            {!read && (
              <CircleIcon
                sx={{
                  marginTop: "7px",
                  width: "10px",
                  height: "10px",
                  color: "#D84141",
                }}
              />
            )}
            <span>You have a new assignment</span>
          </div>
          <div className="font-['Questrial'] font-normal text-sm text-[#939291]">
            {createdAt}
          </div>
        </div>

        {isFullPage ? (
          <div className="flex flex-wrap gap-2 items-center text-sm">
            <div className="flex items-center border-r border-gray-300 pr-4">
              <span className="text-[#939291]">Order&nbsp;</span>
              <span className="text-[#272523] mr-2">#{orderId}</span>
            </div>
            <div className="flex items-center border-r border-gray-300 pr-4">
              <span className="text-[#939291]">
                Customer: <span className="text-[#272523]">{customerName}</span>
              </span>
            </div>
            <div className="flex items-center pr-4">
              <span className="text-[#939291]">
                Est. completed by:{" "}
                <span className="text-[#272523]">
                  {estimatedCompletionDate}
                </span>{" "}
              </span>
            </div>
          </div>
        ) : (
          <div className="font-['Questrial'] font-normal text-sm">
            <span className="text-[#939291]">Order&nbsp;</span>
            <span className="text-[#272523]">#{orderId}&nbsp;</span>
            <span className="text-[#939291]">
              for <span className="text-[#272523]">{customerName}&nbsp;</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderAssignmentItem;
