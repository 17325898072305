import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { useSelector } from "react-redux";
import { selectCurrentOrganization, selectSubscription } from "../../redux/organizationSlice";
import { addToast } from "../../redux/toastSlice";
import { updateRequest } from "../../api";

export const CancellationOptions = ({ close, setCurrentStep }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription);

  const options = [
    {
      name: "Coblr isn’t solving my problem",
    },
    {
      name: "My team doesn’t use Coblr often enough",
    },
    {
      name: "I found an alternative",
      displayInput: true,
      inputFieldText: "Product name",
    },
    {
      name: "It’s too expensive",
    },
    {
      name: "My team no longer needs it",
    },
    {
      name: "Other reason",
      displayInput: true,
      inputFieldText: "Please Specify",
    },
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (!option.displayInput) {
      setInputValue("");
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await updateRequest(
        `organizations/${currentOrganization?.id}/subscriptions/${organizationSubscription?.id}`,
        {
          subscription: {
            cancellation_reason: selectedOption?.name?.concat(inputValue),
          },
        }
      );

      if (response?.status === 200)
        setCurrentStep(2)
    } catch (error) {
      dispatch(addToast(error))
    }
  };

  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-[#272523] text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
          Why do you want to cancel?
        </p>
      </div>

      <Box className="space-y-4">
        {options.map((option, index) => (
          <Box
            key={index}
            className="flex flex-col cursor-pointer"
            onClick={() => handleOptionSelect(option)}
          >
            <Box className="flex items-center gap-0">
              <div
                className={`w-5 h-5 rounded-full flex justify-center items-center mr-3 ${
                  selectedOption?.name === option.name
                    ? "bg-[#4C8C4A]"
                    : "border-2 border-gray-400"
                }`}
              >
                {selectedOption?.name === option.name && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </div>
              <p className="text-base text-gray-800 font-[Questrial]">
                {option.name}
              </p>
            </Box>

            {selectedOption?.name === option.name && option.displayInput && (
              <TextField
                placeholder={option.inputFieldText || "Type your response"}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                sx={{
                  mt: 2,
                  pl: 3.5,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            )}
          </Box>
        ))}
      </Box>

      <Box className="flex justify-end space-x-3">
        <Button
          onClick={close}
          variant="outlined"
          sx={{
            minWidth: "120px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!selectedOption}
          variant="contained"
          sx={{
            minWidth: "120px",
          }}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};
