import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { formatDate } from "../../utils";
import { CARD_NETWORK, SUBSCRIPTION_STATUS } from "../../constants";
import MultipleConfirmationModal from "../Modal/MultipleConfirmationModal";
import MenuOptionButton from "../Button/MenuOptionButton";
import SubscriptionCancelationFlow from "../Modal/SubscriptionCancelationFlow";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";
import {
  selectCurrentOrganization,
  selectSubscription,
  updateSubscription,
} from "../../redux/organizationSlice";
import {
  fetchOrganizationData,
  fetchOrganizationSubscription,
} from "../../redux/organizationAction";
import { postRequest, updateRequest, deleteRequest } from "../../api";
import Disclaimer from "../Disclaimer/Disclaimer";
import { ADD_ON_TYPES } from "../../constants";
import SubAccountModal from "../Modal/SubAccountModal";

const SettingBilling = ({ setOpenPlanModal }) => {
  const [subscription, setSubscription] = useState();
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [openRemoveAddOnModal, setOpenRemoveAddOnModal] = useState(false);
  const [openSubAccountModal, setOpenSubAccountModal] = useState(false);
  const [addOns, setAddOns] = useState([]);
  const [selectedAddOnType, setSelectedAddOnType] = useState(null);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription);
  const dispatch = useDispatch();

  const MODAL_CONFIGS = {
    [ADD_ON_TYPES.USERS_COUNT]: {
      title: "Are you sure you want to remove the add-on seat?",
      description:
        "You will still be able to use the add-on seat during this billing cycle. Your additional seat will be removed on ",
      confirmButtonText: "Remove Seats",
      successMessage: "Removed Add-on subscription successfully",
    },
    [ADD_ON_TYPES.SMS]: {
      title: "Are you sure you want to disable SMS feature?",
      description:
        "You will still be able to use SMS feature during this billing cycle. Your SMS feature will be removed on ",
      confirmButtonText: "Disable SMS",
      successMessage: "SMS Feature disabled successfully",
    },
  };

  const findAddOn = (type) => {
    return addOns?.find((addon) => addon?.add_on_type === type);
  };

  const smsAddOn = findAddOn(ADD_ON_TYPES.SMS);
  const usersAddOn = findAddOn(ADD_ON_TYPES.USERS_COUNT);

  const buttonData = [
    {
      name: "Cancel subscription",
      function: () => setSubscriptionCancelModal(true),
      disabled:
        currentOrganization?.free_month_offer_accepted_at &&
        organizationSubscription?.free_month_coupon_availed &&
        organizationSubscription?.cancel_at_period_end,
    },
  ];

  if (smsAddOn || usersAddOn) {
    buttonData.unshift({
      name: smsAddOn ? "Disable SMS" : "Remove add-on",
      function: () => {
        setSelectedAddOnType(
          smsAddOn ? ADD_ON_TYPES.SMS : ADD_ON_TYPES.USERS_COUNT
        );
        setOpenRemoveAddOnModal(true);
      },
      disabled: false,
    });
  }

  const handlePlanModal = () => {
    setOpenPlanModal(true);
  };

  const isFreeMonthOfferValid = () => {
    const { free_month_offer_accepted_at } = currentOrganization || {};
    const { current_period_start, current_period_end } = subscription || {};

    if (
      !free_month_offer_accepted_at ||
      !current_period_start ||
      !current_period_end
    ) {
      return false;
    }

    const acceptedDate = new Date(free_month_offer_accepted_at);
    const startDate = new Date(current_period_start);
    const endDate = new Date(current_period_end);

    return acceptedDate >= startDate && acceptedDate <= endDate;
  };

  const handleSubAccountModal = () => {
    setOpenSubAccountModal(true);
  };

  const removeAddOnSubscription = async (count) => {
    if (!currentOrganization?.id) return;

    const currentAddOn =
      findAddOn(ADD_ON_TYPES.SMS) || findAddOn(ADD_ON_TYPES.USERS_COUNT);
    if (!currentAddOn) return;

    try {
      dispatch(setLoading(true));

      const isUsersCount =
        currentAddOn.add_on_type === ADD_ON_TYPES.USERS_COUNT;
      const endpoint = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/stripe/add_ons/${isUsersCount ? "decrease_limit" : "disable_sms"}`;

      if (isUsersCount) {
        await postRequest(endpoint, {
          organization_id: currentOrganization.id,
          users_count: currentAddOn.current_quantity - count,
        });
      } else {
        await deleteRequest(
          `${endpoint}?organization_id=${currentOrganization.id}`
        );
      }

      dispatch(
        addToast(MODAL_CONFIGS[currentAddOn.add_on_type].successMessage)
      );
      setOpenRemoveAddOnModal(false);

      await Promise.all([
        dispatch(
          fetchOrganizationData({
            organizationId: currentOrganization.id,
          })
        ),
      ]);
    } catch (error) {
      dispatch(
        addToast(
          error?.message ||
            `Failed to remove ${currentAddOn.add_on_type} add-on`
        )
      );
    } finally {
      dispatch(
        fetchOrganizationSubscription({
          organizationId: currentOrganization?.id,
        })
      );
      dispatch(
        fetchOrganizationData({ organizationId: currentOrganization?.id })
      );
      setAddOns(organizationSubscription?.subscription?.add_on);
      dispatch(setLoading(false));
    }
  };

  const handleReactivate = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(
        `organizations/${currentOrganization?.id}/subscriptions/${organizationSubscription?.id}`,
        { subscription: { cancel_at_period_end: false } }
      );

      if (response.status === 200) {
        dispatch(addToast(response?.data?.message));
        dispatch(
          updateSubscription({
            ...organizationSubscription,
            cancel_at_period_end: false,
          })
        );
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (organizationSubscription) {
      setSubscription(organizationSubscription);
      setAddOns(organizationSubscription?.add_on || []);
    }
  }, [organizationSubscription]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full text-stone-800">
      <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Billing
      </div>
      {organizationSubscription?.cancel_at_period_end && (
        <Disclaimer
          title={
            <p className="font-[Questrial] font-normal">
              You are still able access everything as usual up until{" "}
              <span className="text-xl font-bold">
                {formatDate(subscription?.current_period_end)}.
              </span>{" "}
              You won’t be charged with your next renewal.
            </p>
          }
          primaryButtonText="Reactivate your plan"
          handleSubmit={() => handleReactivate()}
        />
      )}

      {!organizationSubscription?.cancel_at_period_end &&
        organizationSubscription?.free_month_coupon_availed &&
        isFreeMonthOfferValid() && (
          <Disclaimer
            title={
              <p className="font-[Questrial] font-normal">
                The{" "}
                <span className="text-lg font-bold">
                  {subscription?.plan?.name}
                </span>{" "}
                is free for 1 Month, starting{" "}
                <span className="text-lg font-bold">
                  {formatDate(subscription?.current_period_end)}.
                </span>
              </p>
            }
          />
        )}

      {subscription ? (
        <>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
              <div className="p-4 rounded-tl-lg rounded-tr-lg flex justify-between items-center w-full">
                <div className="flex gap-2 items-center">
                  <span className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                    Current plan
                  </span>
                  {subscription?.status && (
                    <div
                      className={`px-2 py-1 bg-orange-100 text-orange-500 rounded-2xl justify-center items-center inline-flex w-fit`}
                    >
                      <div className="text-center text-xs font-normal font-['Questrial'] leading-none">
                        {subscription.status === SUBSCRIPTION_STATUS.TRIAL
                          ? "14 days Trial"
                          : subscription.status
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .replace(/^\w/, (c) => c.toUpperCase())}
                      </div>
                    </div>
                  )}
                </div>
                {(addOns || organizationSubscription) && (
                  <MenuOptionButton data={buttonData} />
                )}
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
              <div className="flex justify-between w-full px-4 pt-4 pb-2 rounded-tl-lg rounded-tr-lg">
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Plan
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex gap-2">
                    <span>{subscription?.plan?.name}</span>
                    {subscription?.status && (
                      <div
                        className={`px-2 py-1 bg-orange-100 text-orange-500 rounded-2xl justify-center items-center inline-flex w-fit`}
                      >
                        <div className="text-center text-xs font-normal font-['Questrial'] leading-none">
                          {subscription.status === SUBSCRIPTION_STATUS.TRIAL
                            ? "14 days Trial"
                            : subscription.status
                                .replace(/_/g, " ")
                                .toLowerCase()
                                .replace(/^\w/, (c) => c.toUpperCase())}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Pricing
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                    ${subscription?.plan?.amount_in_cents / 100} /{" "}
                    {subscription?.plan?.interval}
                  </div>
                </div>
              </div>
              {usersAddOn && (
                <>
                  <hr className="border-[0.5px] border-stone-300 w-full" />
                  <div className="flex justify-between w-full px-4 pt-4 pb-2 rounded-tl-lg rounded-tr-lg">
                    <div className="flex-col justify-center items-start gap-1 inline-flex">
                      <div className="text-sm font-normal font-['Questrial'] leading-snug">
                        Add-on Service
                      </div>
                      <div className="text-lg font-['Montserrat'] leading-[25.20px] flex gap-2">
                        <span>{`${usersAddOn?.initial_quantity} Additional seat`}</span>
                      </div>
                    </div>
                    <div className="flex-col justify-end items-end gap-1 inline-flex">
                      <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                        $
                        {(usersAddOn?.amount_in_cents *
                          usersAddOn?.initial_quantity) /
                          100}{" "}
                        / {subscription?.plan?.interval}
                      </div>
                    </div>
                  </div>
                  <hr className="border-[0.5px] border-stone-300 w-full" />
                </>
              )}
              {findAddOn(ADD_ON_TYPES.SMS) && (
                <>
                  <hr className="border-[0.5px] border-stone-300 w-full" />
                  <div className="flex justify-between w-full px-4 pt-4 pb-2 rounded-tl-lg rounded-tr-lg">
                    <div className="flex-col justify-center items-start gap-1 inline-flex">
                      <div className="flex text-sm font-normal font-['Questrial'] leading-snug">
                        Add-on Service
                      </div>
                      <div className="text-lg font-['Questrial'] leading-[25.20px] flex items-center gap-4">
                        <span className="font-bold">SMS notification</span>
                        <button
                          className="cursor-pointer border border-stone-200 rounded-full px-4 py-1 text-sm bg-stone-100 hover:bg-slate-200"
                          onClick={handleSubAccountModal}
                        >
                          Sub-Account
                        </button>
                      </div>
                    </div>
                    <div className="flex-col justify-end items-end gap-1 inline-flex">
                      <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                        ${15} / {subscription?.plan?.interval}
                      </div>
                    </div>
                  </div>
                  <hr className="border-[0.5px] border-stone-300 w-full" />
                  </>
              )}
              <div className="self-stretch px-4 pt-2 pb-4 rounded-tl-lg rounded-tr-lg justify-start items-start inline-flex">
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Current ending date
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                    {formatDate(subscription?.current_period_end)}
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
              <div className="p-4 rounded-tl-lg rounded-tr-lg justify-start items-center inline-flex">
                <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Payment method
                </div>
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
              <div className="px-8 py-12 rounded-tl-lg rounded-tr-lg justify-start items-center inline-flex">
                <div className="grow shrink basis-0 justify-start items-center gap-6 flex">
                  <div className="flex items-center justify-start gap-4 ">
                    {CARD_NETWORK[
                      subscription?.payment_method?.brand.toLowerCase()
                    ]?.logo && (
                      <img
                        src={
                          CARD_NETWORK[
                            subscription?.payment_method?.brand.toLowerCase()
                          ]?.logo
                        }
                        className="w-8 h-auto"
                      />
                    )}
                    <div>
                      <div className="flex-col justify-start items-start gap-1 flex">
                        <div className="text-xs font-medium font-['Work Sans']">
                          {
                            CARD_NETWORK[
                              subscription?.payment_method?.brand.toLowerCase()
                            ]?.title
                          }
                        </div>
                        <div className="text-xs font-medium font-['Work Sans']">
                          **** **** **** {subscription?.payment_method?.last4}
                        </div>
                      </div>
                      <div className="text-[10px] font-medium font-['Work Sans']">
                        Expire on {subscription?.payment_method?.exp_month}/
                        {subscription?.payment_method?.exp_year}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Manage your subscription and billing information.
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
              <div className="flex flex-row items-center justify-between w-full p-4 rounded-tl-lg rounded-tr-lg">
                <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Current plan
                </div>
                <Button
                  variant="outlined"
                  onClick={handlePlanModal}
                  sx={{
                    borderColor: "#d6d3d1",
                    color: "black",
                    "&:hover": {
                      borderColor: "#d6d3d1",
                    },
                  }}
                >
                  See our plan
                </Button>
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
              <p className="px-3 py-5 text-center text-[#5d5c5a] w-full">
                No active subscription. Browse our plans to get started.
              </p>
            </div>
          </div>
        </>
      )}

      <MultipleConfirmationModal
        open={openRemoveAddOnModal}
        onClose={() => {
          setOpenRemoveAddOnModal(false);
          setSelectedAddOnType(null);
        }}
        onConfirm={removeAddOnSubscription}
        title={selectedAddOnType ? MODAL_CONFIGS[selectedAddOnType].title : ""}
        description={
          selectedAddOnType ? MODAL_CONFIGS[selectedAddOnType].description : ""
        }
        confirmButtonText={
          selectedAddOnType
            ? MODAL_CONFIGS[selectedAddOnType].confirmButtonText
            : "Confirm"
        }
        cancelButtonText="Cancel"
        confirmButtonColor="#ef4444"
        cancelButtonColor="#6b7280"
        count={
          selectedAddOnType === ADD_ON_TYPES.USERS_COUNT
            ? findAddOn(ADD_ON_TYPES.USERS_COUNT)?.current_quantity
            : 0
        }
        date={subscription?.current_period_end}
      />
      <SubscriptionCancelationFlow
        open={subscriptionCancelModal}
        setOpen={setSubscriptionCancelModal}
      />
      <SubAccountModal
        open={openSubAccountModal}
        setOpen={setOpenSubAccountModal}
      />
    </div>
  );
};

export default SettingBilling;
