import { orderOverviewTour } from "./orderOverviewTour";
import { dashboardTour } from "./dashboardTour";
import { orderDetailTour } from "./orderDetailTour";
import { messagesTour } from "./messagesTour";
import { skipTour } from "./skipTour";
import { posTour } from "./posTour";
import { newOrderTour } from "./newOrderTour";
import { disclaimerTour } from "./disclaimerTour";

export const tours = {
    dashboard: dashboardTour,
    orderOverview: orderOverviewTour,
    orderDetail: orderDetailTour,
    messages: messagesTour,
    pos: posTour, 
    newOrder: newOrderTour,
    skip: skipTour,
    disclaimer: disclaimerTour
};
