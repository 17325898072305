import React, { useState, useEffect } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "../components/Chart/chartSetup";
import DefaultLineChart from "../components/Chart/LineChart";
import DonutChart from "../components/Chart/Donut";
import EntityPage from "../components/EntityPage";
import StatsCard from "../components/Card/StatsCard";
import UpcomingDeadlines from "../components/UpcomingDeadlines";
import { MessageInboxIcon } from "../common/icons";

import {
  ThreeBoxIcon,
  UptrendGraphIcon,
  UptrendGreenIcon,
} from "../common/icons";
import {
  MONTHS_IN_YEAR,
  TOUR_OPTION_STATUS_ENUM,
  TOUR_OPTION_TYPE,
  USER_ROLES,
} from "../constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { getRequest } from "../api";
import { formatDateToLongNumeric, humanizeNumber, sumBy } from "../utils";
import { getOrdersForMonth } from "../utils/dashboard";
import { setLoading } from "../redux/loadingSlice";
import { addToast } from "../redux/toastSlice";
import { ORDERS_OVERVIEW } from "../constants/FrontendRoutes";
import { STORES } from "../constants/BackendRoutes";
import { selectCurrentStore } from "../redux/storeSlice";
import { TourModal } from "../components/Modal/TourModal";
import { selectTour } from "../redux/tourSlice";

function DashboardPage() {
  const now = new Date();
  const currentMonthIndex = now.getUTCMonth();
  const currentMonth = now.getUTCMonth() + 1;
  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const currentYear = now.getUTCFullYear();

  const [orders, setOrders] = useState([]);
  const [month, setMonth] = useState(currentMonthIndex);
  const [unquoteOrders, setUnquoteOrders] = useState([]);
  const [messagesCount, setMessagesCount] = useState(0);
  const [isTourModalOpen, setIsTourModalOpen] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const role = currentStore?.role;
  const isAdminOrManager = role === USER_ROLES.ADMIN || role === USER_ROLES.MANAGER;
  const currentTour = useSelector(
    selectTour(TOUR_OPTION_TYPE.DASHBOARD_PAGE)
  );

  const fetchOrders = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getRequest(
        `stores/${currentStore?.store_id}/orders`,
        {},
        "user&fields=order.user.name,order.status,order.net_total,order.estimated_completion,order.placement_type,order.id,order.line_item_summary,order.created_at"
      );

      response.length > 0 ? setOrders(response) : setOrders([]);
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchUnreadMessages = async () => {
    try {
      const count = await getRequest(
        `${STORES}/${currentStore?.store_id}/orders/unread_messages`
      );
      setMessagesCount(count);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const totalOrderStatics = () => {
    const currentMonthOrders = getOrdersForMonth(
      orders,
      currentMonth,
      currentYear
    );
    const previousMonthOrders = getOrdersForMonth(
      orders,
      previousMonth,
      currentMonth == 1 ? 12 : currentYear
    );

    const orderDifference =
      currentMonthOrders.length - previousMonthOrders.length;
    const orderChangePercent =
      orderDifference === 0
        ? "No change"
        : `${(
            (orderDifference / (previousMonthOrders.length || 1)) *
            100
          ).toFixed(2)}%`;

    return `${
      orderDifference > 0 ? "+" : ""
    }${orderDifference} (${orderChangePercent})`;
  };

  const unquoteSalesStatics = () => {
    const currentMonthOrders = getOrdersForMonth(
      unquoteOrders,
      currentMonth,
      currentYear
    );
    const previousMonthOrders = getOrdersForMonth(
      unquoteOrders,
      previousMonth,
      currentMonth == 1 ? 12 : currentYear
    );

    const currentMonthTotal = currentMonthOrders.reduce(
      (sum, order) => sum + order.net_total,
      0
    );
    const previousMonthTotal = previousMonthOrders.reduce(
      (sum, order) => sum + order.net_total,
      0
    );

    const salesDifference = currentMonthTotal - previousMonthTotal;
    const salesChangePercent =
      (salesDifference / (previousMonthTotal || 1)) * 100;

    return `${salesDifference > 0 ? "+" : ""}${humanizeNumber(
      salesDifference
    )} (${humanizeNumber(salesChangePercent)}%)`;
  };

  const upcomingIncompleteOrders = (orders, daysCount) => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);

    const cutoffDate = new Date(
      today.getTime() + daysCount * 24 * 60 * 60 * 1000
    );
    cutoffDate.setUTCHours(23, 59, 59, 999);

    return orders.filter((order) => {
      const isComplete =
        order.status === "delivered" || order.status === "repaired";
      const estimatedDate = new Date(order.estimated_completion);
      estimatedDate.setUTCHours(0, 0, 0, 0);

      return (
        estimatedDate >= today && estimatedDate <= cutoffDate && !isComplete
      );
    });
  };

  const parseUpcomingOrders = (orders, numberOfDays) => {
    const upcomingOrders = upcomingIncompleteOrders(orders, numberOfDays);
    return upcomingOrders.map((order) => {
      return {
        orderId: order?.id.toString(),
        customer: order?.user?.name,
        items: order?.line_item_summary,
        estCompletionDate: formatDateToLongNumeric(order?.estimated_completion),
        status: order?.status,
      };
    });
  };

  useEffect(() => {
    if (currentTour?.status === TOUR_OPTION_STATUS_ENUM.incomplete)
      setIsTourModalOpen(true);
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      const unquoteOrders = orders?.filter(
        (order) => order?.status !== "quote"
      );
      setUnquoteOrders(unquoteOrders);
    } else {
      setUnquoteOrders([]);
    }
  }, [orders]);

  useEffect(() => {
    if (currentStore?.store_id && isAdminOrManager) {
      fetchOrders();
      fetchUnreadMessages();
    }
  }, [currentStore?.store_id]);

  return (
    <EntityPage
      title={`👋 Welcome ${userData?.last_sign_in_at && "back"}, ${
        userData?.name
      }`}
      description={`Here are today's stats for ${currentStore?.store?.name}`}
      isInfoButtonEnabled={
        currentTour?.status !== TOUR_OPTION_STATUS_ENUM.incomplete
      }
      infoButtonClass="skipTourStep1"
      infoButtonFunction={() => setIsTourModalOpen(true)}
    >
      <div className="flex flex-col xl:flex-row gap-4">
        <div className="flex flex-col gap-6 xl:w-[65%]">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 dashboardStep6">
            <StatsCard
              bgColor="#EBF6EF"
              titleIcon={ThreeBoxIcon}
              title="Total Orders"
              total={orders?.length}
              descriptionIcon={UptrendGreenIcon}
              statistics={totalOrderStatics()}
              description="vs. last month"
              navigateTo={ORDERS_OVERVIEW}
            />

            <StatsCard
              bgColor="#F8F1EE"
              titleIcon={UptrendGraphIcon}
              title="Total Sales"
              total={`$${sumBy(unquoteOrders, "net_total")}`}
              descriptionIcon={UptrendGreenIcon}
              statistics={unquoteSalesStatics()}
              description="vs. last month"
            />

            <StatsCard
              bgColor="#F3F0E8"
              titleIcon={MessageInboxIcon}
              title="Unread messages"
              total={`${messagesCount} messages`}
              description="Last updated: 21min ago"
            />
          </div>
          <div className="flex flex-col gap-8 p-8 shadow-lg rounded-2xl dashboardStep7">
            <div className="flex flex-col justify-between sm:flex-row gap-5 md:items-center">
              <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                Sales Performance
              </p>
              <FormControl sx={{ minWidth: "170px" }} size="small">
                <Select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250, // Set the max height of the dropdown
                      },
                    },
                  }}
                >
                  {MONTHS_IN_YEAR.map((monthName, index) => (
                    <MenuItem key={index} value={index}>
                      {monthName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <DefaultLineChart
              orders={unquoteOrders}
              monthIndex={month}
              year={currentYear}
            />
          </div>
        </div>
        <div className="flex flex-col gap-6 xl:w-[35%]">
          <DonutChart
            title="Active orders"
            orders={orders}
            navigateTo={ORDERS_OVERVIEW}
            className="dashboardStep8"
          />

          <UpcomingDeadlines
            orders={parseUpcomingOrders(unquoteOrders, 7)}
            className="dashboardStep9"
          />
        </div>
      </div>
      <TourModal open={isTourModalOpen} close={setIsTourModalOpen} />
    </EntityPage>
  );
}

export default DashboardPage;
