import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TYPES, ORDER_NOTIFICATIONS } from "../../constants";
import { selectCurrentUser } from "../../redux/authSlice";
import {
  markNotificationAsRead,
  selectCurrentStore,
} from "../../redux/storeSlice";
import { markNotificationAsReadApi } from "../../utils/axios/notifications";
import OrderAssignmentNotificationItem from "./Items/Assignment";
import MessageNotificationItem from "./Items/Message";
import NewOrderNotificationItem from "./Items/NewOrder";
import OrderUpdateNotificationItem from "./Items/OrderUpdate";

const NotificationList = ({ notifications, isFullPage, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentStore = useSelector(selectCurrentStore);
  const userData = useSelector(selectCurrentUser);

  const storeId = currentStore?.store_id;
  const userId = userData?.id;

  const handleNotificationClick = (notification) => {
    if (notification.type === NOTIFICATION_TYPES.NEW_MESSAGE) {
      navigate(`/conversations/${notification.conversationId}`);
    } else if (ORDER_NOTIFICATIONS.includes(notification.type)) {
      navigate(`/orders/details/${notification.orderId}`);
    }
    handleMarkAsRead(notification);
  };

  const handleMarkAsRead = async (notification) => {
    try {
      if (!notification.read) {
        const isSuccess = await markNotificationAsReadApi(
          storeId,
          userId,
          notification.id
        );
        if (isSuccess) {
          dispatch(markNotificationAsRead(notification.id));
        } else {
          console.error("Failed to mark notification as read");
        }
      }
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <div className="space-y-4" {...props}>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`flex items-center p-4 border rounded-lg shadow-sm cursor-pointer bg-white`}
          onClick={() => handleNotificationClick(notification)}>
          {notification.type === NOTIFICATION_TYPES.ORDER_CREATE && (
            <NewOrderNotificationItem
              notification={notification}
              handleNotificationClick={() =>
                handleNotificationClick(notification)
              }
              isFullPage={isFullPage}
            />
          )}
          {notification.type === NOTIFICATION_TYPES.ORDER_UPDATE && (
            <OrderUpdateNotificationItem
              notification={notification}
              handleNotificationClick={() =>
                handleNotificationClick(notification)
              }
              isFullPage={isFullPage}
            />
          )}
          {notification.type === NOTIFICATION_TYPES.ORDER_ASSIGNEE && (
            <OrderAssignmentNotificationItem
              notification={notification}
              handleNotificationClick={() =>
                handleNotificationClick(notification)
              }
              isFullPage={isFullPage}
            />
          )}
          {notification.type === NOTIFICATION_TYPES.NEW_MESSAGE && (
            <MessageNotificationItem
              notification={notification}
              handleNotificationClick={() =>
                handleNotificationClick(notification)
              }
              isFullPage={isFullPage}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default NotificationList;
