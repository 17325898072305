import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, Divider, IconButton } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicTabs from "../common/components/Tabs";
import { NOTIFICATION_LIST_TABS } from "../constants";
import { NOTIFICATIONS } from "../constants/FrontendRoutes";
import { selectCurrentUser } from "../redux/authSlice";
import { fetchReadNotifications } from "../redux/storeAction";
import {
  selectCurrentStore,
  selectNotificationsHistory,
  selectUnReadNotifications,
} from "../redux/storeSlice";
import {
  filterNotifications,
  processNotification,
  splitNotificationsInBar,
} from "../utils/notifications";
import CustomPagination from "./CustomPagination";
import NotificationCount from "./NotificationCount";
import NotificationList from "./Notifications";
const NotificationBar = ({
  isFullPage = false,
  openUnread = true,
  openHistory = true,
  setIsVisible
}) => {
  const [activeTab, setActiveTab] = useState("all");
  const [isUnread, setIsUnread] = useState(openUnread);
  const [isHistory, setIsHistory] = useState(openHistory);

  const [notifications, setNotifications] = useState([]);
  const [notificationsHistory, setNotificationsHistory] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const readNotificationsData = useSelector(selectNotificationsHistory);
  const unreadNotificationsData = useSelector(selectUnReadNotifications);

  const userId = userData.id;
  const storeId = currentStore?.store_id;

  useEffect(() => {
    setIsUnread(openUnread);
    setIsHistory(openHistory);
  }, [openUnread, openHistory]);

  useEffect(() => {
    const setNotificationsData = () => {
      if (!isEmpty(readNotificationsData?.data)) {
        const processedNotifications =
          readNotificationsData.data.map(processNotification);
        setNotificationsHistory(processedNotifications);
        setPaginationMeta(readNotificationsData?.meta?.pagination);
      }
    };
    setNotificationsData();
  }, [readNotificationsData]);

  useEffect(() => {
    const setNotificationsData = () => {
      if (!isEmpty(unreadNotificationsData?.data)) {
        const processedNotifications =
          unreadNotificationsData?.data?.map(processNotification);
        setNotifications(processedNotifications);
      }
    };
    setNotificationsData();
  }, [unreadNotificationsData]);

  useEffect(() => {
    const loadUnreadNotifications = async () => {
      try {
        const notifications = dispatch(
          fetchReadNotifications({
            storeId,
            userId,
            currentPage,
            perPage,
            activeTab,
          })
        );
        setNotificationsHistory(notifications?.processedNotifications);
        setPaginationMeta(notifications?.pagination);
      } catch (error) {
        console.error("Failed to load notifications", error);
      }
    };

    loadUnreadNotifications();
  }, [currentPage, perPage, activeTab]);

  const handleTabChange = (newValue) => {
    const newTab = NOTIFICATION_LIST_TABS[newValue];
    setActiveTab(newTab.value);
  };

  const filteredUnreadNotifications = useMemo(() => {
    return filterNotifications(notifications, activeTab);
  }, [activeTab, notifications]);

  const tabsLabels = useMemo(() => {
    return NOTIFICATION_LIST_TABS.map((tab) => {
      if (tab.value === "all") {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <span>{tab.label}</span>
            {isFullPage && <NotificationCount count={notifications?.length} />}
          </div>
        );
      } else {
        return tab.label;
      }
    });
  }, [notifications]);

  const activeTabIndex = useMemo(() => {
    return NOTIFICATION_LIST_TABS.findIndex((tab) => tab.value === activeTab);
  }, [activeTab]);

  const { unreadToShow, readToShow } = splitNotificationsInBar(filteredUnreadNotifications, notificationsHistory);

  let unreadNotificationStyles = {};
  if (isFullPage) {
    unreadNotificationStyles = {
      maxHeight: "500px",
      overflowY: "auto",
    };
  }

  return (
    <div
      className={`${
        isFullPage
          ? "py-5"
          : "flex flex-col fixed w-[500px] bg-white p-6 z-10 h-full sm:right-0"
      }`}
    >
      <div className="flex flex-col overflow-auto h-[78%]">
        <div className="flex">
          {!isFullPage && (
            <>
              <span className="font-extrabold text-lg">Notifications</span>
              {
                <span className="mt-1">
                  <NotificationCount count={notifications?.length} />
                </span>
              }
            </>
          )}
        </div>
        <BasicTabs
          list={tabsLabels}
          setValue={handleTabChange}
          value={activeTabIndex}
          variant="standard"
        />
        <div>
          <Box display="flex" alignItems="center" mb={1}>
            {isFullPage && (
              <IconButton onClick={() => setIsUnread(!isUnread)}>
                {isUnread ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            <div className="font-['Questrial'] my-4">
              {isFullPage ? "New Notifications" : "Unread Notifications"}
            </div>
            <Divider
              sx={{ flexGrow: 1, bgcolor: "#e0e0e0", height: "1px", ml: 2 }}
            />
          </Box>
          <Collapse in={isUnread}>
            <div style={unreadNotificationStyles}>
              {isEmpty(filteredUnreadNotifications) ? (
                <div className="text-center text-gray-500 my-3">
                  You don’t have any notifications for the past 30 days.
                </div>
              ) : (
                <NotificationList onClick={() => setIsVisible?.(false)} notifications={isFullPage ? filteredUnreadNotifications : unreadToShow} isFullPage={isFullPage} />
              )}
            </div>
          </Collapse>
        </div>
        <div>
          <Box display="flex" alignItems="center" mb={1} mt={4}>
            {isFullPage && (
              <IconButton onClick={() => setIsHistory(!isHistory)}>
                {isHistory ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            <div className="font-['Questrial'] my-4">Notifications History</div>
            <Divider
              sx={{ flexGrow: 1, bgcolor: "#e0e0e0", height: "1px", ml: 2 }}
            />
          </Box>
          <Collapse in={isHistory}>
            {isEmpty(notificationsHistory) ? (
              <div className="text-center text-gray-500 my-3">
                You don’t have any notifications for the past 30 days.
              </div>
            ) : (
              <NotificationList onClick={() => setIsVisible?.(false)} notifications={isFullPage ? notificationsHistory : readToShow} isFullPage={isFullPage} />
            )}
          </Collapse>
        </div>
      </div>
      {!isFullPage && (
        <div
          onClick={() => navigate(NOTIFICATIONS)}
          className="h-[10%] cursor-pointer flex items-center justify-center font-questrial text-base font-normal leading-[22.4px] underline p-4 bg-white"
        >
          View all
          <ArrowForwardIosIcon
            sx={{ color: "#272523", fontSize: "12px", marginLeft: "10px" }}
          />
        </div>
      )}
      {isFullPage && (
        <div>
          <CustomPagination
            paginationMeta={paginationMeta}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationBar;
