import {
  MESSAGE_NOTIFICATIONS,
  NOTIFICATION_TAB_VALUES,
  NOTIFICATION_TYPES,
  ORDER_NOTIFICATIONS,
} from "../constants";
import { OrderStatus } from "../constants/OrderStatus";
import { formatTimeDifference } from "./index";

export const processNotification = (notification) => {
  const {
    id,
    read,
    notification_type: type,
    created_at,
    details,
  } = notification;
  const {
    days = null,
    order_status = null,
    order_id: orderId = null,
    is_overdue: isOverdue = null,
    customer_name: customerName = null,
    conversation_id: conversationId = null,
    message_content: messageContent = null,
    order_estimated_completion_date: estimatedCompletionDate = null,
  } = details || {};

  const createdAt = formatTimeDifference(created_at);
  const commonProps = {
    id,
    read,
    type,
    orderId,
    createdAt,
    customerName,
  };
  const orderProps = {
    ...commonProps,
    estimatedCompletionDate,
  };

  switch (type) {
    case NOTIFICATION_TYPES.NEW_MESSAGE:
      return {
        ...commonProps,
        messageContent,
        conversationId,
      };
    case NOTIFICATION_TYPES.ORDER_CREATE:
      return orderProps;
    case NOTIFICATION_TYPES.ORDER_UPDATE:
      return {
        ...orderProps,
        days,
        isOverdue,
        delayed: order_status === OrderStatus.DELAYED,
      };
    case NOTIFICATION_TYPES.ORDER_ASSIGNEE:
      return orderProps;
    default:
      console.warn(`Unsupported notification type: ${type}`);
      return null;
  }
};

export const filterNotifications = (notifications, activeTab) => {
  if (!notifications) return [];

  return notifications.filter((notification) => {
    switch (activeTab) {
      case NOTIFICATION_TAB_VALUES.ALL:
        return true;
      case NOTIFICATION_TAB_VALUES.ORDERS:
        return ORDER_NOTIFICATIONS.includes(notification.type);
      case NOTIFICATION_TAB_VALUES.MESSAGES:
        return MESSAGE_NOTIFICATIONS.includes(notification.type);
      default:
        return false;
    }
  });
};

export const splitNotificationsInBar = (
  filteredUnreadNotifications,
  notificationsHistory
) => {
  const maxTotal = 10;
  const unreadCount = filteredUnreadNotifications?.length;
  const readCount = notificationsHistory?.length;

  let unreadToShow = [];
  let readToShow = [];

  if (unreadCount >= 10 && readCount > 0) {
    unreadToShow = filteredUnreadNotifications.slice(0, 9);
    readToShow = notificationsHistory?.slice(0, 1);
  } else {
    const unreadPossible = Math.min(unreadCount, maxTotal);
    unreadToShow = filteredUnreadNotifications?.slice(0, unreadPossible);

    const leftover = maxTotal - unreadPossible;
    if (leftover > 0) {
      readToShow = notificationsHistory?.slice(0, leftover);
    }
  }
  return { unreadToShow, readToShow };
};
