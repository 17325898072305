import { getRequest, updateRequest } from '../../api';
import { processNotification } from '../notifications';

export const fetchNotifications = async (storeId, userId, currentPage = 1, perPage = 10) => {
  const includes = "receiver,notificationable.conversation,notificationable.order_line_item.order";
  const fields = "message.content,conversation.id,order_assignee.id,order_line_item.id,order.id,order.estimated_completion,order.status";

  try {
    const endpoint = `/stores/${storeId}/users/${userId}/notifications`;
    const { data, meta: { pagination } } = await getRequest(endpoint, {
      page: currentPage,
      per_page: perPage,
    }, `${includes}&fields=${fields}`);

    const processedNotifications = data.map((notification) => processNotification(notification))
    return {
      processedNotifications,
      pagination
    };
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const markNotificationAsReadApi = async (storeId, userId, notificationId) => {
  try {
    const endpoint = `/stores/${storeId}/users/${userId}/notifications/${notificationId}`;
    const response = await updateRequest(endpoint, {
      read: true
    });
    return response?.data?.success;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};
