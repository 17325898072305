import React, { useEffect, useState } from "react";
import { groupedBy } from "../../../../utils";
import BasicTabs, { CustomTabPanel } from "../../../../common/components/Tabs";
import ItemCard from "./ItemCard";
import { isEmpty } from "lodash";

const ProductItem = ({
  replica,
  products,
  currentProduct,
  setCurrentProduct,
  searchedProduct,
  setSearchedProduct,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const groupedProducts = groupedBy(products, "category");
  const uniqueMainCategories = Object.keys(groupedProducts);

  const handleTabChange = (e) => {
    setCurrentProduct();
    setCurrentTab(e);
  };

  const isProductSelected = (product) => {
    return currentProduct && currentProduct.id === product.id;
  };

  useEffect(() => {
    if (
      replica &&
      !(replica?.services?.length > 0 || replica?.options?.length > 0)
    ) {
      setCurrentTab(uniqueMainCategories.indexOf(replica?.category));
      setCurrentProduct({
        id: replica?.id,
        name: replica?.name,
        price: replica?.price,
        category: replica?.category,
        uniqStr: replica?.uniqStr,
      });
    }
  }, [replica]);

  useEffect(() => {
    if (!isEmpty(searchedProduct)) {
      const productOption = products?.find(
        (product) =>
          product?.category === searchedProduct?.category &&
          product?.name === searchedProduct?.categoryType
      );
      setCurrentTab(uniqueMainCategories?.indexOf(searchedProduct?.category));
      setCurrentProduct({
        id: productOption?.id,
        name: productOption?.name,
        price: productOption?.price,
        category: productOption?.category,
      });
      setSearchedProduct({});
    }
  }, [searchedProduct]);

  return (
    <React.Fragment>
      <BasicTabs
        setValue={(e) => handleTabChange(e)}
        value={currentTab}
        list={uniqueMainCategories.map((ct) => ct)}
        disabled={replica && true}
      />
      {uniqueMainCategories.map((category, index) => (
        <CustomTabPanel value={currentTab} index={index} key={index}>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 my-5">
            {groupedProducts[category].map((product, scIndex) => (
              <ItemCard
                data={product}
                isSelected={isProductSelected(product)}
                onClick={() =>
                  setCurrentProduct({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    category: product.category,
                  })
                }
                key={scIndex}
              />
            ))}
          </div>
        </CustomTabPanel>
      ))}
    </React.Fragment>
  );
};

export default ProductItem;
