import React from "react";
import { Box } from "@mui/material";

export default function ({ title = "Suggestions", options, onClick }) {
  return (
    <Box
      className="w-full h-full"
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 1,
        py: 2,
        gap: 1,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      <p className="text-[#939291] text-sm font-normal font-['Questrial'] leading-none">
        {title}
      </p>
      <ul>
        {options.map((item, index) => (
          <li
            key={index}
            onClick={() => onClick(item)}
            className="cursor-pointer p-2 rounded-md hover:bg-[rgba(243,240,232,0.50)] font-['Questrial'] hover:text-[#4C8C4A]"
          >
            {item}
          </li>
        ))}
      </ul>
    </Box>
  );
}
