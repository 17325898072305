
import { createSlice } from '@reduxjs/toolkit';
import { logout } from './authActions';

const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    isNavbarOpen: false,
    openDropdown: null,
  },
  reducers: {
    setisNavbarOpen(state, action) {
      state.isNavbarOpen = action.payload
    },
    setOpenDropdown(state, action) {
      state.openDropdown = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state, _action) => {
        state.isNavbarOpen = false;
        state.openDropdown = null;
      });

  },
});

export const { setisNavbarOpen, setOpenDropdown } = navbarSlice.actions;
export const selectIsNavbarOpen = state => state.navbar.isNavbarOpen;
export const selectOpenDropdown = state => state.navbar.openDropdown;

export default navbarSlice.reducer;