import React from "react";
import EntityPage from "../components/EntityPage";
import NotificationBar from "../components/NotificationBar";
import { DASHBOARD } from "../constants/FrontendRoutes";
import { Box } from "@mui/material";

const Notification = () => {
  return (
    <EntityPage title="Notifications" breadcrumbs={{ href: DASHBOARD }}>
      <Box>
        <NotificationBar
          isFullPage={true}
          openUnread={true}
          openHistory={true}
        />
      </Box>
    </EntityPage>
  );
};

export default Notification;
