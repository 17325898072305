import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";

import InputFileUpload from "../../Button/FileUpload";
import CustomTable from "../../Table";
import ActionMenu from "../../Button/ActionMenu";

import { Plus } from "../../../common/icons";
import { POS, POS_CREATE_SERVICE } from "../../../constants/FrontendRoutes";
import { getRequest, deleteRequest } from "../../../api";
import { COMMON_STATUS } from "../../../constants";
import { addToast } from "../../../redux/toastSlice";
import { maskUSD } from "../../../utils";
import { selectAllCategories, selectAllServiceType, selectCurrentStore } from "../../../redux/storeSlice";

const columns = [
  {
    field: "identifier",
    label: "ID",
    collapsible: false,
    width: "100px",
  },
  {
    field: "name",
    label: "Service Type",
    collapsible: true,
    searchable: true,
    width: "200px",
  },
  {
    field: "category",
    label: "Category",
    collapsible: false,
    sortable: true,
    width: "200px",
  },
  {
    field: "categoryType",
    label: "Category Type",
    collapsible: false,
    sortable: true,
    width: "200px",
  },
  {
    field: "status",
    label: "Status",
    collapsible: false,
    sortable: true,
    width: "100px",
  },
  {
    field: "price",
    label: "Price",
    searchable: true,
    collapsible: false,
    width: "100px",
  },
  {
    field: "actions",
    label: "",
    collapsible: false,
    width: "10px",
    component: ActionMenu,
  },
];

const transformData = (
  allServiceTypes,
  services,
  handleEdit = () => {},
  handleRemove = () => {},
  handleServiceTypeEdit = () => {},
) => {
  const result = [];
  const recordMap = {};

  services.forEach((item) => {
    const key = `${item.sub_category.category}|${item.sub_category.name}|${item.service_type}`;

    if (!recordMap[key]) {
      recordMap[key] = {
        id: allServiceTypes?.find((service) => service?.name === item.service_type)?.id,
        name: item.service_type,
        category: item.sub_category.category,
        categoryType: item.sub_category.name,
        status: COMMON_STATUS[item.status],
        price: "",
        actionsProps: {
          listOfActions: [{ label: "Edit", handler: handleServiceTypeEdit }],
        },
        collapse: [],
      };
      result.push(recordMap[key]);
    }

    recordMap[key].collapse.push({
      id: item.id,
      identifier: item.id,
      name: item.name,
      status: COMMON_STATUS[item.status],
      price: maskUSD(item.price),
      actionsProps: {
        listOfActions: [
          { label: "Edit", handler: handleEdit },
          { label: "Remove", handler: handleRemove },
        ],
      },
    });
  });

  return result;
};

function ActionItems({ onCSV }) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {/* <InputFileUpload onChange={onCSV} /> */}
      <Button
        variant="containedPrimary"
        onClick={() => navigate(POS_CREATE_SERVICE)}
        startIcon={<Plus />}
      >
        Add Service
      </Button>
    </React.Fragment>
  );
}

const Service = ({ onCSVUpload }) => {
  const [rows, setRows] = useState([]);

  const currentStore = useSelector(selectCurrentStore);
  const allServiceTypes = useSelector(selectAllServiceType);
  const allCategories = useSelector(selectAllCategories);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentStore && allServiceTypes && allCategories) {
      handleGetServices()
        .then((services) => {
          setRows(
            transformData(
              allServiceTypes,
              services,
              handleEdit,
              handleRemove,
              handleServiceTypeEdit
            )
          );
        })
        .catch((error) => {
          dispatch(addToast(error.message));
        });
    }
  }, [currentStore, allServiceTypes, allCategories]);

  const handleGetServices = async () => {
    return await getRequest(
      `stores/${currentStore?.store_id}/services`,
      {},
      "sub_category"
    );
  };

  const handleRemove = async (id) => {
    try {
      await deleteRequest(
        `stores/${currentStore?.store_id}/services/${id}`
      );
      setRows((prevRows) =>
        prevRows
          .map((row) => {
            if (row.id === id) {
              return null;
            }

            const updatedCollapse = row.collapse.filter((subRow) => subRow.id !== id);

            if (updatedCollapse.length !== row.collapse.length) {
              return {
                ...row,
                collapse: updatedCollapse,
              };
            }
            return row;
          })
          .filter(Boolean)
      );
      dispatch(addToast("Service removed successfully."));
    } catch (error) {
      dispatch(addToast(error.message));
    }
  };

  const handleEdit = (id) => {
    navigate(`${POS}/edit/service/${id}`);
  };

  const handleServiceTypeEdit = (id) => {
    navigate(`${POS}/edit/service-type/${id}`);
  };

  return (
    <div className="flex flex-col justify-between">
      <CustomTable
        columns={columns}
        rows={rows}
        sortable
        searchable
        searchType="Collapsed"
        actions={<ActionItems onCSV={onCSVUpload} />}
      />
    </div>
  );
};

export default Service;
