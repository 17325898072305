import React, { useEffect } from "react";
import OrderDetail from "./OrderDetail";

const UpcomingDeadlines = ({ orders, className }) => {

  return (
    <div className={`flex flex-col gap-5 p-8 shadow-lg rounded-2xl h-[450px] overflow-auto scroll-m-0 ${className}`}>
      <div className="text-neutral-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Upcoming deadlines
      </div>
      <div className="rounded border border-stone-300 flex flex-col">
        {orders.map((order, index) => (
          <React.Fragment key={order.orderId}>
            <OrderDetail {...order} />
            {index < orders.length - 1 && <hr className="border-stone-300" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default UpcomingDeadlines;
