import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, IconButton } from "@mui/material";

export const BuildPosModal = ({ open, close }) => {
  const handleClose = () => {
    close(false);
  };
  const Chevron = <span className="text-black">{">"}</span>;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="flex flex-col px-6 pt-8 pb-4 gap-4">
        <p className="text-xl font-semibold font-[Montserrat]">
          🏗 We are building your POS
        </p>
        <div className="flex flex-col font-[Questrial] text-sm gap-4">
          <p>
            Before it’s ready, here’s a quick tip of how the POS is structured:
          </p>
          <p>
            Your POS will contains
            <span className="text-[#4C8C4A]">
              {" "}
              category types {Chevron} categories {Chevron} service types{" "}
              {Chevron} services {Chevron} options{" "}
            </span>
            . Services can be attributed to multiple category types if the price
            is the same.
          </p>
          <p className="text-sm">
            Example:
            <br />
            To create a women's boots dainite resole and clean & shine, you
            would select:
            <br />
            <span className="text-[#4C8C4A] text-xs">
              Women's Shoes {Chevron} Women's Boots {Chevron} Upper Repair
              Service {Chevron} Clean & Shine + Sole Repair Service {Chevron}{" "}
              Full Resole {Chevron} Dainite Rubber Soles
            </span>
          </p>

          <p>
            The Clean & Shine and Full Resole services could be services
            attributed to all women's shoe category types.
          </p>

          <p>
            You don't have to select options. Instead you can create a more
            descriptive service (e.g. Dainite Full Resole).
          </p>

          <p>
            And don’t worry if you have a different structure, all the
            components mentioned above are fully customizable!
            <br />
            Our team can also help you build your POS too. Just reach out via{" "}
            <a
              href="mailto:info@getcoblr.com"
              className="text-[#4C8C4A] underline"
            >
              Support
            </a>
            .
          </p>
        </div>
        <div className="flex justify-end">
          <Button
            onClick={handleClose}
            sx={{
              px: 6,
              backgroundColor: "#4C8C4A",
              color: "white",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "green",
              },
            }}
          >
            Got it
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
