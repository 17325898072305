import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";
import { tours } from ".";
import { TOUR_OPTION_STATUS, TOUR_OPTION_STATUS_ENUM } from "../constants";
import { updateTourStatus } from "../redux/tourAction";

export const orderOverviewTour = ({
  currentTour,
  dispatch,
  isDesktopScreen = true,
}) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      showCancelLink: true,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 5,
      scrollTo: true,
      scrollToHandler: (element) => {
        const topOffset = 100; // Space above the element (for better visibility)
        const scrollableContainer =
          document.querySelector(".scrollable-container") || window;

        // Determine container dimensions
        const containerRect =
          scrollableContainer === window
            ? {
                top: 0,
                bottom: window.innerHeight,
                left: 0,
                right: window.innerWidth,
                scrollTop: window.scrollY,
                scrollLeft: window.scrollX,
              }
            : scrollableContainer?.getBoundingClientRect();

        const elementRect = element?.getBoundingClientRect();

        // Check if the element is fully visible
        const isFullyVisibleVertically =
          elementRect.top >= containerRect.top &&
          elementRect.bottom <= containerRect.bottom;

        const isFullyVisibleHorizontally =
          elementRect.left >= containerRect.left &&
          elementRect.right <= containerRect.right;

        // Calculate scrolling only if necessary
        if (!isFullyVisibleVertically || !isFullyVisibleHorizontally) {
          const scrollTop =
            scrollableContainer === window
              ? window.scrollY
              : scrollableContainer.scrollTop;

          const scrollLeft =
            scrollableContainer === window
              ? window.scrollX
              : scrollableContainer.scrollLeft;

          // Scroll only to bring the element into view
          const newTop =
            scrollTop + elementRect.top - containerRect.top - topOffset;
          const newLeft = scrollLeft + elementRect.left - containerRect.left;

          // Scroll the container or window
          if (scrollableContainer === window) {
            window.scrollTo({
              top: newTop,
              left: newLeft,
              behavior: "smooth",
            });
          } else {
            scrollableContainer.scrollTo({
              top: newTop,
              left: newLeft,
              behavior: "smooth",
            });
          }
        }
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
  });

  const onComplete = () => {
    if(currentTour?.status !== TOUR_OPTION_STATUS_ENUM.complete)
      dispatch(updateTourStatus({ id: currentTour?.id, status: TOUR_OPTION_STATUS.COMPLETE }));
  };

  const onSkip = () => {
    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.skip)
      dispatch(updateTourStatus({ id: currentTour?.id, status: TOUR_OPTION_STATUS.SKIP }));

    const skip = tours.skip({ isDesktopScreen });
    skip.start();
  };

  tour.on("complete", onComplete);
  tour.on("cancel", onSkip);

  const steps = [
    {
      id: "Step 2",
      text: ` <div>
                <p class="font-bold text-xl">Status Updates</p>
                <p class="text-sm text-gray-500 pt-2">
                  Drag and drop order tiles to update status or scan the order label QR code to automatically update statuses. Status updates automatically send customers update communications.
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep1",
        on: "top",
        classes: ".rounded-container",
      },
      arrow: false,
      extraHighlights: [".orderOverviewStep2-s1", ".orderOverviewStep2-s2"],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
    },
    {
      id: "Step 3",
      text: ` <div>
                <p class="font-bold text-xl">Quote</p>
                <p class="text-sm text-gray-500 pt-2">
                  All unpaid repair quotes from the POS are here. Once paid, they automatically move to “New”.
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep2",
        on: "bottom",
        classes: ".rounded-container",
      },
      scrollTo: true,
      classes: "mr-96",
    },
    {
      id: "Step 4",
      text: ` <div>
                <p class="font-bold text-xl">New Orders</p>
                <p class="text-sm text-gray-500 pt-2">
                  All orders that have not been started or prepared for shipping. 
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep3",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 5",
      text: ` <div>
                <p class="font-bold text-xl">Shipping</p>
                <p class="text-sm text-gray-500 pt-2">
                  All orders that require shipping prior to starting the repair. 
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep4",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 6",
      text: ` <div>
                <p class="font-bold text-xl">In Progress</p>
                <p class="text-sm text-gray-500 pt-2">
                  Orders that the team is actively working on or is ready for repair. 
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep5",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 7",
      text: ` <div>
                <p class="font-bold text-xl">Delayed</p>
                <p class="text-sm text-gray-500 pt-2">
                  Any order that has delays / will be completed after the estimated completion date.
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep6",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 8",
      text: ` <div>
                <p class="font-bold text-xl">Repaired</p>
                <p class="text-sm text-gray-500 pt-2">
                  Any order that is ready for pickup or prepared to ship back to the customer.
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep7",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 9",
      text: ` <div>
                <p class="font-bold text-xl">Delivered</p>
                <p class="text-sm text-gray-500 pt-2">
                  All orders that are fully completed and back with the customer.
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep8",
        on: "bottom",
        classes: ".rounded-container",
      },
    },
    {
      id: "Step 10",
      text: ` <div>
                <p class="font-bold text-xl">Order Details</p>
                <p class="text-sm text-gray-500 pt-2">
                  View all order details here, upload before and after images or print a shipping label. 
                </p>
              </div>`,
      attachTo: {
        element: ".orderOverviewStep9-0",
        on: isDesktopScreen ? "right" : "bottom",
        classes: ".rounded-container",
      },
      classes: "mr-96",
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: `${index + 1}/${steps.length}`,
          action: null,
          classes: "step_counter",
          disabled: true,
        },
        {
          text: "Skip for now",
          action: tour.cancel,
          classes: "secondary_btn",
        },
        {
          text: index === steps.length - 1 ? "Finish" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
