import Shepherd from "shepherd.js";
import { flip, offset, shift } from "@floating-ui/dom";
import { TOUR_OPTION_STATUS, TOUR_OPTION_STATUS_ENUM } from "../constants";
import { updateTourStatus } from "../redux/tourAction";
import { CONVERSATIONS } from "../constants/FrontendRoutes";

export const messagesTour = ({
  currentTour,
  dispatch,
  navigate,
  isDesktopScreen = true,
}) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      showCancelLink: true,
      modalOverlayOpeningRadius: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: isDesktopScreen ? 15 : 0, crossAxis: 0 })],
      },
    },
  });

  const onComplete = () => {
    if(currentTour?.status !== TOUR_OPTION_STATUS_ENUM.complete)
      dispatch(updateTourStatus({ id: currentTour?.id, status: TOUR_OPTION_STATUS.COMPLETE }));

    navigate(CONVERSATIONS);
  };

  tour.on("complete", onComplete);

  const steps = [
    {
      id: "Step 1",
      text: ` <div>
                  <p class="font-bold text-base md:text-xl">Messages Page</p>
                  <p class="text-sm md:text-sm text-gray-500 pt-2">
                    View all of your customer communications in one place. Email/SMS about an order or even send update photos. 
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen ? ".messageStep1" : ".messageMobileStep1",
        on: isDesktopScreen ? "left" : "top",
      },
      extraHighlights: isDesktopScreen ? [".messageExtraStep1"] : ["messageExtraStep2"],
      beforeShowPromise: () =>
        new Promise((resolve) => {
          setTimeout(resolve, 100);
        }),
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: `${index + 1}/${steps.length}`,
          action: null,
          classes: "step_counter",
          disabled: true,
        },
        {
          text: index === steps.length - 1 ? "Got it" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
