import React from "react";

const NotificationCount = ({ count }) => {
  return count ? (
    <span className="w-5 h-5 text-[12px] flex items-center justify-center rounded-full ml-2 p-2 bg-[#D84141] text-white">
      {count}
    </span>
  ) : (
    <></>
  );
};

export default NotificationCount;
