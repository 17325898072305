import React, { useEffect, useState } from "react";
import { FormControlLabel, TextField, Checkbox } from "@mui/material";
import InputMask from "react-input-mask";
import AddressFields from "./AddressFields";
import { selectCurrentStore } from "../../redux/storeSlice";
import { useSelector } from "react-redux";
import { syncShopToShippingAddresses } from "../../utils/addresses";
import { USER_ROLES } from "../../constants";

const defaultAddress = {
  line: "",
  zipcode: "",
  city: "",
  state: {},
  country: {},
};

const StoreDetail = ({ id, setStoreDetailChanges }) => {
  const currentStore = useSelector(selectCurrentStore);
  const isAdmin = currentStore?.role === USER_ROLES.ADMIN;

  const [businessDetail, setBusinessDetail] = useState({
    name: "",
    email: "",
    phone_number: "",
    addresses: [],
    separateShippingAddress: false,
  });

  const { addresses, name, email, phone_number, separateShippingAddress } =
    businessDetail;

  useEffect(() => {
    if (currentStore) {
      const { name, email, phone_number, addresses } = currentStore?.store;

        const shopAddress = addresses?.find(
          (address) => address.address_type === "shop"
        ) || { ...defaultAddress, address_type: "shop" };

        const shippingAddress = addresses?.find(
          (address) => address.address_type === "shipping"
        ) || { ...defaultAddress, address_type: "shipping" };


        const areAddressesEqual =
          JSON.stringify({
            ...shopAddress,
            address_type: undefined,
            id: undefined,
            created_at: undefined,
            updated_at: undefined,
          }) ===
          JSON.stringify({
            ...shippingAddress,
            address_type: undefined,
            id: undefined,
            created_at: undefined,
            updated_at: undefined,
          });

        setBusinessDetail({
          name: name,
          email: email,
          phone_number: phone_number,
          addresses: [shopAddress, shippingAddress],
          separateShippingAddress: !areAddressesEqual,
        });
      }
  }, [currentStore]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const store = currentStore?.store || {};

    setBusinessDetail((prevDetail) => {
      if (value !== store.name) {
        setStoreDetailChanges((prevChanges) => ({
          ...prevChanges,
          [name]: value,
        }));
      } else {
        setStoreDetailChanges((prevChanges) => {
          const updatedChanges = { ...prevChanges };
          delete updatedChanges[name];
          return updatedChanges;
        });
      }
      return { ...prevDetail, [name]: value };
    });
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setBusinessDetail((prevDetail) => {
      let returnBusinessDetails = {
        ...prevDetail,
        separateShippingAddress: checked,
      };

      if (!checked) {
        const updatedAddresses = syncShopToShippingAddresses(prevDetail.addresses);
        setStoreDetailChanges((prevDetail) => ({
          ...prevDetail,
          addresses: updatedAddresses,
        }));
        returnBusinessDetails.addresses = updatedAddresses;
      }
      return returnBusinessDetails;
    });
  };

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Store Details
      </div>
      <div className="flex flex-col lg:flex-row gap-16 justify-between">
        <div className="flex flex-col gap-5 w-full">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Company name
              </div>
              <TextField
                id="companyName"
                placeholder="Please enter"
                variant="outlined"
                name="name"
                value={name}
                onChange={handleInputChange}
                disabled={!isAdmin}
              />
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Contact email
              </div>
              <TextField
                id="contactEmail"
                placeholder="Please enter"
                variant="outlined"
                name="email"
                value={email}
                onChange={handleInputChange}
                disabled={!isAdmin}
              />
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </div>
              <InputMask
                mask="(999) 999-9999"
                value={phone_number}
                onChange={handleInputChange}
                disabled={!isAdmin}
              >
                {() => (
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    variant="outlined"
                    disabled={!isAdmin}
                  />
                )}
              </InputMask>
            </div>
          </div>
          <AddressFields
            prefix="Shop"
            setBusinessDetail={setBusinessDetail}
            setBusinessDetailChanges={setStoreDetailChanges}
            address={
              addresses?.find((address) => address.address_type === "shop") ||
              defaultAddress
            }
            disabled={!isAdmin}
          />

          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                onChange={handleCheckboxChange}
                checked={separateShippingAddress}
                disabled={!isAdmin}
              />
            }
            label="Use different address for shipping"
          />

          {separateShippingAddress && (
            <AddressFields
              prefix="Shipping"
              setBusinessDetail={setBusinessDetail}
              setBusinessDetailChanges={setStoreDetailChanges}
              address={
                addresses?.find(
                  (address) => address.address_type === "shipping"
                ) || defaultAddress
              }
              disabled={!isAdmin}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;
