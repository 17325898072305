import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import toastReducer from "./toastSlice";
import loadingReducer from "./loadingSlice";
import cartReducer from "./cartSlice";
import storeReducer from "./storeSlice";
import currentStepReducer from "./currentStepSlice";
import messageReducer from './messageSlice';
import organizationReducer from './organizationSlice';
import navbarReducer from "./navbarSlice";
import tourReducer from "./tourSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    loading: loadingReducer,
    cart: cartReducer,
    store: storeReducer,
    currentStep: currentStepReducer,
    message: messageReducer,
    organization: organizationReducer,
    navbar: navbarReducer,
    tour: tourReducer
  },
});
