import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateRequest } from "../api";

export const updateTourStatus = createAsyncThunk(
  "tour/updateTourStatus",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const tour_options = await updateRequest(`tour_options/${id}`, {
        tour_option: { status: status },
      });

      return tour_options?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
