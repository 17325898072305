import { createSlice } from '@reduxjs/toolkit';
import { login, logout, fetchUserData } from './authActions';
import { setDataToLocalStorage } from "../utils/localStorage";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        const { stores_as_staff, tour_options ,...user } = action.payload;
        state.user = user;
        setDataToLocalStorage(action.payload.token, action.payload.id, stores_as_staff?.[0]?.store_id);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(logout.pending, (state, _action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, _action) => {
        state.user = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, _action) => {
        state.user = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = null;
      })

      .addCase(fetchUserData.pending, (state, _action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        const { stores_as_staff, tour_options, ...user } = action.payload;
        state.user = user;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchUserData.rejected, (state, _action) => {
        state.user = null;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { setCurrentUser, setIsAuthenticated } = authSlice.actions;
export const selectCurrentUser = state => state?.auth?.user;

export default authSlice.reducer;
