import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { LetterIcon } from "../../common/icons";
import { postRequest, updateRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import { useMediaQuery } from "react-responsive";
import InputMask from "react-input-mask";
import { ADDRESSES, STORES, USERS } from "../../constants/BackendRoutes";
import { getLatestAddress } from "../../utils";
import { CONVERSATION_DETAIL_WITH_ORDER } from "../../constants/FrontendRoutes";

const OrderDetailsFooter = ({ user, order, setShowGreenTick, fetchOrder }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const isMdUp = useMediaQuery({ query: "(max-width: 767px)" });

  const [notes, setNotes] = useState({
    customerNotes: order.customer_notes || "",
    internalNotes: order.internal_notes || "",
  });

  const [phoneNumber, setPhoneNumber] = useState(user.phone_number || "-");
  const [newEmail, setNewEmail] = useState(user.email || "-");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [editPhone, setEditPhone] = useState(false);

  const [address, setAddress] = useState(
    getLatestAddress(user?.addresses)?.human_readable || "-"
  );
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(true);

  const addressRegex =
    /^(?<line>.+?), ?(?<city>.+?), ?(?<state>[A-Za-z]{2}) ?(?<zipcode>\d{5}), ?(?<country>[A-Za-z\s]{2,})$/;

  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  const resetFields = () => {
    setPhoneNumber(user.phone_number);
    setAddress(getLatestAddress(user?.addresses)?.human_readable);
    setNotes({
      customerNotes: order.customer_notes,
      internalNotes: order.internal_notes,
    });
    setEditPhone(false);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewEmail(email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  useEffect(() => {
    setIsValid(phoneRegex.test(phoneNumber));
  }, [phoneNumber]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setAddress(value);

    if (!addressRegex.test(value)) {
      setError(
        "Invalid address format. Please use: <street address>, <city>, <state>, <zip code>, <country>"
      );
    } else {
      setError("");
    }
  };

  const isButtonDisabled = () =>
    notes.customerNotes === order.customer_notes &&
    notes.internalNotes === order.internal_notes &&
    (phoneNumber === user.phone_number || !isValid) &&
    (address === getLatestAddress(user?.addresses)?.human_readable || error) &&
    newEmail === user.email;

  const updateNotes = async () => {
    try {
      const promises = [];

      if (phoneNumber !== user.phone_number || newEmail !== user.email) {
        promises.push(
          updateRequest(
            `stores/${currentStore?.store_id}/users/${user.id}`,
            {
              user: {
                email: newEmail,
                phone_number: phoneNumber,
              },
            }
          )
        );
      }

      if (address !== getLatestAddress(user?.addresses)?.human_readable) {
        const match = address.match(addressRegex);
        const { line, city, state, zipcode, country } = match.groups;

        if (
          user?.addresses?.length &&
          getLatestAddress(user?.addresses).address_type === "shipping"
        ) {
          promises.push(
            updateRequest(
              `${ADDRESSES}/${getLatestAddress(user?.addresses)?.id}`,
              {
                address: {
                  zipcode: zipcode,
                  state: state,
                  country: country,
                  city: city,
                  line: line,
                },
              }
            )
          );
        } else {
          promises.push(
            postRequest(
              `${USERS}/${user.id}${ADDRESSES}`,
              {
                address: {
                  zipcode: zipcode,
                  state: state,
                  country: country,
                  city: city,
                  line: line,
                  address_type: "shipping",
                },
              }
            )
          );
        }
      }

      if (
        notes.customerNotes !== order.customer_notes ||
        notes.internalNotes !== order.internal_notes
      ) {
        promises.push(
          updateRequest(
            `stores/${currentStore?.store_id}/orders/${order.id}`,
            {
              customer_notes: notes.customerNotes,
              internal_notes: notes.internalNotes,
            }
          )
        );
      }

      const responses = await Promise.all(promises);

      const allSuccess = responses.every((obj) => obj.status === 200);
      if (allSuccess) {
        dispatch(addToast("Order is updated"));
        setShowGreenTick(true);
        fetchOrder();
        setTimeout(() => {
          setShowGreenTick(false);
        }, 3000);
      }
    } catch (error) {
      dispatch(
        addToast(error || "Something went wrong\nOrder is not updated!")
      );
    }
  };

  const customerAddress = (customer) => {
    if (!customer?.addresses?.length) return "-";
    const { zipcode, state, line } = getLatestAddress(customer?.addresses);
    return `${line}, ${state}, ${zipcode}`;
  };

  useEffect(() => {
    resetFields();
  }, [user, order]);

  return (
    <Box className="max-w-[1550px] w-full flex flex-col gap-4">
      <Box className="flex md:flex-row flex-col gap-7 justify-center">
        <Card
          sx={{
            boxShadow: "none",
            "@media (min-width: 768px)": {
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)", // Shadow for md screens and above
            },
          }}
          className="md:w-1/3"
        >
          <CardContent
            sx={{
              padding: 0,
              "@media (min-width: 768px)": {
                padding: "16px",
              },
            }}
            className="flex flex-col gap-4"
          >
            <Typography style={theme.typography.title}>
              Customer detail
            </Typography>
            <Box className="flex flex-col gap-1">
              <Typography
                component="h2"
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Customer #{user.id}
              </Typography>
              <Typography
                className="md:block hidden"
                style={theme.typography.title}
              >
                {user.name}
              </Typography>
              <Typography className="md:hidden">{user.name}</Typography>
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Email:
              </Typography>
              {editPhone ? (
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  placeholder="john.doe@example.com"
                  variant="outlined"
                  value={newEmail}
                  onChange={handleEmailChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? "Invalid email format" : ""}
                />
              ) : (
                <Typography
                  className="cursor-pointer"
                  onClick={() => setEditPhone(true)}
                  style={{
                    ...theme.typography.body,
                    color: theme.palette.text.primary,
                  }}
                >
                  {newEmail}
                </Typography>
              )}
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Phone number:
              </Typography>
              {editPhone ? (
                <InputMask
                  mask="(999) 999-9999"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                >
                  {() => (
                    <TextField
                      id="phone_number"
                      name="phone_number"
                      type="tel"
                      placeholder="(123) 456-7890"
                      variant="outlined"
                      error={!isValid}
                      helperText={!isValid ? "Invalid phone number format" : ""}
                    />
                  )}
                </InputMask>
              ) : (
                <Typography
                  className="cursor-pointer"
                  onClick={() => setEditPhone(true)}
                  style={{
                    ...theme.typography.body,
                    color: theme.palette.text.primary,
                  }}
                >
                  {phoneNumber}
                </Typography>
              )}
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Shipping address:
              </Typography>
              {editPhone ? (
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  placeholder="123 Main St, New York, NY, 12345, US"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={address}
                  onChange={handleInputChange}
                  error={!!error}
                  helperText={
                    error ||
                    "Format: <Street address>, <city>, <state>, <zip code>, <country>"
                  }
                />
              ) : (
                <Typography
                  className="cursor-pointer"
                  onClick={() => setEditPhone(true)}
                  style={{
                    ...theme.typography.body,
                    color: theme.palette.text.primary,
                  }}
                >
                  {customerAddress(user)}
                </Typography>
              )}
            </Box>
            <Box className="hidden md:block">
              <Button
                variant="contained"
                className="orderDetailStep6"
                sx={{
                  bgcolor: "white",
                  color: "#272523",
                  fontSize: "16px",
                  "&:hover": {
                    bgcolor: "white",
                  },
                  fullWidth: true,
                }}
                startIcon={<LetterIcon />}
              >
                <Link to={CONVERSATION_DETAIL_WITH_ORDER.replace(":id", order?.conversation_id).replace(":orderNumber", order?.id)}>Message</Link>
              </Button>
            </Box>
            <Box className="md:hidden">
              <Button
                className="orderDetailMobileStep6"
                variant="outlined"
                color="secondary"
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  fullWidth: true,
                }}
                startIcon={<LetterIcon />}
              >
                <Link to={CONVERSATION_DETAIL_WITH_ORDER.replace(":id", order?.conversation_id).replace(":orderNumber", order?.id)}>Message</Link>
              </Button>
            </Box>
          </CardContent>
        </Card>

        <Card
          sx={{
            boxShadow: "none",
            "@media (min-width: 768px)": {
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)", // Shadow for md screens and above
            },
          }}
          className="md:w-2/3"
        >
          <CardContent
            sx={{
              padding: 0,
              "@media (min-width: 768px)": {
                padding: "16px",
              },
            }}
          >
            <Box className="flex flex-col gap-2">
              <Typography style={theme.typography.title}>
                Order Notes
              </Typography>
              <Box className="flex flex-col md:flex-row w-full gap-2">
                <Box className="flex flex-col gap-1 md:w-1/2">
                  <Typography style={theme.typography.body}>
                    Order Notes
                  </Typography>
                  <TextField
                    className="w-full"
                    id="outlined-multiline-static"
                    multiline
                    rows={isMdUp ? 1 : 8}
                    onChange={(e) =>
                      setNotes({ ...notes, customerNotes: e.target.value })
                    }
                    value={notes.customerNotes}
                    placeholder="Add notes here"
                  />
                </Box>
                <Box className="flex flex-col gap-1 md:w-1/2">
                  <Typography style={theme.typography.body}>
                    Internal Notes
                  </Typography>
                  <TextField
                    className="w-full"
                    id="outlined-multiline-static"
                    multiline
                    rows={isMdUp ? 1 : 8}
                    value={notes.internalNotes}
                    onChange={(e) =>
                      setNotes({ ...notes, internalNotes: e.target.value })
                    }
                    placeholder="Add notes here"
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className="flex gap-5 flex-grow min-w-full self-stretch justify-end">
        <Button
          className="md:flex-grow-0 flex-grow md:w-32"
          variant="outlined"
          color="secondary"
          onClick={resetFields}
        >
          Cancel
        </Button>
        <Button
          className="md:flex-grow-0 flex-grow md:w-32"
          variant="contained"
          color="success"
          disabled={isButtonDisabled()}
          onClick={updateNotes}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default OrderDetailsFooter;
