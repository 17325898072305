export const ROOT = `/`;
export const LOGIN = `${ROOT}login`;
export const SIGNUP = `${ROOT}signup`;
export const ONBOARDING = `${ROOT}onboarding`;
export const DASHBOARD = `${ROOT}dashboard`;
export const ORDERS = `${ROOT}orders`;
export const NOTIFICATIONS =`${ROOT}notifications`;
export const ORDERS_OVERVIEW = `${ORDERS}/overview`;
export const ORDERS_ASSIGNED=`${ORDERS}/assigned`;
export const ORDERS_DETAILS = `${ORDERS}/details/:id`;
export const MESSAGES = `${ROOT}messages`;

export const CONVERSATIONS = `${ROOT}conversations`;
export const NEW_CONVERSATION = `${CONVERSATIONS}/new`;
export const CONVERSATION_DETAIL = `${CONVERSATIONS}/:id`;
export const CONVERSATION_DETAIL_WITH_ORDER = `${CONVERSATION_DETAIL}/:orderNumber`;

export const POS = `${ROOT}pos`;
export const POS_SERVICES_AND_PRODUCTS = `${POS}/services-and-products`;
export const POS_CREATE_SERVICE = `${POS}/new/service`;
export const POS_CREATE_PRODUCT = `${POS}/new/product`;
export const POS_CREATE_OPTION = `${POS}/new/option`;
export const POS_CREATE_CATEGORY_TYPE = `${POS}/new/category-type`;
export const POS_EDIT_SERVICE = `${POS}/edit/service/:id`;
export const POS_EDIT_SERVICE_TYPE = `${POS}/edit/service-type/:id`;
export const POS_EDIT_PRODUCT = `${POS}/edit/product/:id`;
export const POS_EDIT_OPTION = `${POS}/edit/option/:id`;
export const POS_EDIT_CATEGORY = `${POS}/edit/category/:id`;
export const POS_EDIT_CATEGORY_TYPE = `${POS}/edit/category-type/:id`;
export const POS_CREATE_ORDER = `${POS}/new/order`;
export const POS_NEW_ORDER = `${POS}/new-order`;
export const POS_NEW_ORDER_CONFIRM_ORDER = `${POS_NEW_ORDER}/confirm-order`;
export const POS_NEW_ORDER_CONFIRM_QUOTE = `${POS_NEW_ORDER}/confirm-quote`;
export const CUSTOMERS = `${ROOT}customers`;
export const CORPORATIONS = `${ROOT}corporations`;
export const CUSTOMERS_NEW = `${CUSTOMERS}/new`;
export const CUSTOMERS_LIST = `${CUSTOMERS}/list`;
export const INDIVIDUAL_DETAILS = `${CUSTOMERS}/:id`;
export const CORPORATION_DETAILS = `${CORPORATIONS}/:id`;
export const CUSTOMERS_EMAIL_CAMPAIGNS = `${CUSTOMERS}/email-campaigns`;
export const CUSTOMERS_EMAIL_CAMPAIGNS_NEW = `${CUSTOMERS_EMAIL_CAMPAIGNS}/new`;
export const CORPORATE = `${ROOT}corporation`;
export const CORPORATE_NEW = `${CORPORATE}/new`;
export const SETTINGS = `${ROOT}settings`;
export const FORGET_PASSWORD = `${ROOT}forget-password`;
export const NEW_PASSWORD = `${ROOT}password-edit`;
export const PASSWORD_EMAIL_SENT = `${ROOT}password-sent`;
export const SCANNER = "scanner";
export const SCANNER_PAGE = `${ROOT}${SCANNER}/:token`;
export const DETAILS = "order-details";
export const DETAILS_PAGE = `${ROOT}${DETAILS}/:token`;
export const MY_ACCOUNT = `${ROOT}customer/my-account`;

export const CUSTOMER_ROUTES = {
  LOGIN: "/login/customer",
  ORDERS_OVERVIEW: "/customer/orders/overview",
  ORDERS_DETAILS: "/customer/orders/details/:id",
  INVOICES_OVERVIEW: "/customer/invoices/overview",
  MY_ACCOUNT: "/customer/my-account",
  MESSAGES: "/customer/conversations",
  MESSAGES_DETAILS: "/customer/conversations/:id",
};
