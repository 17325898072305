import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FreeCoupon } from "../Subscription/FreeCoupon";
import { CancellationOptions } from "../Subscription/CancellationOptions";
import { CancelConfirmation } from "../Subscription/CancelConfirmation";
import { useSelector } from "react-redux";
import { selectCurrentOrganization, selectSubscription } from "../../redux/organizationSlice";

export default function SubscriptionCancelationFlow({ open, setOpen }) {
  const [currentStep, setCurrentStep] = useState(0);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription)

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setCurrentStep(0);
    }, 1500);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        if (
          currentOrganization?.free_month_offer_accepted_at
        ) {
          setCurrentStep(1);
          return null;
        }
        return <FreeCoupon close={handleClose} setCurrentStep={setCurrentStep} />;
      case 1:
        return (
          <CancellationOptions
            close={handleClose}
            setCurrentStep={setCurrentStep}
          />
        );
      case 2:
        return (
          <CancelConfirmation
            close={handleClose}
            setCurrentStep={setCurrentStep}
          />
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "16px",
          right: 10,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {renderStepContent()}
    </Dialog>
  );
}
