import React, { useState, useEffect } from "react";
import { Button, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import ConfirmationModal from "./ConfirmationModal";
import { postRequest, getRequest, deleteRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";

const SubAccountModal = ({ open, setOpen }) => {
  const [subAccountDetails, setSubAccountDetails] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const currentStore = useSelector(selectCurrentStore);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      fetchSubAccountDetails();
    }
  }, [open]);

  const fetchSubAccountDetails = async () => {
    try {
      const response = await getRequest(
        `/stores/${currentStore?.id}/sub_account`
      );
      if (response?.exists === true) {
        setSubAccountDetails(response);
      } else {
        setSubAccountDetails(null);
      }
    } catch (error) {
      console.error("Error fetching subaccount details:", error);
    }
  };

  const handlePhoneNumber = (e) => {
    const valueWithoutNonDigits = e.target.value.replace(/[^\d+]/g, "");    
    const valueWithoutPlus = valueWithoutNonDigits.replace(/\+/g, "");
    
    let formattedValue = valueWithoutPlus;
    if (valueWithoutPlus.length > 0 && !valueWithoutPlus.startsWith('+')) {
      formattedValue = '+' + valueWithoutPlus;
    }
    
    setPhoneNumber(formattedValue);
  };

  const handleCreateSubAccount = async () => {
    try {
      dispatch(setLoading(true));

      const response = await postRequest(
        `/stores/${currentStore?.id}/handle_phone_number_setup`,
        {
          phone_number: phoneNumber,
        }
      );

      if (response.success) {
        dispatch(
          addToast(
            `Phone number ${response.phone_number} purchased successfully and linked to subaccount: ${response.sub_account_sid}`
          )
        );
      } else {
        throw new Error("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Error in processNumberAndCreateSubAccount:", error);

      dispatch(
        addToast(
          error ||
            "Failed to process and create subaccount. Please try again."
        )
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUpdateNumber = async () => {
    try {
      dispatch(setLoading(true));
      const updateNumber = await postRequest(
        `/stores/${currentStore?.id}/update_phone_number`,
        {
          phone_number: phoneNumber,
        }
      );

      if (updateNumber) {
        dispatch(addToast("Phone number updated successfully!"));
        await fetchSubAccountDetails();
        handleClose();
      } else {
        throw new Error("Unexpected response format.");
      }
    } catch (error) {
      dispatch(
        addToast(error || "Failed to update phone number. Please try again.")
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteSubAccount = async () => {
    try {
      dispatch(setLoading(true));
      await deleteRequest(`/stores/${currentStore?.id}/sub_account/`);
      dispatch(addToast("Subaccount deleted successfully!"));
      setSubAccountDetails(null);
    } catch (error) {
      dispatch(
        addToast(
          error.message || "Failed to delete sub account. Please try again."
        )
      );
    } finally {
      setDeleteModal(false);
      dispatch(setLoading(false));

    }
  };

  const handleClose = () => {
    setOpen(false);
    setPhoneNumber("");
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "20px",
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="p-8">
          {!subAccountDetails ? (
            <>
              <div className="flex flex-col gap-2">
                <p className="text-stone-800 text-lg font-semibold font-['Montserrat']">
                  Subaccount Creation
                </p>
                <p className="text-stone-800 text-base font-normal font-['Questrial']">
                  If you want to use your own number to send and receive sms,
                  please enter it below:
                </p>
              </div>

              <div className="flex items-center justify-between space-x-4 my-4">
                <div className="text-stone-800 text-base font-semibold font-['Questrial']">
                  Phone Number
                </div>
                <input
                  type="text"
                  className="border border-stone-200 rounded-lg p-2 flex-grow font-['Questrial']"
                  placeholder="123-456-7890"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                />
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCreateSubAccount}
                  disabled={!phoneNumber}
                >
                  Confirm
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-4">
                <div>
                  <p className="text-stone-800 text-lg font-semibold font-['Montserrat']">
                    SMS Notification Settings
                  </p>
                  <p className="text-stone-800 text-base font-normal font-['Questrial']">
                    Current Phone Number: {subAccountDetails?.phone_numbers}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-stone-800 text-base font-semibold">
                    Update Phone Number
                  </p>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      className="border border-stone-200 rounded-lg p-2 flex-1"
                      placeholder="New phone number"
                      value={phoneNumber}
                      onChange={handlePhoneNumber}
                      maxLength={12}
                    />
                    <Button
                      variant="contained"
                      onClick={handleUpdateNumber}
                      disabled={
                        !phoneNumber ||
                        phoneNumber === subAccountDetails?.phone_number
                      }
                    >
                      Update
                    </Button>
                  </div>
                </div>

                <div className="mt-4 pt-4 border-t">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setDeleteModal(true)}
                    disabled={!subAccountDetails}
                  >
                    Delete Sub Account
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Dialog>

      <ConfirmationModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        title="Are you sure you want to delete this Sub Account ?"
        onConfirm={handleDeleteSubAccount}
      />
    </>
  );
};

export default SubAccountModal;
