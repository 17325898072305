import CircleIcon from "@mui/icons-material/Circle";
import React from "react";
import { OrderStatus, STATUS_STYLES } from "../../../constants/OrderStatus";
import Capsule from "../../Capsule";

const OrderUpdateNotificationItem = ({
  notification,
  handleNotificationClick,
  isFullPage,
}) => {
  const {
    days,
    read,
    orderId,
    delayed,
    createdAt,
    isOverdue,
    customerName,
    estimatedCompletionDate,
  } = notification;

  const delayedStatusChip = () => {
    return (
      <Capsule
        capsuleColor={`bg-[${STATUS_STYLES[OrderStatus.DELAYED].bgColor}]`}
        textColor={`text-[${STATUS_STYLES[OrderStatus.DELAYED].textColor}]`}
        text={STATUS_STYLES[OrderStatus.DELAYED].text}
      />
    );
  };

  return (
    <div
      className="flex mb-4 w-full"
      onClick={() => handleNotificationClick(notification)}
    >
      <div className="flex flex-col gap-1 flex-1 font-['Questrial'] font-normal text-base">
        <div className="flex justify-between gap-1">
          <div className="flex gap-1">
            {!read && (
              <CircleIcon
                sx={{
                  marginTop: "7px",
                  width: "10px",
                  height: "10px",
                  color: "#D84141",
                }}
              />
            )}
            <span>You have an order {`${delayed ? "overdue" : "due soon"}`}</span>
          </div>
          <div className="font-['Questrial'] font-normal text-sm text-[#939291]">
            {createdAt}
          </div>
        </div>

        {isFullPage ? (
          <div className="flex flex-wrap gap-2 items-center text-sm">
            <div className="flex items-center border-r border-gray-300 pr-4">
              <span className="text-[#939291]">Order&nbsp;</span>
              <span className="text-[#272523] mr-2">#{orderId}</span>
              {delayed && delayedStatusChip()}
            </div>
            <div className="flex items-center border-r border-gray-300 pr-4">
              <span className="text-[#939291]">
                Customer: <span className="text-[#272523]">{customerName}</span>
              </span>
            </div>
            <div className="flex items-center border-r border-gray-300 pr-4">
              <span className="text-[#939291]">
                Est. completed by:{" "}
                <span className={delayed ? "text-[#D84141]" : "text-[#272523]"}>
                  {estimatedCompletionDate}
                </span>{" "}
              </span>
            </div>
            <span className="text-[#D84141]">{`${isOverdue ? `${days} days past due` : `Due in ${days} days`}`}</span>
          </div>
        ) : (
          <div className="font-['Questrial'] font-normal text-sm">
            <span className="text-[#939291]">Order&nbsp;</span>
            <span className="text-[#272523]">#{orderId}&nbsp;</span>
            <span className="text-[#939291]">
              for <span className="text-[#272523]">{customerName}&nbsp;</span>
            </span>
            {isOverdue && <span className="text-[#939291]">
              was due&nbsp;
            </span>}
            <span className="text-[#D84141]">{`${isOverdue ? `${days} days ago` : `due in ${days} days`}`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderUpdateNotificationItem;
