import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon } from '../../common/icons';
import theme from '../../theme/theme';

function DropdownButton({button, menuItems, onClick, disabled, className}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="w-full">
      <Box className="flex flex-row w-full" gap="1px">
        <Button
          variant="contained"
          sx={{
            borderRadius: "8px 0px 0px 8px",
            width: "100%"
          }}
          onClick={() => onClick(button.returnedValue)}
          disabled={disabled}
        >
          {button.text}
        </Button>
        <Button
          className={className}
          variant="contained"
          sx={{
            borderRadius: "0px 8px 8px 0px",
            p: 1,
            minWidth: 0,
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={disabled}
        >
          {open ? (
            <ArrowUpIcon
              fill={!disabled && theme.palette.text.white}
            />
          ) : (
            <ArrowDownIcon
              fill={!disabled && theme.palette.text.white}
            />
          )}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item?.disabled || false}
            onClick={() => {
              onClick(item.returnedValue)
              handleClose()
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default DropdownButton;
