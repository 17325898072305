import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useSelector } from "react-redux";
import { Plus } from "../common/icons";
import { updateRequest } from "../api";
import { OrderStatus } from "../constants/OrderStatus";
import { filterAssignees } from "../utils/assignees";
import { getOrdersInfo, fetchStoreUsers } from "../api/assignees";
import MenuButton from "../components/Button/Menu";
import { ORDERS_DETAILS, POS_CREATE_ORDER } from "../constants/FrontendRoutes";
import MoveOrderModal from "../components/OrderOverview/MoveOrderModal";
import TrackNumberModal from "../components/OrderOverview/TrackNumberModal";
import LabelModal from "../components/Order/LabelModal";
import { ORDERS, STORES } from "../constants/BackendRoutes";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { OrderOverviewTable } from "../components/OrderOverview/OrderOverviewTable";
import { OrderOverviewHeader } from "../components/OrderOverview/OrderOverviewHeader";
import SearchInput from "../components/Form/Field/SearchInput";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import stringComparison from "string-comparison";
import TagIcon from "@mui/icons-material/Tag";
import {
  TOUR_OPTION_STATUS_ENUM,
  TOUR_OPTION_TYPE,
  USER_ROLES,
} from "../constants";
import { setLoading } from "../redux/loadingSlice";
import { selectCurrentStore } from "../redux/storeSlice";
import { tours } from "../tour";
import { useMediaQuery } from "react-responsive";
import EntityPage from "../components/EntityPage";
import { selectTour } from "../redux/tourSlice";

const SearchResult = ({ title = "Customers", data = [], query = "" }) => {
  const highlightMatches = (name, query) => {
    if (!query) return name;

    const nameLower = name.toLowerCase();
    const queryLower = query.toLowerCase();

    let longestMatchStart = -1;
    let longestMatchLength = 0;

    for (let i = 0; i < nameLower.length; i++) {
      let currentLength = 0;
      while (
        i + currentLength < nameLower.length &&
        currentLength < queryLower.length &&
        nameLower[i + currentLength] === queryLower[currentLength]
      ) {
        currentLength++;
      }

      if (currentLength > longestMatchLength) {
        longestMatchLength = currentLength;
        longestMatchStart = i;
      }
    }

    if (longestMatchLength > 0) {
      return (
        <>
          {name.slice(0, longestMatchStart)}

          <span className="font-extrabold text-[#4C8C4A]">
            {name.slice(
              longestMatchStart,
              longestMatchStart + longestMatchLength
            )}
          </span>

          {highlightRemainingMatches(
            name.slice(longestMatchStart + longestMatchLength),
            query.slice(longestMatchLength)
          )}
        </>
      );
    }

    return highlightRemainingMatches(name, query);
  };

  const highlightRemainingMatches = (name, query) => {
    if (!query) return name;

    const queryLower = query?.toLowerCase();
    let queryIndex = 0;

    return name?.split("").map((char, index) => {
      if (
        queryIndex < query.length &&
        char.toLowerCase() === queryLower[queryIndex]
      ) {
        queryIndex++;
        return (
          <span key={index} className="font-extrabold text-[#4C8C4A]">
            {char}
          </span>
        );
      }
      return <span key={index}>{char}</span>;
    });
  };

  return (
    <Box
      className="w-full h-full"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
        gap: 1,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            fontWeight: "fontWeightRegular",
            fontSize: "body2.fontSize",
            letterSpacing: "body2.letterSpacing",
            lineHeight: "body2.lineHeight",
          }}
        >
          {title}
        </Typography>
      </Box>

      {data.map(({ name, onClick }, index) => (
        <Box
          onClick={onClick}
          className="hover:bg-[rgba(243,240,232,0.50)] cursor-pointer"
          key={index}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: 2,
              font: "Montserrat",
            }}
          >
            {title === "Customers" ? (
              <GroupOutlinedIcon className="opacity-50" />
            ) : (
              <TagIcon className="opacity-50" />
            )}
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                fontFamily: "Montserrat",
              }}
            >
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                {highlightMatches(name, query)}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

function OrderOverview() {
  const currentStore = useSelector(selectCurrentStore);
  const dispatch = useDispatch();

  const [quoteOrders, setQuoteOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [delayed, setDelayed] = useState([]);
  const [repaired, setRepaired] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [isMoveOrderModalOpen, setIsMoveOrderModalOpen] = useState(false);
  const [trackNumberModal, setTrackNumberModal] = useState(false);
  const [focus, setFocus] = useState(false);
  const [destinationName, setDestinationName] = useState("");
  const [isLabelModalOpen, setIsLabelModalOpen] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [nameResults, setNameResults] = useState([]);
  const [idResults, setIdResults] = useState([]);
  const [dragOrder, setDragOrder] = useState();
  const [fetchedUserData, setFetchedUserData] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [movedCard, setMovedCard] = useState({
    startStatus: "",
    endStatus: "",
    source: {},
    destination: {},
  });

  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const currentTour = useSelector(
    selectTour(TOUR_OPTION_TYPE.ORDER_OVERVIEW_PAGE)
  );
  const tour = tours.orderOverview({
    currentTour,
    dispatch,
    isDesktopScreen,
  });

  const dateBeforeFiveDays = new Date();
  dateBeforeFiveDays.setDate(dateBeforeFiveDays.getDate() - 5);

  const divRef = useRef(null);

  const navigate = useNavigate();

  const {
    NEW_ORDER,
    SHIPPING,
    IN_PROGRESS,
    DELAYED,
    REPAIRED,
    DELIVERED,
    QUOTE,
  } = OrderStatus;

  const fetchRepairOrders = async () => {
    try {
      dispatch(setLoading(true));
      if (currentStore) {
        const includes = "user,order_assignees.user,shipping_labels";
        const fields =
          "shipping_label.id,shipping_label.tracking_number,shipping_label.shipping_leg";
        const data = await getOrdersInfo(
          includes,
          fields,
          currentStore?.store_id
        );
        setAllOrders(data);
      }
    } catch (error) {
      dispatch(addToast(error || "Something went Wrong!"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getOrdersByStatus = (allOrders, checkDeliveredDate = false) => {
    const sortedOrder = {
      quote: [],
      new_order: [],
      shipping: [],
      in_progress: [],
      delayed: [],
      repaired: [],
      delivered: [],
    };

    allOrders.forEach((order) => {
      switch (order.status) {
        case QUOTE:
          sortedOrder.quote.push(order);
          break;
        case NEW_ORDER:
          sortedOrder.new_order.push(order);
          break;
        case SHIPPING:
          sortedOrder.shipping.push(order);
          break;
        case IN_PROGRESS:
          sortedOrder.in_progress.push(order);
          break;
        case DELAYED:
          sortedOrder.delayed.push(order);
          break;
        case REPAIRED:
          sortedOrder.repaired.push(order);
          break;
        case DELIVERED:
          if (checkDeliveredDate) {
            if (new Date(order.delivered_at) >= dateBeforeFiveDays) {
              sortedOrder.delivered.push(order);
            }
          } else {
            sortedOrder.delivered.push(order);
          }
          break;
      }
    });

    return sortedOrder;
  };

  useEffect(() => {
    const fetchData = async () => {
      const roles = [USER_ROLES.MANAGER, USER_ROLES.REPAIR_TEAM];
      const data = await fetchStoreUsers(currentStore?.store_id, roles);
      setFetchedUserData(data);
    };

    fetchData();
    if (currentTour?.status === TOUR_OPTION_STATUS_ENUM.incomplete)
      tour.start();
  }, []);

  useEffect(() => {
    if (searchValue === "") {
      let filtered = allOrders;

      if (selectedAssignee) {
        filtered = filterAssignees(filtered, selectedAssignee);
      }

      setIdResults([]);
      setNameResults([]);
      setQuoteOrders(allOrders?.filter((order) => order.status === QUOTE));
      setNewOrders(allOrders?.filter((order) => order.status === NEW_ORDER));
      setShipping(allOrders?.filter((order) => order.status === SHIPPING));
      setInProgress(allOrders?.filter((order) => order.status === IN_PROGRESS));
      setDelayed(allOrders?.filter((order) => order.status === DELAYED));
      setRepaired(allOrders?.filter((order) => order.status === REPAIRED));
      setDelivered(
        allOrders?.filter(
          (order) =>
            order.status === DELIVERED &&
            new Date(order.delivered_at) >= dateBeforeFiveDays
        )
      );
    } else {
      const lcs = stringComparison.lcs;
      const sortedMatches = lcs.sortMatch(searchValue, [
        ...new Set(allOrders.map((order) => order?.user?.name)),
      ]);

      const sortedIds = lcs.sortMatch(
        searchValue,
        allOrders.map((order) => order?.id?.toString())
      );

      const resultsId = sortedIds
        .reverse()
        .slice(0, 10)
        .filter((item) => item.rating >= 0.65)
        .map((item) => item.member);

      setIdResults(resultsId);

      const results = sortedMatches
        .reverse()
        .slice(0, 10)
        .filter((item) => item.rating >= 0.5)
        .map((item) => item.member);

      setNameResults(results);

      const exactResults = sortedMatches
        .slice(0, 10)
        .filter((item) => item.rating === 1)
        .map((item) => item.member);

      let filteredList = allOrders.filter(
        (item) =>
          exactResults.includes(item?.user?.name) ||
          resultsId.includes(item?.id?.toString())
      );

      if (selectedAssignee) {
        filteredList = filterAssignees(filteredList, selectedAssignee);
      }

      const sortedData = getOrdersByStatus(filteredList, false);
      setQuoteOrders(sortedData.quote);
      setNewOrders(sortedData.new_order);
      setShipping(sortedData.shipping);
      setInProgress(sortedData.in_progress);
      setDelayed(sortedData.delayed);
      setRepaired(sortedData.repaired);
      setDelivered(sortedData.delivered);
    }
  }, [allOrders, searchValue, selectedAssignee]);

  const orderLists = [
    { orders: newOrders, type: NEW_ORDER },
    { orders: shipping, type: SHIPPING },
    { orders: inProgress, type: IN_PROGRESS },
    { orders: delayed, type: DELAYED },
    { orders: repaired, type: REPAIRED },
    { orders: delivered, type: DELIVERED },
    { orders: quoteOrders, type: QUOTE },
  ];

  const sortOrdersByDate = (orders, dateField, orderCategory) => {
    let sortedOrders = [...orders];
    sortedOrders.sort((a, b) => {
      if (a[dateField] === null) return 1;
      if (b[dateField] === null) return -1;
      return new Date(a[dateField]) - new Date(b[dateField]);
    });
    setList(orderCategory, sortedOrders);
  };

  const sortAllOrdersByDate = (dateField) => {
    orderLists.forEach((orderList) =>
      sortOrdersByDate(orderList.orders, dateField, orderList.type)
    );
  };

  const sortAllOrdersByPriority = () => {
    orderLists.forEach((orderList) =>
      sortOrdersByRushFee(orderList.orders, orderList.type)
    );
  };

  const sortOrdersByRushFee = (orders, orderCategory) => {
    let sortedOrders = [...orders];
    sortedOrders.sort((a, b) => {
      if (a.rush_fee > 0 && b.rush_fee <= 0) {
        return -1;
      } else if (a.rush_fee <= 0 && b.rush_fee > 0) {
        return 1;
      } else {
        return 0;
      }
    });
    setList(orderCategory, sortedOrders);
  };

  const handleSortingChange = (sortingCriteria) => {
    if (sortingCriteria === "Completion Date") {
      sortAllOrdersByDate("estimated_completion");
    } else if (sortingCriteria === "Order Date") {
      sortAllOrdersByDate("created_at");
    } else if (sortingCriteria === "Priority") {
      sortAllOrdersByPriority();
    }
  };

  const handleIsMoveOrderModal = () => {
    setDestinationName("");
    setIsMoveOrderModalOpen(false);
  };

  const checkCanCardMove = (card, destination) => {
    const notify = (message) => {
      dispatch(addToast(message));
      return false;
    };

    if (destination === "shipping") {
      const { shipment_type, shipping_labels } = card;

      if (!["two_leg", "three_leg"].includes(shipment_type)) {
        return notify(
          `Before moving to ${destination}, you need to create shipping labels`
        );
      }

      const shippingLegs = new Set(
        shipping_labels.map((label) => label.shipping_leg)
      );

      if (!shippingLegs.has("leg_2")) {
        return notify(
          `Before moving to ${destination}, Shipping leg "leg_2" must exist`
        );
      }
    }

    return true;
  };

  const onCancelDrag = () => {
    const result = move(
      getList(movedCard.endStatus),
      getList(movedCard.startStatus),
      movedCard.destination,
      movedCard.source
    );
    setList(movedCard.endStatus, result[movedCard.endStatus]);
    setList(movedCard.startStatus, result[movedCard.startStatus]);
    setIsMoveOrderModalOpen(false);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    const startStatus = source.droppableId;
    const endStatus = destination.droppableId;

    if (endStatus === "quote") return;

    const draggedOrder = getList(startStatus).find(
      (order) => order.id.toString() === draggableId
    );
    setDragOrder(draggedOrder);

    if (!checkCanCardMove(draggedOrder, endStatus)) return;

    if (startStatus === endStatus) {
      const items = reorder(
        getList(startStatus),
        source.index,
        destination.index
      );
      setList(startStatus, items);
    } else {
      setIsMoveOrderModalOpen(true);
      setDestinationName(endStatus);

      const result = move(
        getList(startStatus),
        getList(endStatus),
        source,
        destination
      );
      setMovedCard({
        startStatus,
        endStatus,
        source,
        destination,
      });
      setList(startStatus, result[startStatus]);
      setList(endStatus, result[endStatus]);
    }
  };

  const handleCardSave = async ({ endStatus, dragOrderId = 0, sendEmail }) => {
    const data = { order: { status: endStatus }, is_send_email: sendEmail };
    if (endStatus === "shipping") {
      data["order"]["shipping_date"] = new Date();
    } else if (endStatus === "in_progress") {
      data["order"]["in_progress_date"] = new Date();
    }
    try {
      await updateRequest(
        `${STORES}/${currentStore?.store_id}${ORDERS}/${dragOrderId}`,
        data
      );
    } catch (error) {
      onCancelDrag();
      setTrackNumberModal(false);
      dispatch(addToast(error));
    }
  };

  const getList = (status) => {
    switch (status) {
      case NEW_ORDER:
        return newOrders;
      case SHIPPING:
        return shipping;
      case IN_PROGRESS:
        return inProgress;
      case DELAYED:
        return delayed;
      case REPAIRED:
        return repaired;
      case DELIVERED:
        return delivered;
      default:
        return [];
    }
  };

  const setList = (status, items) => {
    switch (status) {
      case NEW_ORDER:
        setNewOrders(items);
        break;
      case SHIPPING:
        setShipping(items);
        break;
      case IN_PROGRESS:
        setInProgress(items);
        break;
      case DELAYED:
        setDelayed(items);
        break;
      case REPAIRED:
        setRepaired(items);
        break;
      case DELIVERED:
        setDelivered(items);
        break;
      case QUOTE:
        setQuoteOrders(items);
      default:
        break;
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleSortingAssignedOrders = (selectedAssignee) => {
    const selectedUser = fetchedUserData?.find(
      (user) => user.id === selectedAssignee.id
    );
    if (selectedUser) {
      setSelectedAssignee(selectedUser);
    } else {
      setSelectedAssignee(null);
    }
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const handleNotification = (value) => {
    handleCardSave({
      endStatus: movedCard.endStatus,
      dragOrderId: dragOrder.id,
      sendEmail: value,
    });
  };

  useEffect(() => {
    fetchRepairOrders();
  }, [currentStore]);

  const handleBlur = (e) => {
    if (divRef.current && divRef.current.contains(e.relatedTarget)) {
      return;
    }
    setFocus(false);
  };

  const renderAssigneeFilter = () => {
    return (
      <MenuButton
        optionKey="name"
        isObject={true}
        title="Assignee"
        variant="outlinedSecondary"
        options={[{ id: "all", name: "All" }, ...fetchedUserData]}
        onChange={(value) => handleSortingAssignedOrders(value)}
        mobileIcon={false}
        desktopIconShow={true}
        desktopIcon={<LocationOnOutlinedIcon />}
        mobileCustomIcon={<LocationOnOutlinedIcon />}
      />
    );
  };

  return (
    <EntityPage
      title="Order Overview"
      childrenLayout="p-0"
      isInfoButtonEnabled={
        currentTour?.status !== TOUR_OPTION_STATUS_ENUM.incomplete
      }
      infoButtonClass="skipTourStep1"
      infoButtonFunction={() => tour.start()}
    >
      <div className="bg-[#f8f8f8]">
        <div className="flex flex-col gap-5 max-w-[1440px] mx-auto">
          <Modal
            open={isMoveOrderModalOpen}
            onClose={() => handleIsMoveOrderModal()}
          >
            <MoveOrderModal
              handleNotification={(value) => handleNotification(value)}
              handleClose={() => handleIsMoveOrderModal()}
              openTrackNumberModal={() => setTrackNumberModal(true)}
              handleBack={onCancelDrag}
              order={dragOrder}
              destinationName={destinationName}
            />
          </Modal>
          <Modal
            open={trackNumberModal}
            onClose={() => setTrackNumberModal(false)}
          >
            <TrackNumberModal
              handleClose={() => setTrackNumberModal(false)}
              openLabelModal={() => setIsLabelModalOpen(true)}
              orderId={dragOrder?.id}
              fetchRepairOrders={fetchRepairOrders}
            />
          </Modal>
          <Modal
            open={isLabelModalOpen}
            onClose={() => setIsLabelModalOpen(false)}
          >
            <LabelModal
              handleClose={() => setIsLabelModalOpen(false)}
              customer={dragOrder?.user}
              isFinalShippingLeg={
                dragOrder?.shipping_labels?.length > 0 &&
                dragOrder?.shipping_labels.some(
                  (label) => label.shipping_leg == "leg_2"
                )
              }
              orderId={dragOrder?.id}
            />
          </Modal>
          <div className="flex flex-col gap-5">
            <Divider />
            <div className="px-5">
              <OrderOverviewHeader
                setSelectedStatus={setSelectedStatus}
                selectedStatus={selectedStatus}
                newOrders={newOrders}
                shipping={shipping}
                inProgress={inProgress}
                delayed={delayed}
                repaired={repaired}
                delivered={delivered}
              />
            </div>
            <div className="flex flex-col gap-3 py-4 rounded-lg bg-white">
              <div className="block sm:hidden w-[300px]">
                {renderAssigneeFilter()}
              </div>
              <div className="flex gap-3 flex-row w-full px-5">
                <MenuButton
                  title="Sort by:"
                  variant="outlinedSecondary"
                  options={["Order Date", "Priority", "Completion Date"]}
                  onChange={(value) => handleSortingChange(value)}
                  mobileIcon={true}
                />
                <div className="hidden sm:block">{renderAssigneeFilter()}</div>
                <div
                  onFocus={() => setFocus(true)}
                  ref={divRef}
                  tabIndex={-1}
                  onBlur={handleBlur}
                  className="relative ms-auto"
                >
                  <SearchInput
                    placeholder="Search by order ID, or customer name"
                    isOnClearEnabled={true}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    onClear={() => setSearchValue("")}
                    width="370px"
                  />
                  {focus && (
                    <div className="absolute z-[1] w-full max-h-60 overflow-auto shadow-md">
                      {nameResults.length > 0 && (
                        <SearchResult
                          data={nameResults.map((item) => ({
                            name: item,
                            onClick: () => setSearchValue(item),
                          }))}
                          query={searchValue}
                        />
                      )}
                      {idResults.length > 0 && (
                        <SearchResult
                          title="Order ID"
                          data={idResults.map((item) => ({
                            name: item,
                            onClick: () =>
                              navigate(ORDERS_DETAILS.replace(":id", item)),
                          }))}
                          query={searchValue}
                        />
                      )}
                    </div>
                  )}
                </div>
                <Button
                  className="text-nowrap h-[40px] flex-shrink-0"
                  variant="containedPrimary"
                  onClick={() => navigate(POS_CREATE_ORDER)}
                  startIcon={<Plus />}
                >
                  Add new order
                </Button>
              </div>
              <OrderOverviewTable
                onDragEnd={onDragEnd}
                selectedStatus={selectedStatus}
                quoteOrders={quoteOrders}
                newOrders={newOrders}
                shipping={shipping}
                inProgress={inProgress}
                delayed={delayed}
                repaired={repaired}
                delivered={delivered}
              />
            </div>
          </div>
        </div>
      </div>
    </EntityPage>
  );
}

export default OrderOverview;
