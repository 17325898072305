import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";
import { initialCartState, updateCart } from "../redux/cartSlice";
import { updateCurrentStep } from "../redux/currentStepSlice";
import { POS_NEW_ORDER_STEPS, TOUR_OPTION_STATUS, TOUR_OPTION_STATUS_ENUM } from "../constants";
import { tours } from ".";
import { updateTourStatus } from "../redux/tourAction";

export const newOrderTour = ({
  dispatch,
  currentTour,
  isDesktopScreen = true,
}) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      scrollTo: false,
      showCancelLink: true,
      modalOverlayOpeningRadius: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
  });

  const onComplete = () => {
    if(currentTour?.status !== TOUR_OPTION_STATUS_ENUM.complete)
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.COMPLETE,
        })
      );

    dispatch(updateCart({ ...initialCartState }));
  };

  const onSkip = () => {
    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.skip)
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.SKIP,
        })
      );
    dispatch(updateCart({ ...initialCartState }));

    const skip = tours.skip({ isDesktopScreen });
    skip.start();
  };

  tour.on("complete", onComplete);
  tour.on("cancel", onSkip);

  const steps = [
    {
      id: "Step 1",
      text: ` <div>
                  <p class="font-bold text-xl">Select your services to create a new order</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Create new orders by selecting the main category > category type > service type. You can also add customized services/products from this page.
                  </p>
                </div>`,
      attachTo: {
        element: ".newOrderStep1",
        on: "bottom",
      },
      classes: "mr-96",
    },
    {
      id: "Step 2",
      text: ` <div>
                  <p class="font-bold text-xl">Switch from service list to product list</p>
                  <p class="text-sm text-gray-500 pt-2">
                    If you’d like to add a product to the order, click here to switch to the product list. 
                  </p>
                </div>`,
      attachTo: {
        element: ".newOrderStep2",
        on: "bottom",
      },
      when: {
        hide: () => {
          if (!isDesktopScreen)
            dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART));
        },
      },
      classes: "mr-96",
    },
    {
      id: "Step 3",
      text: ` <div>
                  <p class="font-bold text-xl">More Options</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Add custom discounts and attach a customer to the order
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen ? ".newOrderStep3" : ".newOrderMobileStep3",
        on: isDesktopScreen ? "right" : "top",
      },
      floatingUIOptions: !isDesktopScreen
        ? {
            middleware: [offset({ mainAxis: 100, crossAxis: 0 })],
          }
        : undefined,
      beforeShowPromise: () =>
        new Promise((resolve) => {
          setTimeout(resolve, 100);
        }),
      extraHighlights: isDesktopScreen
        ? [".newOrderStep3-s1"]
        : ["newOrderStep3-s2"],
      modalOverlayOpeningPadding: 5,
      when: {
        show: () => {
          const button = document.querySelector(
            isDesktopScreen ? ".cart-tour" : ".cart-tour-2"
          );
          if (button) button?.click();
        },
        hide: () => {
          const wrapper = document.querySelector(".MuiBackdrop-root");
          if (wrapper) wrapper?.click();

          dispatch(
            updateCart({
              ...initialCartState,
              serviceLineItems: [
                {
                  category: "Women's Shoes",
                  categoryType: "Sneaker",
                  services: [
                    {
                      id: 21,
                      name: "SR-6",
                      type: "XYZ Repair",
                      price: 150,
                    },
                  ],
                  options: [],
                  uniqStr: "DmpuOc2mM9",
                  quantity: 1,
                  description: "",
                },
              ],
            })
          );
        },
      },
      classes: "mr-96",
    },
    {
      id: "Step 4",
      text: ` <div>
                  <p class="font-bold text-xl">Create a quote for a more flexible checkout</p>
                  <p class="text-sm text-gray-500 pt-2">
                    If your customer is requesting a quote, you can also click here, and we will send the customer a quote with a payment link via email.
                  </p>
                </div>`,
      attachTo: {
        element: ".newOrderStep4",
        on: isDesktopScreen ? "right" : "top",
      },
      when: {
        show: () => {
          const b = document.getElementById("basic-menu");
          if (b) b.click();
        },
        hide: () => {
          dispatch(updateCart({ ...initialCartState }));
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: `${index + 1}/${steps.length}`,
          action: null,
          classes: "step_counter",
          disabled: true,
        },
        {
          text: "Skip for now",
          action: tour.cancel,
          classes: "secondary_btn",
        },
        {
          text: index === steps.length - 1 ? "Finish" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
