import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTabs, { CustomTabPanel } from "../../common/components/Tabs";
import { ORDER_HISTORY_TAB_LIST } from "../../constants";
import { selectCurrentUser } from "../../redux/authSlice";
import OrderAccordion from "./OrderAccordion";
import { LocationIcon } from "../../common/icons";
import { CUSTOMER_ROUTES } from "../../constants/FrontendRoutes";
import MultiSelectDropdown from "../Form/MultiSelectDropdown";

const OrderHistory = ({ userData, currentStore, OrderDisplay = OrderAccordion }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUser);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [orders, setOrders] = useState(userData?.orders || []);

  const filterOrdersByStatus = (statuses) =>
    orders?.filter((order) => statuses.includes(order.status));

  const quoteOrders = filterOrdersByStatus(["quote"]);
  const pastOrders = filterOrdersByStatus(["repaired", "delivered"]);
  const activeOrders = filterOrdersByStatus([
    "new_order",
    "shipping",
    "in_progress",
    "delayed",
  ]);

  const initialTab = searchParams.get("tab") || ORDER_HISTORY_TAB_LIST[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleTabChange = (newValue) => {
    const newTab = ORDER_HISTORY_TAB_LIST[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  const renderOrders = (orders) =>
    orders?.length > 0 ? (
      <div className="flex flex-col gap-[24px]">
        {orders.map((order) => (
          <OrderDisplay key={order.id} order={order} />
        ))}
      </div>
    ) : (
      <p className="px-3 py-10 text-center text-[#837d77]">
        No order(s) to display
      </p>
    );

  useEffect(() => {
    if (currentUser) {
      const data = currentUser?.stores_as_customer?.map((storeUser) => {
        const { id, name } = storeUser?.store;
        return {
          id,
          label: name
        };
      });
      const sortedStores = data?.sort((a, b) => a.id - b.id) || [];
      setStores(sortedStores);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isShowLocationDropDown) {
      if (selectedStores.length === 0) {
        setOrders(userData?.orders);
      } else if (selectedStores?.length === stores?.length) {
        setOrders(userData?.orders);
      } else {
        const filteredOrders = (userData?.orders || []).filter((order) =>
          selectedStores.some((id) => id === order?.store?.id)
        );
        setOrders(filteredOrders);
      }
    } else {
      const filteredOrders = (userData?.orders || []).filter((order) =>
        order?.store_id === currentStore.id
      );
      setOrders(filteredOrders);
    }
  }, [userData, selectedStores]);

  const isShowLocationDropDown = location.pathname === CUSTOMER_ROUTES.ORDERS_OVERVIEW;

  return (
    <section className="bg-white px-5 lg:px-8 py-[24px] flex flex-col gap-[16px] rounded-[8px] w-full">
      <div className="flex flex-row items-center justify-between md:justify-start gap-[16px]">
        <div className="font-['Montserrat'] text-[24px] font-[700] text-[#272523]">
          Order history
        </div>
        {isShowLocationDropDown &&
          <MultiSelectDropdown
            options={stores}
            selectedValues={selectedStores}
            onSelectionChange={setSelectedStores}
            enableSearch={false}
            placeholder="Select stores"
            allItemsLabel="All stores"
            itemLabel={(item) => item.label}
            IconComponent={LocationIcon}
          />
        }
      </div>
      <BasicTabs
        list={ORDER_HISTORY_TAB_LIST.map((tab) => tab.label)}
        setValue={handleTabChange}
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
      />
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={0}
      >
        {renderOrders(orders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={1}
      >
        {renderOrders(quoteOrders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={2}
      >
        {renderOrders(activeOrders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={3}
      >
        {renderOrders(pastOrders)}
      </CustomTabPanel>
      <CustomTabPanel
        value={ORDER_HISTORY_TAB_LIST.findIndex(
          (tab) => tab.value === currentTab
        )}
        index={4}
      >
        {renderOrders(pastOrders)}
      </CustomTabPanel>
    </section>
  );
};

export default OrderHistory;
