import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";
import { tours } from ".";
import { TOUR_OPTION_STATUS, TOUR_OPTION_STATUS_ENUM } from "../constants";
import { updateTourStatus } from "../redux/tourAction";

export const orderDetailTour = ({ currentTour, dispatch, isDesktopScreen }) => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: "tour_step",
      showCancelLink: true,
      modalOverlayOpeningRadius: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
  });

  const onComplete = () => {
    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.complete)
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.COMPLETE,
        })
      );
  };

  const onSkip = () => {
    if (currentTour?.status !== TOUR_OPTION_STATUS_ENUM.skip)
      dispatch(
        updateTourStatus({
          id: currentTour?.id,
          status: TOUR_OPTION_STATUS.SKIP,
        })
      );

    const skip = tours.skip({ isDesktopScreen });
    skip.start();
  };

  tour.on("complete", onComplete);
  tour.on("cancel", onSkip);

  const steps = [
    {
      id: "Step 1",
      text: ` <div>
                <p class="font-bold text-xl">Order Details</p>
                <p class="text-sm text-gray-500 pt-2">
                  View all order details here, upload before and after images, print a shipping label, or print order labels.
                </p>
              </div>`,
      attachTo: {
        element: ".orderDetailStep1",
        on: "bottom",
      },
    },
    {
      id: "Step 2",
      text: ` <div>
                  <p class="font-bold text-xl">Print Shipping Labels</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Create shipping labels for customers or to send items back. Shipstation integration setup required!
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen
          ? ".orderDetailStep2"
          : ".orderDetailMobileStep2",
        on: "bottom",
      },
    },
    {
      id: "Step 3",
      text: ` <div>
                  <p class="font-bold text-xl">Print Order Labels</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Print order labels for easy in-store tracking and management of orders.
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen
          ? ".orderDetailStep3"
          : ".orderDetailMobileStep3",
        on: "bottom",
      },
    },
    {
      id: "Step 4",
      text: ` <div>
                  <p class="font-bold text-xl">Assign orders to your team</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Efficiently manage work load and assign orders to your team.
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen ? ".orderDetailStep4" : ".orderDetailMobileStep4",
        on: isDesktopScreen ? "left" : "bottom",
      },
    },
    {
      id: "Step 5",
      text: ` <div>
                  <p class="font-bold text-xl">Change the order status</p>
                  <p class="text-sm text-gray-500 pt-2">
                    Quickly update statuses here to keep customers informed and orders properly managed. You can also create sub-status tags to better manage your orders internally.
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen
          ? ".orderDetailStep5"
          : ".orderDetailMobileStep5",
        on: "bottom",
      },
      modalOverlayOpeningPadding: 10,
    },
    {
      id: "Step 6",
      text: ` <div>
                  <p class="font-bold text-xl">Message customers directly</p>
                  <p class="text-sm text-gray-500 pt-2">
                    If you have questions for the customer, you can message them directly here.
                  </p>
                </div>`,
      attachTo: {
        element: isDesktopScreen
          ? ".orderDetailStep6"
          : ".orderDetailMobileStep6",
        on: isDesktopScreen ? "right" : "top",
      },
      scrollTo: true,
    },
  ];

  steps.forEach((step, index) => {
    tour.addStep({
      ...step,
      buttons: [
        {
          text: `${index + 1}/${steps.length}`,
          action: null,
          classes: "step_counter",
          disabled: true,
        },
        {
          text: "Skip for now",
          action: tour.cancel,
          classes: "secondary_btn",
        },
        {
          text: index === steps.length - 1 ? "Finish" : "Next",
          action: index === steps.length - 1 ? tour.complete : tour.next,
          classes: "primary_btn",
        },
      ],
    });
  });

  return tour;
};
