import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData, login } from "./authActions";
import {
  TOUR_OPTION_STATUS,
  TOUR_OPTION_STATUS_ENUM,
  TOUR_OPTION_TYPE,
} from "../constants";
import { updateTourStatus } from "./tourAction";

const initialState = {
  tours: {},
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, action) => {
        const { tour_options } = action.payload;
        state.tours = { ...mapTourOptions(tour_options || []) };
      })
      .addCase(login.fulfilled, (state, action) => {
        const { tour_options } = action.payload;
        state.tours = { ...mapTourOptions(tour_options || []) };
      })
      .addCase(updateTourStatus.fulfilled, (state, action) => {
        const { id, status, tour_type } = action.payload;
        if (tour_type && state.tours[tour_type]) {
          state.tours[tour_type] = {
            ...state.tours[tour_type],
            id,
            status: TOUR_OPTION_STATUS_ENUM[status] ?? TOUR_OPTION_STATUS_ENUM.INCOMPLETE,
          };
        }
      });
      
  },
});

export const selectTours = (state) => state?.tour?.tours;
export const selectTour = (key) => (state) => state?.tour?.tours?.[key];

export default tourSlice.reducer;

const mapTourOptions = (tourOptions) => {
    return tourOptions.reduce((acc, option) => {
      const mappedKey = Object.keys(TOUR_OPTION_TYPE).find(
        (key) => TOUR_OPTION_TYPE[key].toLowerCase() === option.tour_type.toLowerCase()
      );
  
      if (mappedKey) {
        const lowerKey = mappedKey.toLowerCase();
        acc[lowerKey] = {
          id: option.id,
          status: TOUR_OPTION_STATUS_ENUM[option.status.toLowerCase()] ?? TOUR_OPTION_STATUS_ENUM.incomplete,
          name: TOUR_OPTION_TYPE[mappedKey],
        };
      }
  
      return acc;
    }, {});
  };
  
