export const addressRegex = /"([^"]+),([^"]+),([^"]+),([^"]+)"/;

export const usAddressRegex =
  /^.+,\s*[A-Za-z\s]+,\s*[A-Za-z]{2}\s*\d{5},\s*USA$/;


export const canadaAddressRegex =
  /^.+,\s*[A-Za-z\s]+,\s*[A-Za-z]{2}\s*[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d,\s*CA$/;

export const phoneNumberRegex = /^\d{10}$/;

export const phoneNumberFormatRegex = /^(\d{3})(\d{3})(\d{4})$/;

export const CSV_SPLIT_REGEX = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
