import { Button, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CrownIcon from "../../images/CrownIcon.svg";
import NumberInput from "../Form/NumberInput";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";
import { postRequest } from "../../api";

const AddOnModal = ({ open, setOpen, organization, count }) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddOnSubscription = async () => {
    try {
      dispatch(setLoading(true));
      const updatedQuanity = count + quantity;
      const response = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stripe/add_ons/`,
        {
          organization_id: organization.id,
          users_count: updatedQuanity,
          add_on_type: "users_count",
        }
      );
      if (response && response.status == 201) {
        setOpen(false);
        dispatch(addToast("Add-On Subscribed Successfully. Refresh your page"));
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <>
        <div className="flex flex-col gap-2 mx-8 mt-8">
          <div className="ml-1 px-2 py-1 bg-orange-100 rounded-2xl justify-center items-center inline-flex w-fit">
            <img alt="logo" src={CrownIcon} className="w-4 h-auto " />
            <div className="text-center text-orange-500 text-xs font-normal font-['Questrial'] leading-none">
              Add-On
            </div>
          </div>
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            Add more team members
          </p>
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            You’ve reached the 5 users limitation on your plan, would you like
            to add additional team members?
          </p>
        </div>
        <hr className="border-[0.5px] border-stone-300 mx-8 mt-4" />
        <div className="flex flex-col gap-2 mx-8 mt-8">
          <div className="flex flex-row justify-between">
            <div>Cost per user</div>
            <div>$ 10.00 / month</div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div>Number of Users</div>
            <NumberInput quantity={quantity} setQuantity={setQuantity} />
          </div>
        </div>

        <hr className="border-[0.5px] border-stone-300 mx-8 mt-4" />

        <div className="flex flex-row justify-between gap-2 mx-8 mt-4">
          <div>Total Cost</div>
          <div>
            <span>$</span>
            {quantity * 10}
            <span>/ month</span>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center gap-2 mx-8 mt-4 mb-8">
          <Button variant="outlinedSecondary" disabled className="w-full">
            Contact Us
          </Button>
          <Button
            variant="containedPrimary"
            className="w-full"
            onClick={handleAddOnSubscription}
          >
            Add Now
          </Button>
        </div>
      </>
    </Dialog>
  );
};

export default AddOnModal;
